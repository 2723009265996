import { Button, MarkdownRender, Modal } from '@douyinfe/semi-ui';
import './index.less';
import { useCallback, useContext, useEffect, useRef, useState, Suspense, lazy } from 'react';
import { AppContext } from '../../../../App';
import { request } from '../../../../utils';

// Lazy load the Editor and its CSS dynamically
const loadEditorAndCSS = async () => {
    await import('@toast-ui/editor/dist/toastui-editor.css' as any); // Dynamically load CSS
    return import('@toast-ui/react-editor').then((module) => ({ default: module.Editor }));
};

const Editor = lazy(loadEditorAndCSS);

interface IGiftCardSummaryProps {
    gift_card_promotion_auto_increment_id: number;
    md: string;
}

const GiftCardSummary = ({ md, gift_card_promotion_auto_increment_id }: IGiftCardSummaryProps) => {
    const [clickCount, setClickCount] = useState(0);
    const [timer, setTimer] = useState<any>(null);
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);

    const [displayMd, setDisplayMd] = useState(md);
    const [newMd, setNewMd] = useState('');
    const { isMobile } = useContext(AppContext);

    // Use ref with a null assertion to handle lazy-loaded Editor ref
    const editorRef = useRef<any>(null);

    useEffect(() => {
        setNewMd(displayMd);
    }, [displayMd]);

    useEffect(() => {
        // When click count reaches 5, trigger action and reset click counter and timer
        if (clickCount === 5) {
            setVisible(true);
            clearTimeout(timer);
            setClickCount(0);
        }

        return () => clearTimeout(timer);
    }, [clickCount, timer]);

    const handleClick = () => {
        if (clickCount === 0) {
            // Start timer on the first click
            const newTimer = setTimeout(() => {
                setClickCount(0); // Reset click count
            }, 1000); // Set 1-second click count limit
            setTimer(newTimer);
        }

        // Increase click count
        setClickCount(prevCount => prevCount + 1);
    };

    const handleOk = useCallback(async () => {
        setLoading(true);

        await request({
            url: '/api/v1/service/execute',
            method: 'post',
            data: {
                __skip_decode__: true,
                service: 'rds.sql',
                requestParams: [
                    `UPDATE simpo_octopus.au_gift_card_promotion SET promotion_detail = "${encodeURIComponent(newMd)}" WHERE gift_card_promotion_auto_increment_id = "${gift_card_promotion_auto_increment_id}"`
                ]
            }
        });

        setLoading(false);
        setVisible(false);
        setDisplayMd(newMd);
    }, [newMd, gift_card_promotion_auto_increment_id]);

    useEffect(() => {
        // Override ul
        const ulElList = Array.from(document.querySelectorAll('.gift-card-summary-wrapper ul'));
        ulElList.map((el: any) => {
            if (el?.innerText?.trim() === '-') {
                el.outerHTML = '-';
            }
        });

        // Override a onclick
        const handleClick = (e: any) => {
            e.preventDefault();
            if (e.target.href) {
                window.open(e.target.href);
            }
        };

        const elList = Array.from(document.querySelectorAll('.gift-card-summary-wrapper a'));

        elList.forEach(el => {
            el.addEventListener('click', handleClick);
        });

        return () => {
            elList.forEach(el => {
                el.removeEventListener('click', handleClick);
            });
        };
    }, [displayMd]);

    return (
        <div className="gift-card-summary-wrapper" onClick={handleClick}>
            <MarkdownRender
                raw={displayMd.replace(/<br/g, '<br /')}
                format="mdx"
                components={{
                    a: ({ href, children }) => {
                        return (
                            <Button
                                size="small"
                                theme="borderless"
                                style={{ padding: 0, height: 'fit-content' }}
                                onClick={() => {
                                    window.open(href);
                                }}
                            >
                                {children}
                            </Button>
                        );
                    }
                }}
            />

            <Modal visible={visible} title="Edit promotion detail" width={isMobile ? '100%' : 1000} onCancel={() => setVisible(false)} confirmLoading={loading} onOk={handleOk}>
                {/* Use Suspense to handle the lazy loading */}
                <Suspense fallback={<div>Loading Editor...</div>}>
                    <Editor
                        initialValue={displayMd}
                        previewStyle={isMobile ? 'tab' : 'vertical'}
                        height="600px"
                        initialEditType="wysiwyg"
                        useCommandShortcut={true}
                        ref={editorRef}
                        onChange={() => setNewMd(editorRef.current?.getInstance()?.getMarkdown() || '')}
                    />
                </Suspense>
            </Modal>
        </div>
    );
};

export default GiftCardSummary;
