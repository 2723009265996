import { useAsyncEffect } from "ahooks"
import { request } from "../../../utils"
import { useContext, useState } from "react"
import { IOzBargainDeal } from "../../../typings"
import { Button, Descriptions, Divider, SideSheet, Table, Tag } from "@douyinfe/semi-ui"
import FlexContainer from "../../../components/FlexContainer"
import { AppContext } from "../../../App"
import dayjs from "dayjs"
import { IconSetting, IconThumbUpStroked } from "@douyinfe/semi-icons"
import GPTHelper from "../../../components/GPTHelper"
import { SideSheetPlaceholder } from "../AdminLayout"
import { uniq, uniqBy } from "lodash-es"

const TrendingDealManagement = () => {
    const { windowHeight, navbarHeight } = useContext(AppContext)

    const [pageLoading, setPageLoading] = useState(true)

    const [dealList, setDealList] = useState<IOzBargainDeal[]>([])
    const [selectedDeal, setSelectedDeal] = useState<IOzBargainDeal>()

    useAsyncEffect(async () => {
        const respData = await request({
            method: 'post',
            url: '/api/v1/service/execute',
            data: {
                service: 'rds.sql',
                requestParams: [
                    `SELECT 
                        * 
                    FROM 
                        simpo_octopus.au_ozbargain_deal
                    WHERE 
                        is_expired = 0
                        AND created_at >= DATE_SUB(CURDATE(), INTERVAL 7 DAY)
                    ORDER BY 
                        created_at DESC`
                ]
            }
        })

        setDealList(respData)
        setPageLoading(false)
    }, [])

    return (
        <FlexContainer>
            <div style={{ flexGrow: 1 }}>
                <div className='responsive-text font-weight-black' style={{ fontSize: 20, padding: '8px 16px', letterSpacing: -0.5 }}>
                    Trending Deal Management ({dealList.length})
                </div>

                <Table
                    style={{ height: windowHeight - navbarHeight - 46, overflow: 'auto' }}
                    dataSource={dealList}
                    pagination={false}
                    loading={pageLoading}
                    rowKey="deal_id"
                    columns={[
                        {
                            title: 'ID',
                            dataIndex: 'deal_id',
                            render: (text: string) => {
                                return (
                                    <Button size="small" style={{ padding: 0 }} theme="borderless" type="tertiary" onClick={() => {
                                        window.open(`https://www.ozbargain.com.au/node/${text}`)
                                    }}>{text}</Button>
                                )
                            }
                        },
                        {
                            title: 'Upvote',
                            dataIndex: 'vote_up_num',
                            sorter: (a: any, b: any) => {
                                return (a.vote_up_num || 0) - (b.vote_up_num || 0)
                            },
                            render: (_: any, record: IOzBargainDeal) => {
                                return (
                                    <Tag>
                                        <IconThumbUpStroked style={{ marginRight: 4 }} />
                                        <span style={{ fontSize: 14 }}>{record.vote_up_num}</span>
                                    </Tag>
                                )
                            }
                        },
                        {
                            title: 'Name',
                            filterMultiple: false,
                            filters: uniqBy(dealList.map(deal => {
                                return {
                                    text: deal.platform,
                                    value: deal.platform
                                }
                            }), 'value').sort((a, b) => a.value.localeCompare(b.value)),
                            onFilter: (value: string, record: any) => {
                                return record.platform === value
                            },
                            dataIndex: 'platform',
                            render: (text: string, record: IOzBargainDeal) => {
                                return (
                                    <FlexContainer gap="4px" flexDirection="column">
                                        <div>
                                            <Tag>{record.platform}</Tag>
                                        </div>
                                        <div>{record.name}</div>
                                    </FlexContainer>
                                )
                            }
                        },
                        {
                            title: 'Created at',
                            width: 160,
                            render: (record: IOzBargainDeal) => {
                                return (
                                    <div>{dayjs(record.created_at).format('YYYY-MM-DD HH:mm')}</div>
                                )
                            }
                        },
                        {
                            title: 'Action',
                            render: (record: IOzBargainDeal) => {
                                return (
                                    <FlexContainer>
                                        <Button icon={<IconSetting />} type="tertiary" theme="solid" style={{ borderRadius: 32 }} onClick={() => {
                                            setSelectedDeal(record)
                                        }} />
                                    </FlexContainer>
                                )
                            }
                        }
                    ]}
                />

                <SideSheet title="Deal detail" visible={!!selectedDeal} onCancel={() => setSelectedDeal(undefined)} mask={false}>
                    <div>
                        <Descriptions
                            align="left"
                            data={[
                                { key: 'Deal ID', value: selectedDeal?.deal_id },
                                { key: 'Name', value: selectedDeal?.name },
                                { key: 'Created at', value: dayjs(selectedDeal?.created_at).format('YYYY-MM-DD HH:mm') },
                                { key: 'Upvote num', value: selectedDeal?.vote_up_num },
                                { key: 'Detail', value: (
                                    <Button style={{ padding: 0, height: 'auto' }} size="small" theme="borderless" onClick={() => {
                                        window.open(selectedDeal?.buy_url)
                                    }}>
                                        Open
                                    </Button>
                                ) }
                            ]}
                        />
                    </div>

                    <Divider />

                    {/* <GPTHelper
                        key={selectedDeal?.deal_id}
                        system_prompt="请帮我撰写小红书文案，初衷是表达这个活动很划算，不用加很多推销文案，实事求是的说就好，也不要让别人觉得是gpt生成的，单位是AUD"
                        user_content={`${selectedDeal?.name}\n\n${selectedDeal?.description}`}
                    /> */}

                    <FlexContainer alignItems="center" flexDirection="column" style={{ marginTop: 16, }}>
                        <div>
                            <div 
                                className="font-weight-bold" 
                                style={{ letterSpacing: -1, fontSize: 20 }}
                                contentEditable
                            >
                                {selectedDeal?.platform}
                            </div>
                        </div>

                        <div>
                            <img src={selectedDeal?.pic_url} />
                        </div>

                        <div 
                            className="font-weight-black" 
                            style={{
                                marginTop: 36,
                                fontSize: 26, 
                                lineHeight: '30px', 
                                letterSpacing: -1,
                                textAlign: 'center'
                            }} 
                            contentEditable
                        >
                            {selectedDeal?.name}
                        </div>
                    </FlexContainer>
                </SideSheet>
            </div>

            <SideSheetPlaceholder />
        </FlexContainer>
    )
}

export default TrendingDealManagement