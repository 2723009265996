import { Button, Form, SideSheet, Switch, Table, Tabs, Toast } from "@douyinfe/semi-ui"
import FlexContainer from "../../../components/FlexContainer"
import { IconFullScreenStroked, IconHistory, IconPlus, IconShrinkScreenStroked, IconSync } from "@douyinfe/semi-icons"
import useKeyValuePairs from "../../../hooks/useKeyValuePairs"
import { SideSheetPlaceholder } from "../AdminLayout";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useAsyncEffect } from "ahooks";
import { request } from "../../../utils";
import SystemStatus from "../SystemStatus";
import { FormCodeEditor } from "../../../components/CodeEditor";
import { FormApi } from "@douyinfe/semi-ui/lib/es/form";
import { AppContext } from "../../../App";
import { IconCollapse } from "@douyinfe/semi-icons-lab";
import GPTHelper from "../../../components/GPTHelper";
import { loadScript } from "../../../components/ArticleRender";

interface ICronjob {
    cronJobName: string;
    syncTaskSource: string;
    cronTime: string;
    disabled: boolean;
    jobParams: {
        service: string;
        requestParams: any
    }
}

const Cronjob = () => {
    const { raw, loading, handleRefresh } = useKeyValuePairs('generic', 'cronjob_setting')

    const cronjobList = (raw || []) as ICronjob[]
    const formRef = useRef<FormApi>()

    const [selectedCronjob, setSelectedCronjob] = useState<ICronjob>()
    const [isFullScreen, setIsFullScreen] = useState(true)

    const { navbarHeight, windowHeight } = useContext(AppContext)

    useAsyncEffect(async () => {
        if (!window.cronstrue) {
            await loadScript('https://unpkg.com/cronstrue@latest/dist/cronstrue.min.js')
        }
    }, [])

    useEffect(() => {
        if (selectedCronjob) {
            formRef.current?.setValues({
                json: JSON.stringify(selectedCronjob, null, 4)
            })
        }
    }, [selectedCronjob])

    const handleSync = async (record: ICronjob) => {
        const { jobParams } = record
        const { service, requestParams } = jobParams

        request({
            data: {
                service,
                requestParams
            }
        })

        setSelectedCronjob(record)
        Toast.success(`Sync task created`)
    }

    const handleSubmit = useCallback(async (v: any) => {
        if (!selectedCronjob) {
            return
        }

        try {
            const json = JSON.parse(v.json)
            const isCreate = !selectedCronjob?.cronJobName

            await request({
                data: {
                    __skip_decode__: true,
                    service: 'rds.insert',
                    requestParams: [
                        'simpo_octopus', 'config', {
                            key: 'cronjob_setting',
                            value: encodeURIComponent(JSON.stringify(isCreate ? [
                                ...cronjobList || [],
                                json
                            ] : cronjobList.map(item => {
                                if (item.cronJobName === selectedCronjob.cronJobName) {
                                    return json
                                }

                                return item
                            })))
                        }, {
                            onDuplicateKeyUpdate: {
                                'value': 'VALUES(value)'
                            }
                        }
                    ]
                }
            })

            Toast.success("Submit successfully")
            setTimeout(() => {
                handleRefresh()
            }, 500)
        } catch (err) {
            console.log(v.json)
            Toast.error("Invalid json format")
        }
    }, [selectedCronjob, cronjobList])

    return (
        <FlexContainer style={{ width: '100%' }}>
            <div style={{ width: '100%' }}>
                <FlexContainer justifyContent="space-between" style={{ padding: '8px 16px' }}>
                    <div style={{ fontSize: 20, letterSpacing: -0.5, fontWeight: 700 }}>
                        Cronjob
                    </div>

                    <Button theme="solid" icon={<IconPlus />} onClick={() => {
                        setSelectedCronjob({
                            "cronJobName": "",
                            "cronTime": "0 9 * * 1-5",
                            "syncTaskSource": "",
                            "disabled": false,
                            "jobParams": {
                                "service": "",
                                "requestParams": []
                            }
                        })
                    }}>Cronjob</Button>
                </FlexContainer>

                <Table
                    loading={loading}
                    dataSource={cronjobList}
                    pagination={false}
                    style={{ maxHeight: windowHeight - navbarHeight - 48, overflow: 'auto' }}
                    columns={[
                        {
                            title: 'Cronjob',
                            dataIndex: 'cronJobName'
                        },
                        {
                            title: 'Triggered at',
                            dataIndex: 'cronTime',
                            width: 200,
                            render: (text: string) => {
                                return (
                                    <div>
                                        <div>
                                            <code>{text}</code>
                                        </div>
                                        <div style={{ fontSize: 12, lineHeight: '14px', color: 'grey' }}>{window.cronstrue?.toString(text)}</div>
                                    </div>
                                )
                            }
                        },
                        {
                            title: 'Script',
                            render: (record: ICronjob) => {
                                return (
                                    <div className="responsive-background" style={{ padding: 12, borderRadius: 4, background: '#f2f3f5' }}>
                                        <pre style={{ maxHeight: 100, overflow: 'auto', margin: 0, fontSize: 12 }}>
                                            {JSON.stringify(record.jobParams || {}, null, 4)}
                                        </pre>
                                    </div>
                                )
                            }
                        },
                        {
                            title: 'Enabled',
                            width: 100,
                            render: (record: ICronjob) => {
                                return (
                                    <Switch checked={!record.disabled} disabled />
                                )
                            }
                        },
                        {
                            title: 'Action',
                            width: 110,
                            render: (record: ICronjob) => {
                                return (
                                    <FlexContainer gap="6px">
                                        <Button icon={<IconSync />} theme="solid" style={{ borderRadius: 32 }} onClick={() => {
                                            handleSync(record)
                                        }} />
                                        <Button theme="solid" type="tertiary" icon={<IconHistory />} style={{ borderRadius: 32 }} onClick={() => {
                                            setSelectedCronjob(record)
                                        }} />
                                    </FlexContainer>

                                )
                            }
                        }
                    ]}
                />
            </div>

            <SideSheetPlaceholder />

            <SideSheet
                title={
                    <FlexContainer alignItems="center" gap="6px">
                        <span>Cronjob Detail</span>

                        <Button type="tertiary" size="small" icon={isFullScreen ? <IconShrinkScreenStroked /> : <IconFullScreenStroked />} onClick={() => setIsFullScreen(prev => !prev)} />
                    </FlexContainer>
                }
                visible={!!selectedCronjob}
                onCancel={() => setSelectedCronjob(undefined)}
                mask={false}
                {...isFullScreen ? { width: 1200 } : {}}
            >
                {!!selectedCronjob?.cronJobName ? (
                    <Tabs>
                        <Tabs.TabPane itemKey="sync-history" tab="Sync History">
                            <div>
                                <SystemStatus mode="table" source={selectedCronjob?.syncTaskSource} />
                            </div>
                        </Tabs.TabPane>
                        <Tabs.TabPane itemKey="edit-cronjob" tab="Edit">
                            <Form getFormApi={api => formRef.current = api} onSubmit={handleSubmit}>
                                <FormCodeEditor
                                    field="json"
                                    label="Cronjob config"
                                    height="200px"
                                    language="json"
                                />

                                <Button theme="solid" htmlType="submit">
                                    Submit
                                </Button>
                            </Form>

                            <GPTHelper
                                system_prompt="Generate cron, keep it concise, plain text result is enough"
                                user_content={"Everyday at 5PM"}
                                rows={1}
                            />
                        </Tabs.TabPane>
                    </Tabs>
                ) : (
                    <>
                        <Form getFormApi={api => formRef.current = api} onSubmit={handleSubmit}>
                            <FormCodeEditor
                                field="json"
                                label="Cronjob config"
                                height="200px"
                                language="json"
                            />

                            <Button theme="solid" htmlType="submit">
                                Submit
                            </Button>
                        </Form>
                        <GPTHelper
                            system_prompt="Generate cron, keep it concise, plain text result is enough"
                            user_content={"Everyday at 5PM"}
                            rows={1}
                        />
                    </>

                )}

            </SideSheet>
        </FlexContainer>
    )
}

export default Cronjob