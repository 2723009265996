import { IconChevronDown, IconChevronRight, IconChevronUp } from "@douyinfe/semi-icons";
import { Button } from "@douyinfe/semi-ui";
import { useState } from "react";
import FlexContainer from "../FlexContainer";

interface IViewMoreContainerProps {
    collapse: any;
    expand: any;
}

const ViewMoreContainer = ({ collapse, expand }: IViewMoreContainerProps) => {
    const [isExpanded, setIsExpanded] = useState(false)
    
    return (
        <div>
            {isExpanded ? expand : collapse}

            <Button 
                theme="borderless" 
                style={{ padding: 2, fontSize: 11, height: 12 }} 
                onClick={() => setIsExpanded(prev => !prev)}
                size="small"
            >
                <FlexContainer alignItems="center" gap="2px">
                    {isExpanded ? 'Collapse' : 'View more'}
                    {isExpanded ? <IconChevronUp style={{ fontSize: 12 }} /> : <IconChevronRight style={{ fontSize: 12 }} />}
                </FlexContainer>
            </Button>
        </div>
    )
}

export default ViewMoreContainer