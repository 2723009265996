import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react"
import SubHeader from "../../components/SubHeader"
import { AutoComplete, BackTop, Badge, Breadcrumb, Button, Card, Checkbox, Divider, Dropdown, Image, Input, Radio, RadioGroup, SideSheet, Switch, Tag, Toast, Typography } from "@douyinfe/semi-ui"
import { useAsyncEffect } from "ahooks"
import { changeUrlWithQueryParams, handleDecodeFilterKey, isMobileDevice, isNumeric, renderPageOnReady, request, toBase64Unicode } from "../../utils"
import { ICatalogue, IDeal } from "../../typings"
import FlexContainer from "../../components/FlexContainer"
import SimilarProductSidesheet, { ISimilarProductSidesheetProps } from "./components/SimilarProductSidesheet"
import ProductCard from "./components/ProductCard"
import InfiniteScroll from 'react-infinite-scroll-component';
import './index.less'
import { AppContext } from "../../App"
import { IconAlignTop, IconArrowUp, IconCart, IconHistory, IconLoading, IconRedo, IconRefresh, IconRefresh2, IconSearch, IconSpin } from "@douyinfe/semi-icons"
import algoliasearch from "algoliasearch"
import { useParams } from "react-router-dom"
import { setConstantValue } from "typescript"
import { capitalize, debounce } from "lodash-es"
import dayjs from "dayjs"
import GroceriesTourModal from "../../components/CommonModal/GroceriesTourModal"
import { initSeo, resetSeo } from "../../utils/seo"

const client = algoliasearch('A97US7BF85', '70a0214c44fee7a1ddda7f47b0babeaf');
const index = client.initIndex('simpo_octopus');

export const PLATFORM_NAME_MAP: Record<string, string> = {
    coles: 'Coles',
    woolworths: 'Woolworths',
    aldi: 'ALDI',
    kmart: 'Kmart',
    costco: 'Costco',
    "chemist-warehouse": 'Chemist Warehouse'
}

export const isCataloguePage = () => {
    return window.location.pathname.includes('catalogue')
}

const SORT_BY_DICT = {
    'most-recent': 'Most recent',
    'price-from-low-to-high': 'Price: Low-High',
    'discount-from-high-to-low': 'Discount: High-Low',
    'popularity': 'Popularity'
}

const CATALOGUE_NAME_LIST = [
    ...[1, 2].includes(dayjs().day()) ? [
        "Coles (Sneak peek)",
        "Woolworths (Sneak peek)"
    ] : [],
    'Coles',
    'Woolworths',
    'Aldi Special Buys',
    'Costco In-Warehouse Savings'
]

const PAGE_SIZE = 50

const GroceriesByPlatform = () => {
    const { isMobile, windowWidth, windowHeight, navbarHeight, isInApp, isLogin, showShoppingList, cartItemList, showLoginForm, isDarkMode } = useContext(AppContext)
    const { platform: rawPlatform } = useParams()

    const platform = decodeURIComponent(rawPlatform as string)
    const filterKey: Record<string, string> = handleDecodeFilterKey()

    const [currTab, setCurrTab] = useState(platform && ['coles', 'woolworths', 'costco in-warehouse savings'].includes(platform) ? platform : 'coles')

    const [loading, setLoading] = useState(true) // page loading
    const [productListLoading, setProductListLoading] = useState(true)
    const [productList, setProductList] = useState<IDeal[]>([])
    const [exactMatchList, setExactMatchList] = useState<IDeal[]>([])

    const [catalogueList, setCatalogueList] = useState<ICatalogue[]>([])

    const [categoryList, setCategoryList] = useState<any[]>([])
    const [selectedCategory, setSelectedCategory] = useState<string>(filterKey.selectedCategory || 'all')
    const [showSpecialsOnly, setShowSpecialsOnly] = useState(false)

    const [sortBy, setSortBy] = useState(filterKey.sortBy || 'popularity')
    const [searchKeyword, setSearchKeyword] = useState(filterKey.searchKeyword || '')

    const [autoCompleteList, setAutoCompleteList] = useState<any[]>([])
    const [autoCompleteLoading, setAutoCompleteLoading] = useState(false)

    const [pageNum, setPageNum] = useState(1)
    const [totalNum, setTotalNum] = useState(0)

    const [v, setV] = useState(1)

    const prevStateRef = useRef<Record<string, any>>({
        ...filterKey || {},
        currTab: platform && ['coles', 'woolworths', 'catalogue'].includes(platform) ? platform : 'coles',
        showSpecialsOnly: true
    })

    const [similarProductSidesheetProps, setSimilarProductSidesheetProps] = useState<ISimilarProductSidesheetProps>({
        visible: false
    })

    const hasFilter = useMemo(() => {
        if (!isCataloguePage() && (searchKeyword || selectedCategory !== 'all' || sortBy !== 'popularity')) {
            return true
        }

        if (isCataloguePage() && searchKeyword) {
            return true
        }

        return false
    }, [searchKeyword, selectedCategory, sortBy])

    const handleResetFilter = useCallback(() => {
        if (searchKeyword) {
            setSearchKeyword('')
            setV(prev => prev + 1)
        }

        if (selectedCategory !== 'all') {
            setSelectedCategory('all')
        }

        if (sortBy !== 'popularity') {
            setSortBy('popularity')
        }
    }, [searchKeyword, selectedCategory, sortBy])

    useEffect(() => {
        return () => {
            resetSeo()
        }
    }, [])

    useAsyncEffect(async () => {
        if (isCataloguePage()) {
            const respData = await request({
                method: 'POST',
                url: '/api/v1/service/execute',
                data: {
                    service: 'rds.sql',
                    requestParams: [
                        `SELECT * FROM simpo_octopus.au_salefinder_catalogue WHERE end_date >= "${dayjs().format('YYYY-MM-DD')}"`
                    ]
                }
            })

            const NAME_DICT: Record<string, string> = {
                coles: 'Coles',
                woolworths: 'Woolworths',
                aldi: 'Aldi',
                costco: 'Costco',

            }

            setCatalogueList(respData.map((item: any) => {

                let key = NAME_DICT[item.platform]
                if (dayjs().isBefore(dayjs(item.start_date))) {
                    key = `${key} (Sneak peek)`
                }

                if (item.platform === 'aldi') {
                    key = `${key}||${item.catalogue_id}`
                }

                return {
                    ...item,
                    key
                }
            }))
        }
    }, [])

    useAsyncEffect(async () => {
        if (isCataloguePage()) {
            setCategoryList([])
            return
        }

        const categoryListRespData = await request({
            method: 'POST',
            url: '/api/v1/service/execute',
            data: {
                service: `au.hotaudeals.getGroceryCategoryList`
            }
        })

        setCategoryList(categoryListRespData)
    }, [currTab])

    useAsyncEffect(async () => {
        const json = {
            currTab,
            sortBy,
            selectedCategory,
            searchKeyword,
            showSpecialsOnly
        }

        const changedField = ['currTab', 'sortBy', 'selectedCategory', 'searchKeyword', 'showSpecialsOnly'].filter(key => prevStateRef.current[key] !== (json as any)?.[key])

        if (changedField.length && pageNum !== 1) {
            setPageNum(1)
            setTotalNum(0)
            prevStateRef.current = json
            return
        }

        // set url
        changeUrlWithQueryParams(`/${isCataloguePage() ? 'catalogue' : 'groceries'}/${currTab}`, {
            filterKey: toBase64Unicode(JSON.stringify({ sortBy, selectedCategory, searchKeyword }))
        })

        // change document title
        // document.title = [capitalize(currTab), isCataloguePage() ? 'Catalogue' : 'Groceries', 'HotAuDeals'].join(' - ')

        if (isCataloguePage()) {
            initSeo({
                title: `${capitalize(currTab)} Catalogue: An In-Depth Overview`,
                description: `Browse the ${capitalize(currTab)} catalogue for all the latest specials and offers. Stay updated on genuine discounts and deals by using our price tracking tool, available exclusively at HotAuDeals.`
            })
        } else {
            initSeo({
                title: `Shop Smarter at ${capitalize(currTab)} Like Never Before`,
                description: `Shop your way at ${capitalize(currTab)} by comparing price history, checking exact match item prices at other supermarkets, and identifying historical lows to find the best grocery deals—all exclusive to HotAuDeals.`
            })
        }

        if (pageNum === 1) {
            setLoading(true)
        }

        setProductListLoading(true)

        let sortByCommand = ''
        switch (sortBy) {
            case 'popularity':
                if (isCataloguePage()) {
                    sortByCommand = ''
                } else {
                    sortByCommand = 'ORDER BY formatted_category ASC, -discount_rate DESC'
                }
                break
            case 'most-recent':
                sortByCommand = 'ORDER BY updated_at DESC'
                break
            case 'price-from-low-to-high':
                if (isCataloguePage()) {
                    sortByCommand = 'ORDER BY new_price ASC'
                } else {
                    sortByCommand = 'ORDER BY price ASC'
                }
                break
            case 'discount-from-high-to-low':
                sortByCommand = 'ORDER BY -discount_rate DESC'
                break
        }

        let isSkuIdSearch = false

        const whereCommand = [`1 = 1`]
        if (searchKeyword) {
            if (isNumeric(searchKeyword) && (searchKeyword as string).length > 4) {
                whereCommand.push(`sku_id = "${searchKeyword}"`)
                isSkuIdSearch = true
            } else {
                whereCommand.push(`name LIKE "%${searchKeyword}%"`)
            }
        }

        if (!isSkuIdSearch && !isCataloguePage()) {
            if (selectedCategory && selectedCategory !== 'all') {
                whereCommand.push(`formatted_category = "${selectedCategory}"`)
            }

            if (showSpecialsOnly) {
                whereCommand.push(`extra_data LIKE "%isActiveCatalogueItem%"`)
            }
        }

        if (isCataloguePage()) {
            const respData = await request({
                method: 'POST',
                url: '/api/v1/service/execute',
                data: {
                    service: 'au.hotaudeals.getCatalogueItemList',
                    requestParams: [
                        currTab, pageNum, PAGE_SIZE, whereCommand, sortByCommand
                    ]
                }
            })

            if (pageNum === 1) {
                setProductList(respData?.productList || [])
                setTotalNum(respData?.total || 0)
            } else {
                setProductList(prev => [...prev, ...respData?.productList || []])
            }
        } else {
            const productListRespData = await request({
                method: 'POST',
                url: '/api/v1/service/execute',
                data: {
                    service: 'rds.sql',
                    requestParams: [
                        `
                            SELECT 
                                *,
                                "${currTab}" as platform
                            FROM 
                                simpo_octopus.au_${currTab}_product_tmp
                            WHERE 
                                ${whereCommand.join(' AND ')} 
                            ${sortByCommand} 
                            LIMIT ${PAGE_SIZE}
                            OFFSET ${(pageNum - 1) * PAGE_SIZE}`
                    ]
                }
            })

            const competitorPlatform = currTab === 'coles' ? 'woolworths' : 'coles'
            const skuIdList: string[] = productListRespData.map((product: IDeal) => product.sku_id)

            if (skuIdList.length) {
                const exactMatchList = await request({
                    method: 'POST',
                    url: '/api/v1/service/execute',
                    data: {
                        service: 'rds.sql',
                        requestParams: [
                            `
                                    SELECT 
                                        *,
                                        "${competitorPlatform}" as platform
                                    FROM
                                        (${currTab === 'coles'
                                ? `SELECT SUBSTRING_INDEX(criteria, '_', -1) AS coles_sku_id, buying_options AS woolworths_sku_id FROM simpo_octopus.au_gorcery_item_buying_option WHERE platform = "coles" AND status = 1 AND criteria IN (${skuIdList.map(skuId => `"algolia_${skuId}"`).join(', ')})`
                                : `SELECT SUBSTRING_INDEX(criteria, '_', -1) AS coles_sku_id, buying_options AS woolworths_sku_id FROM simpo_octopus.au_gorcery_item_buying_option WHERE platform = "coles" AND status = 1 AND criteria LIKE "algolia_%" AND buying_options IN (${skuIdList.map(skuId => `"${skuId}"`).join(', ')})`
                            }) a
                                        LEFT JOIN
                                        simpo_octopus.au_${competitorPlatform}_product_tmp b
                                        ON a.${competitorPlatform}_sku_id = b.sku_id
                                `,
                        ]
                    }
                })

                setExactMatchList(prev => [...prev, ...exactMatchList])
            }

            if (pageNum === 1) {
                const totalNumRespData = await request({
                    method: 'POST',
                    url: '/api/v1/service/execute',
                    data: {
                        service: 'rds.sql',
                        requestParams: [
                            `
                                SELECT 
                                    COUNT(*) as total
                                FROM 
                                    simpo_octopus.au_${currTab}_product_tmp
                                WHERE 
                                    ${whereCommand.join(' AND ')} 
                                ${sortByCommand}`
                        ]
                    }
                })

                setTotalNum(totalNumRespData?.[0]?.total || 0)
            }

            if (pageNum === 1) {
                setProductList(productListRespData)
            } else {
                setProductList(prev => [...prev, ...productListRespData])
            }
        }

        prevStateRef.current = json
        setLoading(false)
        setProductListLoading(false)
    }, [currTab, sortBy, selectedCategory, searchKeyword, showSpecialsOnly, pageNum])

    const showCategoryFilterInline = useMemo(() => {
        return isMobile || windowWidth < 1440
    }, [isMobile, windowWidth])

    const resetFilterWrapper = (
        <>
            {!isCataloguePage() && showSpecialsOnly && (
                <Tag closable onClose={() => setShowSpecialsOnly(false)} shape="circle" color="blue" style={{ marginBottom: 8, height: 24, padding: '0 8px 0 12px' }}>
                    <span className="font-weight-bold" style={{ fontSize: 14 }}>Show Specials Only</span>
                </Tag>
            )}

            {!isCataloguePage() && selectedCategory !== 'all' && (
                <Tag closable onClose={() => setSelectedCategory('all')} shape="circle" color="blue" style={{ marginBottom: 8, height: 24, padding: '0 8px 0 12px' }}>
                    <span className="font-weight-bold" style={{ fontSize: 14 }}>Category: {selectedCategory}</span>
                </Tag>
            )}

            {!!searchKeyword && (
                <Tag closable onClose={() => {
                    setSearchKeyword('')
                    setV(prev => prev + 1)
                }} shape="circle" color="blue" style={{ marginBottom: 8, height: 24, padding: '0 8px 0 12px' }}>
                    <span className="font-weight-bold" style={{ fontSize: 14 }}>Search keyword: {searchKeyword}</span>
                </Tag>
            )}

            {(
                ((showSpecialsOnly || selectedCategory !== 'all' || !!searchKeyword) && !isCataloguePage())
                || (isCataloguePage() && searchKeyword)
            ) && (
                    <Button icon={<IconRefresh />} style={{ marginTop: 8 }} theme="borderless" type="tertiary" onClick={() => {
                        handleResetFilter()
                        setShowSpecialsOnly(false)
                    }}>Reset all</Button>
                )}
        </>
    )

    const handleAutoComplete = async (text: string) => {
        setAutoCompleteList([])

        if (!text) {
            return
        }

        setAutoCompleteLoading(true)

        const respData = await request({
            method: 'POST',
            url: '/api/v1/service/execute',
            data: {
                service: 'au.hotaudeals.autoComplete',
                requestParams: [text]
            }
        })

        setAutoCompleteList([...respData, text].map(str => ({
            label: <span className="font-weight-bold">{str}</span>,
            value: str
        })))
        setAutoCompleteLoading(false)
    }

    return (
        <div>
            <InfiniteScroll
                dataLength={productList.length} //This is important field to render the next data
                next={() => setPageNum(prev => prev + 1)}
                hasMore={totalNum > PAGE_SIZE * pageNum}
                height={`calc(${windowHeight}px - ${navbarHeight}px)`}
                loader={<></>}
                className="infinite-scroll-wrapper"
                endMessage={loading || productList.length === 0 ? <></> : (
                    <FlexContainer style={{ marginBottom: 48, width: '100%' }} flexDirection="column" alignItems="center">
                        <p style={{ textAlign: 'center', color: 'grey', fontSize: 14 }} className="font-weight-bold">
                            Yay! You have seen it all
                        </p>

                        {resetFilterWrapper}
                    </FlexContainer>

                )}
            >
                <div style={{ position: 'relative' }}>
                    {!isCataloguePage() && windowWidth >= 1440 && (
                        <Button type="tertiary" icon={<IconAlignTop rotate={270} />} style={{ position: 'absolute', right: 16, top: 8, zIndex: 102, borderRadius: 387.5 }} onClick={showShoppingList}>Shopping list</Button>
                    )}

                    <SubHeader
                        headerType={'tab'}
                        activeKey={currTab}
                        tabList={isCataloguePage() ? catalogueList.map(item => {
                            const isUpcoming = dayjs().isBefore(dayjs(item.start_date))
                            let daysLeft = dayjs().subtract(1, 'day').from(isUpcoming ? item.start_date : item.end_date, true)

                            if (isUpcoming) {
                                daysLeft = `In ${daysLeft}`
                            } else {
                                daysLeft = `${daysLeft} remaining`.replace('a ', '1 ')
                            }

                            return {
                                itemKey: item.key.toLowerCase(),
                                tab: (
                                    <div>
                                        <div style={{ marginBottom: -4 }}>{item.key?.split('||')[0]}</div>
                                        <div style={{ fontSize: 12 }}>{daysLeft}</div>
                                    </div>
                                ),
                                extra: item
                            }
                        }) : [
                            // { itemKey: 'catalogue', tab: 'Catalogue' },
                            { itemKey: 'coles', tab: "Coles" },
                            { itemKey: 'woolworths', tab: 'Woolworths' }
                        ]}
                        collapsible={isCataloguePage()}
                        onChange={(v) => {
                            if (v === 'catalogue') {
                                setSelectedCategory('Coles')
                            }

                            setCurrTab(v)
                        }}
                        tabBarExtraContent={!isCataloguePage() && (
                            <FlexContainer alignItems="center" style={{ height: 46 }}>
                                <Checkbox checked={showSpecialsOnly} onChange={e => setShowSpecialsOnly(e.target.checked as boolean)}>
                                    <span className="font-weight-bold" style={{ fontSize: 14 }}>
                                        {isMobile ? "Specials Only" : "Show Specials & Catalogues Only"}
                                    </span>
                                </Checkbox>
                            </FlexContainer>
                        )}
                    />
                    <div className="main-container" style={{ marginLeft: showCategoryFilterInline ? 0 : -208 }}>
                        {!showCategoryFilterInline && (
                            <div style={{ position: 'relative' }}>
                                <div
                                    className="responsive-background"
                                    style={{
                                        background: 'white',
                                        position: 'sticky',
                                        borderRadius: 4,
                                        top: 58 + (isCataloguePage() ? 13 : 0),
                                        width: 180,
                                        padding: 8,
                                        maxHeight: `calc(${windowHeight}px - 98px - 40px - ${isCataloguePage() ? 13 : 0}px)`,
                                        overflow: 'auto',
                                        visibility: categoryList.length ? 'visible' : 'hidden'
                                    }}
                                >
                                    <RadioGroup type='pureCard' value={selectedCategory} onChange={e => setSelectedCategory(e.target.value)} style={{ width: '100%' }}>
                                        {currTab !== 'catalogue' && (
                                            <Radio value='all' style={{ width: '100%' }}>
                                                All
                                            </Radio>
                                        )}
                                        {categoryList.map(category => {
                                            return (
                                                <Radio key={category} value={category} style={{ width: '100%' }}>
                                                    {category}
                                                </Radio>
                                            )
                                        })}
                                    </RadioGroup>
                                </div>
                            </div>
                        )}

                        <div className="content-wrapper">
                            <div
                                className="filter-wrapper responsive-background"
                                style={{
                                    fontSize: 14,
                                    padding: '8px 0',
                                    display: 'flex',
                                    position: 'sticky',
                                    top: 47,
                                    background: '#f2f3f5',
                                    zIndex: 100,
                                    // WebkitBackdropFilter: 'blur(20px)',
                                    // backdropFilter: "blur(20px)",
                                    alignItems: 'center',
                                    whiteSpace: 'nowrap',
                                    overflowX: 'auto',
                                    justifyContent: 'space-between'
                                }}
                            >
                                <FlexContainer gap="8px" alignItems="center" className="responsive-text">
                                    {categoryList.length > 0 && showCategoryFilterInline && (
                                        <>
                                            <Dropdown
                                                contentClassName="font-weight-regular"
                                                showTick
                                                position="bottomLeft"
                                                clickToHide
                                                render={
                                                    <Dropdown.Menu style={{ maxHeight: '400px', overflow: 'auto' }}>
                                                        <Dropdown.Item active={selectedCategory === 'all'} onClick={() => setSelectedCategory('all')}>
                                                            <span className="font-weight-bold">All</span>
                                                        </Dropdown.Item>

                                                        {categoryList.map(category => {
                                                            return (
                                                                <Dropdown.Item key={category} active={selectedCategory === category} onClick={() => setSelectedCategory(category)}>
                                                                    <span className="font-weight-bold">{category}</span>
                                                                </Dropdown.Item>
                                                            )
                                                        })}
                                                    </Dropdown.Menu>
                                                }
                                            >
                                                <span className="font-weight-regular" style={{ cursor: 'pointer' }}>
                                                    <span className="font-weight-bold">Category:</span>
                                                    <span>&nbsp; {selectedCategory === 'all' ? 'All' : selectedCategory}</span>
                                                </span>
                                            </Dropdown>

                                            <Divider layout="vertical" />
                                        </>
                                    )}

                                    <Dropdown
                                        contentClassName="font-weight-regular"
                                        showTick
                                        clickToHide
                                        render={
                                            <Dropdown.Menu>
                                                <Dropdown.Item active={sortBy === 'popularity'} onClick={() => setSortBy('popularity')} className="font-weight-bold">Popularity</Dropdown.Item>
                                                <Dropdown.Item active={sortBy === 'most-recent'} onClick={() => setSortBy('most-recent')} className="font-weight-bold">Most recent</Dropdown.Item>
                                                <Dropdown.Item active={sortBy === 'price-from-low-to-high'} onClick={() => setSortBy('price-from-low-to-high')} className="font-weight-bold">Price: Low-High</Dropdown.Item>
                                                {!isCataloguePage() && <Dropdown.Item active={sortBy === 'discount-from-high-to-low'} onClick={() => setSortBy('discount-from-high-to-low')} className="font-weight-bold">Discount: High-Low</Dropdown.Item>}
                                            </Dropdown.Menu>
                                        }
                                    >
                                        <span className="font-weight-regular" style={{ cursor: 'pointer' }}>
                                            <span className="font-weight-bold">Sort by:</span>
                                            <span>&nbsp; {(SORT_BY_DICT as any)[sortBy]}</span>
                                            {/* <IconChevronDown style={{ fontSize: 14, marginLeft: 6 }} /> */}
                                        </span>
                                    </Dropdown>

                                    <Divider layout="vertical" />

                                    <span className="font-weight-regular" style={{ cursor: 'pointer' }}>
                                        <AutoComplete
                                            key={v}
                                            showClear
                                            borderless
                                            placeholder="Search"
                                            prefix={<IconSearch />}
                                            defaultValue={searchKeyword}
                                            style={{ marginLeft: -8, width: 200 }}
                                            onClear={() => setSearchKeyword('')}
                                            data={autoCompleteList}
                                            loading={autoCompleteLoading}
                                            onSearch={debounce(handleAutoComplete, 500)}
                                            onSelect={(v: any) => setSearchKeyword(v)}
                                        // onEnterPress={(e: any) => {
                                        //     setSearchKeyword((e.target as any).value)
                                        // }}
                                        />
                                    </span>
                                </FlexContainer>

                                <FlexContainer alignItems="center" gap="8px" className="responsive-text">
                                    {hasFilter && <Button icon={<IconRefresh />} size="small" theme="borderless" onClick={handleResetFilter}>Reset filter</Button>}
                                    {!isMobile && !!totalNum && (
                                        <FlexContainer gap="4px" alignItems="center">
                                            {productListLoading && <IconSpin spin />}
                                            <div className="font-weight-bold">Showing 1-{Math.min(PAGE_SIZE * pageNum, totalNum)} of {totalNum}</div>
                                        </FlexContainer>
                                    )}
                                </FlexContainer>
                            </div>

                            {renderPageOnReady(loading, (

                                <div style={{ display: 'flex', flexWrap: 'wrap', gap: 12 }}>

                                    {productList.map(product => {
                                        return (
                                            <ProductCard
                                                key={product.sku_id}
                                                platform={product.platform}
                                                product={product}
                                                onClick={() => setSimilarProductSidesheetProps({ visible: true, platform: currTab, product })}
                                                cardProps={{ style: { width: isMobile || windowWidth < 700 ? '100%' : 'calc(50% - 6px)' } }}
                                                showAddToListButton={!window.location.pathname.includes('catalogue')}
                                                showCatalogueItemLabel
                                                exactMatchProduct={exactMatchList.find((item: any) => item.platform !== product.platform && item[`${product.platform}_sku_id`] === product.sku_id && item.name)}
                                                showSimpleOpenButton={['aldi', 'costco'].includes(product.platform)}
                                            // showShareButton={isInApp}
                                            />
                                        )
                                    })}

                                    {productList.length === 0 && (
                                        <FlexContainer flexDirection="column" alignItems="center" style={{ width: '100%' }}>
                                            <p style={{ width: '100%', textAlign: 'center', color: 'grey' }} className="font-weight-bold">
                                                Oops! No records found. <br /> Reset the filter and try again.
                                            </p>

                                            {resetFilterWrapper}
                                        </FlexContainer>

                                    )}
                                </div>
                            ))}

                        </div>
                    </div>

                    <SimilarProductSidesheet {...similarProductSidesheetProps} onCancel={() => setSimilarProductSidesheetProps({ visible: false })} />
                </div>
            </InfiniteScroll>

            {isMobile && (
                <div className="font-weight-bold responsive-background responsive-text" style={{ fontSize: 12, textAlign: 'center', position: 'sticky', bottom: 0, background: '#f2f3f5', padding: '4px 0' }}>
                    Showing 1-{Math.min(PAGE_SIZE * pageNum, totalNum)} of {totalNum}
                </div>
            )}

            {isMobile && !isInApp && (
                <div
                    className="responsive-background responsive-border"
                    style={{
                        position: 'absolute',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: 50,
                        width: 50,
                        borderRadius: '100%',
                        backgroundColor: '#fff',
                        color: 'black',
                        bottom: 128,
                        right: 8,
                        border: '1px solid rgb(224, 224, 224)',
                        boxShadow: 'rgba(33, 33, 33, 0.12) 0px 8px 16px 0px'
                    }}
                    onClick={showShoppingList}
                >
                    {cartItemList.length ? (
                        <>
                            <Badge count={cartItemList.length} style={{ position: 'absolute', top: -18, right: -26 }}></Badge>
                            <IconCart className="responsive-text" style={{ fontSize: 20, position: 'relative', top: 2, left: -2 }} />
                        </>
                    ) : (
                        <IconCart className="responsive-text" style={{ fontSize: 20 }} />
                    )}
                </div>
            )}

            <BackTop
                className="responsive-background responsive-border"
                target={() => document.querySelector('.infinite-scroll-wrapper')}
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: 50,
                    width: 50,
                    borderRadius: '100%',
                    backgroundColor: '#fff',
                    color: 'black',
                    bottom: isInApp ? 56 : navbarHeight + 16,
                    right: isMobileDevice() ? 8 : 36,
                    border: '1px solid rgb(224, 224, 224)',
                    boxShadow: 'rgba(33, 33, 33, 0.12) 0px 8px 16px 0px'
                }}
            >
                <IconArrowUp className="responsive-text" />
            </BackTop>

            {!isCataloguePage() && <GroceriesTourModal />}
        </div>

    )
}

export default GroceriesByPlatform