import { Banner, Button, Descriptions, Divider, Popconfirm, Radio, RadioGroup, Table, Toast } from "@douyinfe/semi-ui"
import { IFuelPrice, IFuelStation } from "../../../../typings"
import { IconClose, IconExternalOpen, IconEyeClosed, IconSend, IconStar, IconTriangleDown } from "@douyinfe/semi-icons"
import FlexContainer from "../../../../components/FlexContainer"
import ViewMoreContainer from "../../../../components/ViewMoreContainer"
import { useContext, useState } from "react"
import { checkIsOpenNow, DEFAULT_FUEL_STATION_LOGO, FuelMapContext } from "../.."
import { isEmpty } from "lodash-es"
import { AppContext } from "../../../../App"
import { storageWrapper } from "../../../../utils/indexDB"
import { FUEL_MAP_ADVANCED_SETTING_LOCAL_STORAGE_KEY } from "../../../../constants"
import { link } from "fs"
import { useAsyncEffect } from "ahooks"
import { request } from "../../../../utils"

import './index.less'
import classNames from "classnames"

const SOURCE_MAP: any = {
    NSW: {
        schemaName: 'Fuel Check NSW',
        link: 'https://www.fuelcheck.nsw.gov.au/app'
    },
    QLD: {
        schemaName: 'QLD Fuel Price Reporting Schema',
        link: 'https://www.energyandclimate.qld.gov.au/energy/vehicles-and-energy/fuel-price-reporting'
    },
    SA: {
        schemaName: 'SA Fuel Pricing Information Schema',
        link: 'https://www.sa.gov.au/topics/driving-and-transport/fuel-pricing/fuel-price-reporting'
    },
    TAS: {
        schemaName: 'Fuel Check Tasmania',
        link: 'https://www.fuelcheck.tas.gov.au/app'
    },
    WA: {
        schemaName: 'FuelWatch WA',
        link: 'https://www.fuelwatch.wa.gov.au/'
    },
    NT: {
        schemaName: 'MyFuel NT',
        link: 'https://myfuelnt.nt.gov.au/'
    }
}

interface IFuelStationDetailPaneProps {
    station: IFuelStation;
    onAdvancedSettingChange: () => void;
    onCancel: () => void;
}

const FuelStationDetailPane = ({ station, onAdvancedSettingChange, onCancel }: IFuelStationDetailPaneProps) => {
    const {
        fuelPriceDict,
        viewpointStats,
        selectedFuelType,
        fuelStationBrandOptionList,
        mapRef,
        setTmpStationIdRef,
        showOpenOnly
    } = useContext(FuelMapContext)

    console.log(selectedFuelType)

    const logoUrl = fuelStationBrandOptionList.find(item => item.value && (item.value as any).includes(station.brand))?.extra || DEFAULT_FUEL_STATION_LOGO
    const fuelPriceList = fuelPriceDict?.[station.station_id] || []

    const selectedFuelTypePriceList = selectedFuelType ? fuelPriceList.filter(price => selectedFuelType.split('-').includes(price.fuel_type)) : undefined
    const lowestPrice = Math.min(...selectedFuelTypePriceList?.map(item => item.price) as number[])

    const lowestPriceWidthPercent = (lowestPrice - viewpointStats.min) / (viewpointStats.max - viewpointStats.min) * 100
    const medPriceWidthPercent = (viewpointStats.med - viewpointStats.min) / (viewpointStats.max - viewpointStats.min) * 100

    const { isOpenNow, todayOpeningHour, tmrOpeningHour } = checkIsOpenNow(station)
    const { isMobile } = useContext(AppContext)

    const [nearbyStationList, setNearbyStationList] = useState<IFuelStation[]>([])
    const [brandFilter, setBrandFilter] = useState('')

    useAsyncEffect(async () => {
        if (!station?.station_id) return

        const respData = await request({
            method: 'post',
            url: '/api/v1/service/execute',
            data: {
                service: 'au.fuel.common.getNearbyFuelStations',
                requestParams: [
                    station,
                    selectedFuelType.split('-'),
                    lowestPrice
                ]
            }
        })

        setBrandFilter(station.brand)
        setNearbyStationList(respData || [])
    }, [station])

    return (

        <div
            className="store-popover-wrapper font-weight-regular"
            style={{ width: isMobile ? '100%' : 220, position: 'relative' }}
        >
            <FlexContainer
                justifyContent="space-between"
                className={classNames({
                    "responsive-background-secondary": isMobile
                })}
                gap="8px"
                flexDirection="column"
                style={isMobile ? { position: 'sticky', top: 0, background: 'white', paddingTop: 12, zIndex: 1 } : {}}
            >
                <FlexContainer>

                    <FlexContainer gap="8px">
                        <img src={logoUrl} width="36px" height="36px" style={{ borderRadius: 4, background: 'white', objectFit: 'contain' }} />

                        <div>
                            <div className="font-weight-bold">{station.name}</div>
                            {!!station?.address && (
                                <div style={{ fontWeight: 400, fontSize: 11, lineHeight: '14px', cursor: 'pointer' }} onClick={() => {
                                    window.open(`https://maps.google.com/?q=${station.address || station.name}`)
                                }}>{station.address}</div>
                            )}
                        </div>
                    </FlexContainer>

                    <Button
                        icon={<IconClose style={{ fontSize: 14 }} />}
                        type="tertiary"
                        style={{ position: 'absolute', padding: 2, height: 18, zIndex: 2, ...isMobile ? { top: 16, right: 0 } : { top: 0, right: 0 } }}
                        theme="borderless"
                        size="small"
                        onClick={onCancel}
                    />
                </FlexContainer>

                <Divider />
            </FlexContainer>


            <>
                <div style={{ fontWeight: 600, fontSize: 12, letterSpacing: -0.2, marginBottom: 2 }}>Quick action</div>

                <FlexContainer flexWrap="wrap" gap="6px" alignItems="center">
                    <Button
                        theme="borderless"
                        icon={<IconSend style={{ fontSize: 12, marginRight: -4 }} />}
                        style={{ fontSize: 12, padding: 0, height: 16 }}
                        size="small"
                        onClick={() => {
                            if (station.google_place_id) {
                                window.open(`https://www.google.com/maps/place/?q=place_id:${station.google_place_id}`)
                            } else {
                                window.open(`https://maps.google.com/?q=${station.address || station.name}`)
                            }
                        }}>
                        Direction
                    </Button>

                    <Divider layout="vertical" />

                    <Button
                        theme="borderless"
                        icon={<IconStar style={{ fontSize: 12, marginRight: -4 }} />}
                        style={{ fontSize: 12, padding: 0, height: 16 }}
                        size="small"
                        onClick={() => {
                            const data: any = storageWrapper.get(FUEL_MAP_ADVANCED_SETTING_LOCAL_STORAGE_KEY) || { disabledBrandList: [], personalDiscounts: {} }
                            let favouriteStationIds: string[] = data?.favouriteStationIds || []
                            if (favouriteStationIds.includes(station.station_id)) {
                                favouriteStationIds = favouriteStationIds.filter(id => id !== station.station_id)
                                Toast.success("Removed from favorite stations")
                            } else {
                                favouriteStationIds.push(station.station_id)
                                Toast.success("Added to favorite stations")
                            }
                            data.favouriteStationIds = favouriteStationIds
                            storageWrapper.set(FUEL_MAP_ADVANCED_SETTING_LOCAL_STORAGE_KEY, data)
                            onAdvancedSettingChange()
                        }}
                    >
                        Favorite
                    </Button>

                    <Divider layout="vertical" />

                    <Popconfirm
                        content={`Are you sure you want to hide all "${station.brand}" stations? You can change this anytime in the settings at the top right.`}
                        title="Confirm"
                        onConfirm={() => {
                            const data: any = storageWrapper.get(FUEL_MAP_ADVANCED_SETTING_LOCAL_STORAGE_KEY) || { disabledBrandList: [], personalDiscounts: {} }
                            data.disabledBrandList.push(station.brand)
                            storageWrapper.set(FUEL_MAP_ADVANCED_SETTING_LOCAL_STORAGE_KEY, data)
                            onAdvancedSettingChange()
                        }}
                    >
                        <Button
                            theme="borderless"
                            icon={<IconEyeClosed style={{ fontSize: 12, marginRight: -4 }} />}
                            style={{ fontSize: 12, padding: 0, height: 16 }}
                            size="small"
                        >
                            Hide
                        </Button>
                    </Popconfirm>
                </FlexContainer>
            </>

            {!!fuelPriceDict?.[station.station_id] && (
                <>
                    <Divider style={{ marginTop: 8 }} />
                    <div style={{ fontWeight: 600, fontSize: 12, letterSpacing: -0.2 }}>Fuel prices</div>
                    <ViewMoreContainer
                        collapse={(
                            <>
                                <Descriptions
                                    data={selectedFuelTypePriceList?.map((price) => {
                                        return {
                                            key: price.fuel_type,
                                            value: price.discountInfo ? (
                                                <FlexContainer gap="4px">
                                                    <span style={{ textDecoration: 'line-through' }}>{price.origin_price}</span>
                                                    <span>➡️</span>
                                                    <span>{price.price}</span>
                                                </FlexContainer>
                                            ) : price.price
                                        }
                                    }) as any}
                                />

                                <div style={{ marginTop: -4 }}>
                                    <FlexContainer justifyContent="flex-end" style={{ width: `${lowestPriceWidthPercent}%`, minWidth: 10 }}>
                                        <IconTriangleDown style={{ fontSize: 10 }} />
                                    </FlexContainer>

                                    <div
                                        style={{
                                            background: 'linear-gradient(90deg, #198754, #fc8800)',
                                            height: 10,
                                            width: '100%',
                                            borderRadius: 12,
                                            position: 'relative',
                                        }}
                                    >
                                    </div>

                                    <FlexContainer justifyContent="space-between" style={{ fontSize: 10, marginTop: -2 }}>
                                        <FlexContainer className="font-weight-bold" flexDirection="column" alignItems="flex-start">
                                            {viewpointStats.min}
                                            <div style={{ color: 'grey', marginTop: -10 }}>MIN</div>
                                        </FlexContainer>
                                        <FlexContainer className="font-weight-bold" flexDirection="column" alignItems="flex-end">
                                            {viewpointStats.max}
                                            <div style={{ color: 'grey', marginTop: -10 }}>MAX</div>
                                        </FlexContainer>
                                    </FlexContainer>

                                    <FlexContainer className="font-weight-bold" justifyContent="flex-end" style={{ fontSize: 10, marginTop: -30, width: `${medPriceWidthPercent}%`, minWidth: 10 }}>
                                        <FlexContainer flexDirection="column" alignItems="center">
                                            {viewpointStats.med}
                                            <div style={{ color: 'grey', marginTop: -10 }}>MED</div>
                                        </FlexContainer>
                                    </FlexContainer>
                                </div>
                            </>
                        )}
                        expand={(
                            <Descriptions
                                data={fuelPriceDict?.[station.station_id]?.map((price) => {
                                    return {
                                        key: price.fuel_type,
                                        value: price.discountInfo ? (
                                            <FlexContainer gap="4px">
                                                <span style={{ textDecoration: 'line-through' }}>{price.origin_price}</span>
                                                <span>➡️</span>
                                                <span>{price.price}</span>
                                            </FlexContainer>
                                        ) : price.price
                                    }
                                }) as any}
                            />
                        )}
                    />


                </>
            )}

            {!!station.opening_hours && !isEmpty(station.opening_hours) && (
                <>
                    <Divider style={{ marginTop: 8 }} />
                    <div style={{ fontWeight: 600, fontSize: 12, letterSpacing: -0.2 }}>Opening hours</div>
                    <ViewMoreContainer
                        collapse={(
                            <div className="font-weight-bold" style={{ fontSize: 11 }}>
                                {!isOpenNow
                                    ? <span style={{ color: 'rgb(249,57,32)' }}>Closed. Reopens at {tmrOpeningHour.StartTime}</span>
                                    : todayOpeningHour.IsOpen24Hours
                                        ? <span style={{ color: 'rgb(59,179,70)' }}>Open 24 hours</span>
                                        : <span style={{ color: 'rgb(59,179,70)' }}>Open utill {todayOpeningHour.EndTime}</span>}
                            </div>
                        )}
                        expand={(
                            <Descriptions
                                data={station.opening_hours.map(day => ({
                                    key: day.Day,
                                    value: day.IsOpen24Hours ? '24 Hours' : `${day.StartTime} - ${day.EndTime}`
                                }))}
                            />
                        )}
                    />
                </>
            )}

            <>
                <Divider style={{ marginTop: 8 }} />
                <div style={{ fontWeight: 600, fontSize: 12, letterSpacing: -0.2 }}>Cheaper {station.brand} stations nearby</div>
                <FlexContainer flexDirection="column">
                    {/* <div>
                        <RadioGroup type="button" buttonSize="small" value={brandFilter} onChange={(e) => setBrandFilter(e.target.value)}>
                            <Radio value={station.brand}>{station.brand} stations</Radio>
                            <Radio value='all'>All brands</Radio>
                        </RadioGroup>
                    </div> */}

                    {nearbyStationList.filter(station => !showOpenOnly || checkIsOpenNow(station)?.isOpenNow).length ? (
                        <Table
                            className="nearby-station-table-wrapper"
                            pagination={false}
                            size="small"
                            dataSource={nearbyStationList.filter(station => !showOpenOnly || checkIsOpenNow(station)?.isOpenNow)}
                            showHeader={false}
                            style={{ maxHeight: 200, overflow: 'auto' }}
                            columns={[
                                {
                                    render: (record: IFuelStation) => {
                                        return (
                                            <FlexContainer
                                                justifyContent="space-between"
                                                alignItems="center"
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => {
                                                    mapRef?.flyTo({
                                                        center: [record.longitude, record.latitude].map(parseFloat) as any,
                                                        offset: [0, -160]
                                                    })

                                                    setTmpStationIdRef?.(record.station_id)
                                                }}
                                            >
                                                <div style={{ fontSize: 12 }}>
                                                    <div className="font-weight-bold" style={{ marginBottom: -4 }}>{record.name.replace(station.brand, '').replace('  ', ' ').trim()}</div>
                                                    <div>{record.distance?.toFixed(1)}km</div>
                                                </div>
                                                <div className="font-weight-bold">{record.price}</div>
                                            </FlexContainer>

                                        )
                                    }
                                },
                            ]}
                        />
                    ) : (
                        <div className="font-weight-bold" style={{ color: 'rgb(59,179,70)', fontSize: 11 }}>
                            Cheapest within a 10km range
                        </div>
                    )}


                </FlexContainer>
            </>

            <>
                <Divider style={{ marginTop: 8 }} />
                <div style={{ fontWeight: 600, fontSize: 12, letterSpacing: -0.2 }}>Source</div>
                <FlexContainer>
                    <Button
                        theme="borderless"
                        icon={<IconExternalOpen style={{ fontSize: 11, marginLeft: -4 }} />}
                        type="primary"
                        iconPosition="right"
                        style={{ padding: 0, fontSize: 11, height: 14 }}
                        onClick={() => {
                            window.open(SOURCE_MAP[station.state]?.link)
                        }}
                    >
                        {SOURCE_MAP[station.state]?.schemaName}
                    </Button>
                </FlexContainer>
            </>
        </div>
    )
}

export default FuelStationDetailPane