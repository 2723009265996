import { AutoComplete, Button, Checkbox, Descriptions, Divider, Input, List, Popover, Toast, Tooltip } from "@douyinfe/semi-ui"
import { useCallback, useContext, useEffect, useRef, useState } from "react"
import { parseUrl, request } from "../../utils"
import LoadingButton from "../../components/LoadingButton"
import FlexContainer from "../../components/FlexContainer"
import { IconCheckCircleStroked, IconClear, IconMapPin, IconTickCircle, IconUploadError } from "@douyinfe/semi-icons"
import LazyMap from "../../components/LazyMap"
import { MAPBOX_TOKEN } from "../RewardFlightsByPlatform/components/AirportMap"
import { AppContext } from "../../App"
import Map, { Source, Layer, MapRef, ViewState, Marker } from 'react-map-gl';
import { debounce, replace } from "lodash-es"
import './index.less'
import StockCheckerHome from "./components/Home"
import { IconNavigation } from "@douyinfe/semi-icons-lab"
import { useAsyncEffect } from "ahooks"
import classNames from "classnames"

const KMART_STOCK_ENUM: any = {
    'Low': {
        label: "Out of stock",
        color: 'rgb(249,57,32)',
        icon: <IconClear style={{ color: 'rgb(249,57,32)', fontSize: 20 }} />
    },
    'Medium': {
        label: 'Low stock',
        color: 'rgb(252,136,0)',
        icon: <IconUploadError style={{ color: 'rgb(252,136,0)', fontSize: 20 }} />
    },
    'High': {
        label: 'In stock',
        color: 'rgb(59,179,70)',
        icon: <IconTickCircle style={{ color: 'rgb(59,179,70)', fontSize: 20 }} />
    }
}

const REPLACE_MAP: any = {
    chemistwarehouse: 'chemist warehouse',
    bigw: 'big w'
}

const WEEKDAY_MAP: any = {
    mon: 'Mon',
    tue: 'Tue',
    wed: 'Wed',
    thu: 'Thu',
    fri: 'Fri',
    sat: 'Sat',
    sun: 'Sun'
}

function replaceAndTrim(str: string, target: string, replacement: string) {
    return str
        .replace(new RegExp(target, 'gi'), replacement) // Case-insensitive replace
        .replace(/^[^a-zA-Z]+|[^a-zA-Z]+$/g, ''); // Trim non-letter characters
}

interface IStockCheckerProps {
    productUrl?: string;
}

const StockChecker = ({ productUrl }: IStockCheckerProps) => {
    const isPreFilled = !!productUrl

    const [url, setUrl] = useState(productUrl || '')
    const [postcode, setPostcode] = useState('')
    const [storeList, setStoreList] = useState<any[]>([])
    const [targetPlatform, setTargetPlatform] = useState('')
    const [autoCompleteOptionList, setAutoCompleteOptionList] = useState<any[]>([])
    const [autoCompleteLoading, setAutoCompleteLoading] = useState(false)

    const [displayOnlyInStockStores, setDisplayOnlyInStockStores] = useState(false)
    const [visiblePopoverStoreId, setVisiblePopoverStoreId] = useState('')
    const [confirmLoading, setConfirmLoading] = useState(false)

    const mapRef = useRef<MapRef>()
    const autoCompleteTimeoutRef = useRef<any>()
    const tmpStoreIdRef = useRef('')
    const autoTriggerRef = useRef(false)

    const { isDarkMode } = useContext(AppContext)

    useEffect(() => {
        if (autoTriggerRef.current || !isPreFilled || !postcode) {
            return
        }

        (document.querySelector('.stock-checker-btn') as any)?.click?.()
        autoTriggerRef.current = true
    }, [postcode])

    const handleParseUrl = useCallback(async (str: string) => {
        console.log(str, postcode)

        const { isUrl, host, path } = parseUrl(str)

        setStoreList([])
        setTargetPlatform('')

        if (!isUrl) {
            Toast.error("Invalid URL")
            return
        }

        if (!postcode) {
            Toast.error("Please enter postcode")
            return
        }

        setConfirmLoading(true)

        let skuId = ''
        let tmp = []
        let platform = ''
        const arr = path?.split('/')?.filter(Boolean) || []

        switch (true) {
            case host?.includes('bigw.com.au') && path?.startsWith('/product/'):
                platform = 'bigw'
                skuId = arr[arr.length - 1]
                skuId = skuId.split('-')[0]
                break
            case host?.includes('chemistwarehouse.com.au') && path?.startsWith('/buy/'):
                platform = 'chemistwarehouse'
                skuId = arr[1]
                break
            case host?.includes('officeworks.com.au') && path?.startsWith('/shop/officeworks/p/'):
                platform = 'officeworks'
                tmp = arr[arr.length - 1]?.split('-') || []
                skuId = tmp[tmp.length - 1]
                break
            case host?.includes('kmart.com.au') && path?.startsWith('/product/'):
                platform = 'kmart'
                tmp = arr[1]?.split('-') || []
                skuId = tmp[tmp.length - 1]
                break
            case host?.includes('bunnings.com.au'):
                platform = 'bunnings'
                skuId = arr?.[0]?.split('_')?.[1]?.slice(1)
                break
            default:
                Toast.error("Invalid URL")
                return
        }

        setTargetPlatform(platform)

        const respData = await request({
            url: '/api/v1/service/execute',
            method: 'post',
            data: {
                service: `au.${platform}.checkStockLevelByPostcodeAndSkuId`,
                requestParams: [postcode, skuId]
            }
        })

        let tmpList: any[] = []
        switch (platform) {
            case 'chemistwarehouse':
                tmpList = (respData?.stores || []).map((item: any) => {
                    item.isAvailable = item.products?.[0]?.available
                    item.storeDetail = {
                        store_id: item.id,
                        latitude: item.latitude,
                        longitude: item.longitude,
                        name: item.name,
                        address: [item.address, item.suburb, item.storestate + ' ' + item.postcode].filter(Boolean).map(item => item.trim()).join(', '),
                        opening_hours: {
                            mon: item.storemon,
                            tue: item.storetue,
                            wed: item.storewed,
                            thu: item.storethu,
                            fri: item.storefri,
                            sat: item.storesat,
                            sun: item.storesun,
                        },
                    }

                    return item
                })
                break
            case 'bigw':
                tmpList = (respData || []).map((item: any) => {
                    item.isAvailable = item.available
                    return item
                })
                break
            case 'kmart':
                tmpList = (respData?.data?.findInStores?.[0]?.inventory || []).map((item: any) => {
                    item.isAvailable = item.stockLevel !== 'Low'
                    return item
                })
                break
            case 'officeworks':
                const formatOfficeworksOpeningHour = (day: any) => {
                    if (!day) return 'N/A'
                    return `${day.open} - ${day.close}`
                }

                tmpList = (respData || []).map((item: any) => {
                    item.isAvailable = !!item.inStoreQuantity
                    item.storeDetail = {
                        ...item.storeDetail,
                        address: [item.storeLocation, item.storeDetail.state + ' ' + item.storeDetail.postcode].filter(Boolean).join(', '),
                        opening_hours: {
                            mon: formatOfficeworksOpeningHour(item.storeDetail?.meta_data?.openingHours?.[0]),
                            tue: formatOfficeworksOpeningHour(item.storeDetail?.meta_data?.openingHours?.[1]),
                            wed: formatOfficeworksOpeningHour(item.storeDetail?.meta_data?.openingHours?.[2]),
                            thu: formatOfficeworksOpeningHour(item.storeDetail?.meta_data?.openingHours?.[3]),
                            fri: formatOfficeworksOpeningHour(item.storeDetail?.meta_data?.openingHours?.[4]),
                            sat: formatOfficeworksOpeningHour(item.storeDetail?.meta_data?.openingHours?.[5]),
                            sun: formatOfficeworksOpeningHour(item.storeDetail?.meta_data?.openingHours?.[6])
                        }
                    }
                    return item
                })
                break
            case 'bunnings':
                const formatBunningsOpeningHour = (day: any) => {
                    if (!day) return 'N/A'
                    if (day.closed) return 'Closed'
                    return `${day.openingTime?.formattedHour} - ${day.closingTime?.formattedHour}`
                }

                tmpList = (respData || []).map((item: any) => {
                    item.isAvailable = item.products?.[0]?.stock?.stockLevelStatus?.code === "inStock"
                    item.storeDetail = {
                        store_id: item.name,
                        name: item.displayName,
                        latitude: item.geoPoint.latitude,
                        longitude: item.geoPoint.longitude,
                        address: item.address.formattedAddress,
                        opening_hours: {
                            mon: formatBunningsOpeningHour(item.openingHours.weekDayOpeningList.find((item: any) => item.weekDay === 'MONDAY')),
                            tue: formatBunningsOpeningHour(item.openingHours.weekDayOpeningList.find((item: any) => item.weekDay === 'TUESDAY')),
                            wed: formatBunningsOpeningHour(item.openingHours.weekDayOpeningList.find((item: any) => item.weekDay === 'WEDNESDAY')),
                            thu: formatBunningsOpeningHour(item.openingHours.weekDayOpeningList.find((item: any) => item.weekDay === 'THURSDAY')),
                            fri: formatBunningsOpeningHour(item.openingHours.weekDayOpeningList.find((item: any) => item.weekDay === 'FRIDAY')),
                            sat: formatBunningsOpeningHour(item.openingHours.weekDayOpeningList.find((item: any) => item.weekDay === 'SATURDAY')),
                            sun: formatBunningsOpeningHour(item.openingHours.weekDayOpeningList.find((item: any) => item.weekDay === 'SUNDAY'))
                        }
                    }

                    return item
                })
                break
            default:
                tmpList = respData || []
        }

        setStoreList(tmpList.map((item, idx) => ({
            ...item,
            storeDetail: {
                ...item.storeDetail,
                name: replaceAndTrim(item.storeDetail?.name, REPLACE_MAP[platform] || platform, ''),
            }
        })))
        setConfirmLoading(false)

        if (mapRef.current) {
            mapRef.current.getMap().flyTo({
                zoom: 11,
                center: [tmpList?.[0]?.storeDetail?.longitude, tmpList?.[0]?.storeDetail?.latitude],
                speed: 1.5
            })
        }

        return { platform, skuId }
    }, [postcode])

    // useEffect(() => {
    //     if (storeList.length === 0 || !mapRef.current) return

    //     console.log(storeList)

    //     storeList.forEach(store => {
    //         switch (targetPlatform) {
    //             case 'chemistwarehouse':
    //                 new mapboxgl.Marker().setLngLat([store.longitude, store.latitude]).addTo(mapRef.current?.getMap() as any)
    //                 break
    //             case 'officeworks':
    //                 new mapboxgl.Marker().setLngLat([store.storeDetail?.longitude, store.storeDetail?.latitude]).addTo(mapRef.current?.getMap() as any)
    //                 break
    //         }
    //     })

    // }, [storeList])

    useAsyncEffect(async () => {
        if (sessionStorage.getItem('postcode')) {
            setPostcode(sessionStorage.getItem('postcode') as string)
        }

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(async (position) => {
                const latitude = position.coords.latitude;
                const longitude = position.coords.longitude;
                console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);

                const respData = await request({
                    url: '/api/v1/service/execute',
                    method: 'post',
                    data: {
                        service: `au.common.getPostcodeByGeoPoint`,
                        requestParams: [{ lat: latitude, lon: longitude }]
                    }
                })

                if (respData && respData?.postcode) {
                    setPostcode(respData.postcode)
                    sessionStorage.setItem('postcode', respData.postcode)
                }

            }, function (error) {
                console.error('Error getting location:', error);
            });
        } else {
            console.log('Geolocation is not supported by this browser.');
        }
    }, [])

    const handleSuburbSearch = async (v: string) => {
        autoCompleteTimeoutRef.current = undefined

        if (!v) {
            return
        }

        setAutoCompleteLoading(true)

        const respData = await request({
            url: '/api/v1/service/execute',
            method: 'post',
            data: {
                service: `au.common.fuzzySearchSuburbOrPostcode`,
                requestParams: [v]
            }
        })

        setAutoCompleteOptionList([
            ...respData.map((item: any) => {
                return {
                    label: `${item.suburb} ${item.state} ${item.postcode}`,
                    value: item.postcode
                }
            })
        ])
        setAutoCompleteLoading(false)
    }

    if (storeList.length === 0 && !isPreFilled) {
        return <StockCheckerHome onUrlChange={setUrl} onSubmit={async () => handleParseUrl(url)} />
    }

    return (
        <div className="stock-checker-wrapper">
            {!isPreFilled && <h2 style={{ letterSpacing: -1, margin: '8px' }}>Check Stock in Your Local Stores</h2>}

            <FlexContainer gap='8px' style={{ margin: isPreFilled ? '4px 0 8px 0' : '8px' }}>
                <Input 
                    value={url} 
                    onChange={setUrl} 
                    size={isPreFilled ? "default" : "large"}
                    prefix="URL" 
                    disabled={isPreFilled}
                />

                <AutoComplete
                    data={autoCompleteOptionList}
                    value={postcode}
                    size={isPreFilled ? "default" : "large"}
                    prefix="Postcode"
                    style={{ width: 300 }}
                    onSearch={(v: string) => {
                        if (autoCompleteTimeoutRef.current) {
                            clearTimeout(autoCompleteTimeoutRef.current)
                        }

                        autoCompleteTimeoutRef.current = setTimeout(() => {
                            handleSuburbSearch(v)
                        }, 200)
                    }}
                    loading={autoCompleteLoading}
                    onChange={(v: any) => setPostcode(v)}
                    defaultActiveFirstOption
                />

                <LoadingButton 
                    className="stock-checker-btn"
                    type="primary" 
                    theme="solid" 
                    size={isPreFilled ? "default" : "large"}
                    onClick={async () => handleParseUrl(url)}
                >
                    Check
                </LoadingButton>
            </FlexContainer>


            <FlexContainer style={{ height: isPreFilled ? '360px' : '80vh', margin: isPreFilled ? 0 : '0 8px', borderRadius: '4px', overflow: 'hidden' }}>
                <List
                    loading={confirmLoading}
                    className="responsive-background-secondary"
                    style={{ width: '320px', background: 'white', overflowY: 'auto', overflowX: 'hidden' }}
                    header={(
                        <div>
                            <Checkbox onChange={(e: any) => setDisplayOnlyInStockStores(e.target.checked)}>In-stock stores only</Checkbox>
                        </div>
                    )}
                    dataSource={storeList.filter(store => !displayOnlyInStockStores || store.isAvailable)}
                    renderItem={(item: any, idx: number) => {
                        return (
                            <FlexContainer
                                className="custom-hover-background"
                                style={{ padding: '8px 16px', cursor: 'pointer', borderTop: idx !== 0 ? '1px solid rgb(230,232,234)' : 'none' }}
                                gap="12px"
                                alignItems="center"
                                onClick={() => {
                                    setVisiblePopoverStoreId('')
                                    mapRef.current?.getMap().flyTo({
                                        center: [item.storeDetail?.longitude, item.storeDetail?.latitude],
                                        zoom: 11,
                                    })
                                    tmpStoreIdRef.current = item.storeDetail.store_id
                                }}
                            >
                                { }
                                {targetPlatform === 'kmart'
                                    ? KMART_STOCK_ENUM[item.stockLevel].icon
                                    : item.isAvailable
                                        ? <IconTickCircle style={{ color: 'green', fontSize: 20 }} />
                                        : <IconClear style={{ color: 'rgb(249,57,32)', fontSize: 20 }} />}


                                <div>
                                    <div
                                        className="font-weight-black"
                                        style={{
                                            fontSize: 16,
                                            letterSpacing: -0.2,
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis'
                                        }}
                                    >
                                        {item.storeDetail.name}
                                    </div>
                                    <div className="font-weight-regular" style={{ fontSize: 13 }}>{item.isAvailable ? 'Available' : 'Out of stock'}</div>
                                </div>
                            </FlexContainer>
                        )
                    }}
                />

                <div style={{ width: '100%' }}>
                    <LazyMap
                        onRef={mapRef}
                        style={{ width: '100%', height: '100%' }}
                        mapStyle={isDarkMode ? "mapbox://styles/mapbox/dark-v11" : "mapbox://styles/mapbox/light-v11"}
                        mapboxAccessToken={MAPBOX_TOKEN}
                        onLoad={() => {
                            setTimeout(() => {
                                window.dispatchEvent(new Event('resize'))
                            }, 200)
                        }}
                        onMoveEnd={() => {
                            if (tmpStoreIdRef.current) {
                                setVisiblePopoverStoreId(tmpStoreIdRef.current)
                                tmpStoreIdRef.current = ''
                            }
                        }}
                        onMoveStart={() => {
                            setVisiblePopoverStoreId('')
                        }}
                        initialViewState={{
                            "longitude": 151.20699000000002,
                            "latitude": -33.87078,
                            "zoom": 13,
                            "bearing": 0,
                            "pitch": 0

                        }}
                    >
                        {storeList.map(store => {
                            if (!store.storeDetail) {
                                return <></>
                            }

                            return (
                                <Marker
                                    longitude={store.storeDetail?.longitude}
                                    latitude={store.storeDetail?.latitude}
                                    onClick={() => {
                                        // mapRef.current?.getMap().flyTo({
                                        //     center: [store.storeDetail?.longitude, store.storeDetail?.latitude],
                                        //     zoom: 12,
                                        // })
                                    }}
                                >
                                    <Popover
                                        trigger="custom"
                                        visible={visiblePopoverStoreId === store.storeDetail?.store_id}
                                        showArrow
                                        off
                                        content={(
                                            <div
                                                className="store-popover-wrapper"
                                                style={{ width: 180 }}
                                            >
                                                <FlexContainer justifyContent="space-between" alignItems="center" gap="8px">
                                                    {store.storeDetail.name}

                                                    <IconNavigation style={{ fontSize: 20, cursor: 'pointer' }} onClick={() => {
                                                        window.open(`https://maps.google.com/?q=${targetPlatform} ${store.storeDetail?.name}`)
                                                    }} />
                                                </FlexContainer>

                                                {!!store.storeDetail?.address && (
                                                    <div style={{ fontWeight: 400, fontSize: 11, lineHeight: '14px' }}>{store.storeDetail.address}</div>
                                                )}

                                                {!!store.storeDetail?.opening_hours && (
                                                    <>
                                                        <Divider style={{ marginTop: 8 }} />
                                                        <div style={{ fontWeight: 600, fontSize: 12, letterSpacing: -0.2 }}>Opening hours</div>
                                                        <Descriptions
                                                            data={Object.entries(store.storeDetail.opening_hours).map(([k, v]) => {
                                                                return {
                                                                    key: WEEKDAY_MAP[k],
                                                                    value: v
                                                                }
                                                            }) as any}
                                                        />
                                                    </>

                                                )}
                                            </div>
                                        )}
                                    >
                                        <div
                                            onClick={() => {
                                                setVisiblePopoverStoreId(store.storeDetail?.store_id)
                                            }}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            <IconMapPin
                                                style={{
                                                    color: targetPlatform === 'kmart' ? KMART_STOCK_ENUM[store.stockLevel]?.color : store.isAvailable ? 'rgb(59,179,70)' : 'rgb(249,57,32)',
                                                    fontSize: 30,
                                                }}
                                            />
                                        </div>

                                    </Popover>
                                </Marker>
                            )
                        })}
                    </LazyMap>
                </div>
            </FlexContainer>

        </div>
    )
}

export default StockChecker