import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react"
import { IGiftCardDetail, IGiftCardRetailerDetail } from "../../../typings"
import { useAsyncEffect } from "ahooks"
import { request } from "../../../utils"
import FlexContainer from "../../../components/FlexContainer"
import { Button, Divider, Form, Input, Popconfirm, Popover, Select, SideSheet, Table, Tabs, Toast, useFormApi } from "@douyinfe/semi-ui"
import { FormApi } from "@douyinfe/semi-ui/lib/es/form"
import { omit } from "lodash-es"
import { AppContext } from "../../../App"
import { IconDelete, IconDeleteStroked, IconPlus, IconSearch } from "@douyinfe/semi-icons"
import { SideSheetPlaceholder } from "../AdminLayout"

interface IGiftCardImageRenderProps {
    pic_url: string;
    margin_offset?: string;
    height: number;
    debug?: boolean
}

export const GiftCardRender = ({ pic_url, margin_offset, height, debug }: IGiftCardImageRenderProps) => {
    const divRef = useRef<any>()

    const arr = (margin_offset || '').split(' ').filter(Boolean).map((v) => parseFloat(v))
    const style = {
        marginTop: arr[0] || 0,
        marginRight: arr[1] || 0,
        marginBottom: arr[2] || 0,
        marginLeft: arr[3] || 0,
        borderRadius: 4,
    }

    const heightOffset = height / (80 + style.marginTop + style.marginBottom) // checked

    return (
        <FlexContainer justifyContent="center" alignItems="center" style={{ height, width: height * 1.6 }}>
            {!margin_offset ? (
                <img src={pic_url} height={height} style={{ borderRadius: 4 }} />
            ) : (
                <div style={{ height, overflow: 'hidden', ...debug ? { background: debug ? 'white' : 'transparent' } : { width: height * 1.6 } }}>
                    <div ref={divRef as any} style={{ transform: `scale(${heightOffset})`, transformOrigin: '0 0', overflow: 'visible' }}>
                        <img src={pic_url} height="80px" style={style} />
                    </div>
                </div>
            )}
        </FlexContainer >
    )
}

const GiftCardManagement = () => {
    const { navbarHeight, windowHeight } = useContext(AppContext)

    const [giftCardList, setGiftCardList] = useState<IGiftCardDetail[]>([])
    const [retailerList, setRetailerList] = useState<IGiftCardRetailerDetail[]>([])
    const [selectedGiftCard, setSelectedGiftCard] = useState<IGiftCardDetail>()
    const [loading, setLoading] = useState(false)
    const [formData, setFormData] = useState<Record<string, any>>({})

    const [popoverVisible, setPopoverVisible] = useState(false)
    const [retailerListLoading, setRetailerListLoading] = useState(false)
    const [searchKeyword, setSearchKeyword] = useState('')

    const formRef = useRef<FormApi>()

    useAsyncEffect(async () => {
        const respData = await request({
            url: '/api/v1/service/execute',
            method: 'POST',
            data: {
                service: 'rds.sql',
                requestParams: [
                    `SELECT * FROM simpo_octopus.au_gift_card`
                ]
            }
        })

        setGiftCardList(respData)
    }, [])

    useAsyncEffect(async () => {
        if (selectedGiftCard) {
            setRetailerList([])
            setRetailerListLoading(true)
            const respData = await request({
                data: {
                    service: 'rds.sql',
                    requestParams: [
                        `SELECT * FROM simpo_octopus.au_gift_card_retailer WHERE gift_card_id = "${selectedGiftCard.gift_card_id}" ORDER BY name ASC`
                    ]
                }
            })

            setRetailerList(respData)
            setRetailerListLoading(false)
        }
    }, [selectedGiftCard])

    useEffect(() => {
        if (selectedGiftCard) {
            formRef.current?.setValues(selectedGiftCard)
            setFormData(selectedGiftCard)
        }
    }, [selectedGiftCard])

    const handleSave = useCallback(async () => {
        setLoading(true)

        await request({
            url: '/api/v1/service/execute',
            method: 'POST',
            data: {
                service: 'rds.insert',
                requestParams: [
                    `simpo_octopus`, 'au_gift_card', omit(formData, ['created_at', 'updated_at']), {
                        onDuplicateKeyUpdate: {
                            'gift_card_id': 'VALUES(gift_card_id)',
                            'name': 'VALUES(name)',
                            'pic_url': 'VALUES(pic_url)',
                            'detail_url': 'VALUES(detail_url)',
                            'mutually_exclusive_terms': 'VALUES(mutually_exclusive_terms)',
                            'margin_offset': 'VALUES(margin_offset)',
                            'remark': 'VALUES(remark)',
                            'activation_fee': 'VALUES(activation_fee)'
                        }
                    }
                ]
            }
        })

        if (selectedGiftCard?.gift_card_id) {
            setGiftCardList(arr => {
                return arr.map(item => {
                    if (item.gift_card_auto_increment_id === formData.gift_card_auto_increment_id) {
                        return formData as any
                    } else {
                        return item
                    }
                })
            })
        } else {
            window.location.reload()
        }

        setLoading(false)
    }, [formData, selectedGiftCard])

    const handleCreateRetailer = useCallback(async (v: any) => {
        if (selectedGiftCard) {
            const data = {
                gift_card_id: selectedGiftCard.gift_card_id,
                name: v.name,
                logo: v.logo,
                channel: v.channel.join(',')
            }

            await request({
                data: {
                    service: 'rds.insert',
                    requestParams: [
                        'simpo_octopus', 'au_gift_card_retailer', data, { ignore: true }
                    ]
                }
            })
            Toast.success('Created successfully')
            setRetailerList(prev => [data, ...prev])
            setPopoverVisible(false)
        }
    }, [selectedGiftCard])

    const handleUpdateRetailer = useCallback(async (name: string, channel: string[]) => {
        if (selectedGiftCard) {
            await request({
                data: {
                    service: 'rds.update',
                    requestParams: [
                        'simpo_octopus', 'au_gift_card_retailer', {
                            channel: channel.join(',')
                        }, {
                            where: {
                                gift_card_id: selectedGiftCard.gift_card_id,
                                name
                            }
                        }
                    ]
                }
            })
            Toast.success('Update successfully')
            setRetailerList(prev => prev.map(item => {
                if (item.name === name) {
                    return {
                        ...item,
                        channel: channel.join(',')
                    }
                } else {
                    return item
                }
            }))
        }
    }, [selectedGiftCard])

    return (
        <FlexContainer style={{ padding: '0 16px' }}>
            <div>
                <FlexContainer justifyContent="space-between" style={{ padding: '8px 0' }}>
                    <div className="font-weight-black responsive-text" style={{ fontSize: 20, letterSpacing: -0.5 }}>
                        Gift Card Management
                    </div>

                    <Button icon={<IconPlus />} theme="solid" onClick={() => {
                        setSelectedGiftCard({
                            gift_card_id: '',
                            name: '',
                            pic_url: '',
                            detail_url: '',
                            activation_fee: '',
                            mutually_exclusive_terms: '',
                            margin_offset: '',
                            remark: ''
                        } as IGiftCardDetail)
                    }}>
                        Gift card
                    </Button>
                </FlexContainer>


                <FlexContainer gap="8px" flexWrap="wrap" style={{ flexGrow: 1, paddingBottom: 16, height: windowHeight - navbarHeight - 46, overflowY: 'auto', overflowX: 'hidden', boxSizing: 'border-box' }}>
                    {giftCardList?.map(gc => {
                        return (
                            <div key={gc.gift_card_id} style={{ cursor: 'pointer' }} onClick={() => setSelectedGiftCard(gc)}>
                                <GiftCardRender pic_url={gc.pic_url} margin_offset={gc.margin_offset} height={100} />
                            </div>

                        )
                    })}
                </FlexContainer>
            </div>

            <SideSheetPlaceholder />

            <SideSheet 
                visible={!!selectedGiftCard} 
                onCancel={() => setSelectedGiftCard(undefined)} 
                title={selectedGiftCard?.gift_card_id ? "Edit gift card meta data" : 'Create gift card'}
                mask={false}
            >
                <Tabs>
                    <Tabs.TabPane itemKey="general" tab="General">
                        <Form 
                            getFormApi={formApi => formRef.current = formApi} 
                            onValueChange={v => setFormData(prev => ({ ...prev, ...v }))}
                        >
                            <Form.Input label="Gift card ID" field="gift_card_id" />
                            <Form.Input label="Name" field="name" />
                            <Form.Input label="Logo" field="pic_url" />
                            <Form.Input label="Detail URL" field="detail_url" />
                            <Form.Input label="Activation fee" field="activation_fee" />
                            <Form.Input label="Mutually exclusive terms" field="mutually_exclusive_terms" />
                            <Form.Input label="Margin offset" field="margin_offset" />
                            <Form.TextArea label="Remark" field="remark" rows={10} />
                        </Form>

                        <Button type="primary" theme="solid" style={{ marginTop: 12 }} loading={loading} onClick={handleSave}>Save</Button>

                        <Divider style={{ margin: '24px 0 12px' }} />

                        {selectedGiftCard && (
                            <div style={{ marginBottom: 24 }}>
                                <div className="font-weight-bold responsive-text" style={{ marginBottom: 4 }}>Preview</div>
                                <GiftCardRender pic_url={selectedGiftCard.pic_url} margin_offset={formData.margin_offset} height={80} debug />
                            </div>
                        )}
                    </Tabs.TabPane>
                    {selectedGiftCard?.gift_card_id && (
                        <Tabs.TabPane itemKey="participating-retailers" tab={`Participating Retailers (${retailerList.length})`}>
                        <FlexContainer justifyContent="space-between" gap="6px" style={{ marginBottom: 6 }}>
                            <Input prefix={<IconSearch />} placeholder="Search for retailer" onChange={setSearchKeyword} />

                            <Popover trigger="custom" visible={popoverVisible} position="bottomRight" content={(
                                <div style={{ padding: '0 16px 16px', width: 300 }}>
                                    <Form onSubmit={handleCreateRetailer}>
                                        <Form.Input label="Name" field="name" rules={[{ required: true }]} />
                                        <Form.Input label="Logo" field="logo" rules={[{ required: true }]} />
                                        <Form.Select label="Channel" field="channel" multiple rules={[{ required: true }]} style={{ width: '100%' }} optionList={['in-store', 'online', 'exchange-card'].map(item => {
                                            return {
                                                label: item,
                                                value: item
                                            }
                                        })} />

                                        <Button theme="solid" type="primary" htmlType="submit">Create</Button>
                                    </Form>
                                </div>
                            )}>
                                <Button theme="solid" icon={<IconPlus />} onClick={() => setPopoverVisible(true)}>Retailer</Button>
                            </Popover>
                        </FlexContainer>

                        <Table
                            pagination={false}
                            dataSource={retailerList.filter(item => !searchKeyword || item.name.toLowerCase().includes(searchKeyword.toLowerCase()))}
                            showHeader={false}
                            loading={retailerListLoading}
                            size="small"
                            columns={[
                                {
                                    title: 'Retailer', render: (record: IGiftCardRetailerDetail) => {
                                        return (
                                            <FlexContainer justifyContent="space-between" alignItems="center">
                                                <div>
                                                    <div style={{ paddingLeft: 4 }}>{record.name}</div>
                                                    <Select
                                                        borderless
                                                        optionList={['in-store', 'online', 'exchange-card'].map(item => {
                                                            return {
                                                                label: item,
                                                                value: item
                                                            }
                                                        })}
                                                        multiple
                                                        value={record.channel.split(',').filter(Boolean)}
                                                        onChange={v => handleUpdateRetailer(record.name, v as string[])}
                                                    />
                                                </div>

                                                <Popconfirm okType="danger" title="Delete reminder" content="Are you sure to delete this retailer?" onConfirm={async () => {
                                                    await request({
                                                        data: {
                                                            service: 'rds.delete',
                                                            requestParams: [
                                                                'simpo_octopus',
                                                                'au_gift_card_retailer',
                                                                {
                                                                    where: {
                                                                        gift_card_id: record.gift_card_id,
                                                                        name: record.name
                                                                    }
                                                                }
                                                            ]
                                                        }
                                                    })
                                                    setRetailerList(arr => arr.filter(item => item.name !== record.name))
                                                    Toast.success('Deleted successfully')
                                                }}>
                                                    <Button type="danger" size="small" style={{ borderRadius: 32 }} icon={<IconDeleteStroked />} />
                                                </Popconfirm>
                                            </FlexContainer>
                                        )
                                    }
                                }
                            ]}
                        />
                        </Tabs.TabPane>
                    )}
                </Tabs>
            </SideSheet>
        </FlexContainer>
    )
}

export default GiftCardManagement