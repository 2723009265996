import { Dropdown, Tabs, Tag } from "@douyinfe/semi-ui"
import { TabsProps } from "@douyinfe/semi-ui/lib/es/tabs"
import './index.less'
import classNames from "classnames"
import dayjs, { extend } from "dayjs" 
import FlexContainer from "../FlexContainer"
import { useContext } from "react"
import { AppContext } from "../../App"
import { capitalize } from "lodash-es"

function formatFromTime(text: string): string {
    return text.replace(/^a /, '1 ');
  }

interface ISubHeaderProps extends TabsProps {
    headerType?: string
}

const SubHeader = ({ headerType, ...props }: ISubHeaderProps) => {

    const { isMobile, COLOR_MAP } = useContext(AppContext)

    if (headerType === 'dropdown') {
        const activeTab: any = props.tabList?.find(item => item.itemKey === props.activeKey)

        if (!activeTab) {
            return <></>
        }

        const isUpcoming = dayjs().isBefore(dayjs(activeTab?.extra?.start_date))
        let daysLeft = dayjs().subtract(1, 'day').from(isUpcoming ? activeTab?.extra?.start_date : activeTab?.extra?.end_date, true)

        if (isUpcoming) {
            daysLeft = `In ${daysLeft}`
        } else {
            daysLeft = `${daysLeft} remaining`
        }

        return (
            <div
                className={classNames({
                    // 'tablist-wrapper': true,
                    // 'tablist-wrapper-collapse': props.collapsible,
                    'responsive-background': true,
                    'dropdown-header-wrapper': true,
                })}
            >
                <FlexContainer justifyContent="space-between" className="header-wrapper responsive-text">
                    <Dropdown
                        clickToHide
                        position="topLeft"
                        returnFocusOnClose={false}
                        showTick
                        contentClassName={"catalogue-dropdown-item-wrapper"}
                        menu={props.tabList?.map((tab: any) => {
                            if (!tab?.extra) {
                                return {
                                    node: 'item', 
                                    name: tab.tab, 
                                    active: props.activeKey === tab.itemKey, 
                                    onClick: () => props.onChange?.(tab.itemKey)
                                }
                            }


                            const isUpcoming = dayjs().isBefore(dayjs(tab?.extra?.start_date))
                            let daysLeft = dayjs().subtract(1, 'day').from(isUpcoming ? tab?.extra?.start_date : tab?.extra?.end_date, true)
                    
                            if (isUpcoming) {
                                daysLeft = `In ${daysLeft}`
                            } else {
                                daysLeft = `${daysLeft} remaining`
                            }

                            return { 
                                node: 'item', 
                                name: (
                                    <div className="font-weight-bold" style={{ width: '100%' }}>
                                        <FlexContainer alignItems="center" justifyContent="space-between">
                                            <Tag style={{ background: COLOR_MAP[tab?.extra?.platform], color: 'white' }}>{capitalize(tab?.extra?.platform)}</Tag>
                                        </FlexContainer>
                                        <div style={{ paddingTop: 4 }}>
                                            <div>{tab.tab}</div>
                                            <div style={{ color: 'grey', fontSize: 12, letterSpacing: -0.5 }}>{formatFromTime(daysLeft)}</div>
                                        </div>
                                    </div>
                                ), 
                                active: props.activeKey === tab.itemKey, 
                                onClick: () => props.onChange?.(tab.itemKey)
                            }
                        }) as any}
                    >
                        <div style={{ overflow: 'hidden' }}>
                            <div style={{ fontSize: 20, letterSpacing: -0.5 }} className="text-overflow">
                                {activeTab?.tab}
                            </div>
                            <div style={{ fontSize: 14, fontWeight: 500 }}>
                                {activeTab?.extra?.offer_text}
                            </div>
                        </div>
                    </Dropdown>

                    {!isMobile && activeTab?.extra && <div>{formatFromTime(daysLeft)}</div>}
                </FlexContainer>
            </div>
        )
    }

    return (
        <div className={classNames({
            'tablist-wrapper': true,
            'tablist-wrapper-collapse': props.collapsible,
            'responsive-background': true
        })}>
            <Tabs {...props} />
        </div>
    )
}

export default SubHeader