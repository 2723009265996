export class IndexedDBWrapper<T> {
    private dbName: string;
    private version: number;
    private db: IDBDatabase | null = null;

    constructor(dbName: string, version: number) {
        this.dbName = dbName;
        this.version = version;
    }

    // Open a connection to the IndexedDB
    open(storeNames: string[]): Promise<IDBDatabase> {
        if (this.db) {
            return Promise.resolve(this.db)
        }

        return new Promise((resolve, reject) => {
            const request = indexedDB.open(this.dbName, this.version);

            // Handle upgrade needed (create object stores)
            request.onupgradeneeded = (event: IDBVersionChangeEvent) => {
                const db = (event.target as IDBOpenDBRequest).result;
                storeNames.forEach(storeName => {
                    if (!db.objectStoreNames.contains(storeName)) {
                        // Custom ID, not necessarily autoIncrement
                        db.createObjectStore(storeName, { keyPath: 'id' });
                    }
                });
            };

            // Handle successful open
            request.onsuccess = (event: Event) => {
                this.db = (event.target as IDBOpenDBRequest).result;
                resolve(this.db);
            };

            // Handle errors
            request.onerror = (event: Event) => {
                reject((event.target as IDBOpenDBRequest).error);
            };
        });
    }

    // Upsert an item: inserts if it doesn't exist, updates if it does
    upsert(storeName: string, data: T & { id: IDBValidKey }): Promise<IDBValidKey> {
        return new Promise((resolve, reject) => {
            if (!this.db) {
                reject(new Error("Database is not open"));
                return;
            }

            const transaction = this.db.transaction([storeName], 'readwrite');
            const store = transaction.objectStore(storeName);
            const request = store.put(data);  // 'put' acts as upsert

            request.onsuccess = () => {
                resolve(request.result);
            };

            request.onerror = (event: Event) => {
                reject((event.target as IDBRequest).error);
            };
        });
    }

    // Get an item by a custom ID from a specific store
    get(storeName: string, id: IDBValidKey): Promise<T | undefined> {
        return new Promise((resolve, reject) => {
            if (!this.db) {
                reject(new Error("Database is not open"));
                return;
            }

            const transaction = this.db.transaction([storeName], 'readonly');
            const store = transaction.objectStore(storeName);
            const request = store.get(id);

            request.onsuccess = () => {
                resolve(request.result as T);
            };

            request.onerror = (event: Event) => {
                reject((event.target as IDBRequest).error);
            };
        });
    }

    // Delete an item by a custom ID from a specific store
    delete(storeName: string, id: IDBValidKey): Promise<void> {
        return new Promise((resolve, reject) => {
            if (!this.db) {
                reject(new Error("Database is not open"));
                return;
            }

            const transaction = this.db.transaction([storeName], 'readwrite');
            const store = transaction.objectStore(storeName);
            const request = store.delete(id);

            request.onsuccess = () => {
                resolve();
            };

            request.onerror = (event: Event) => {
                reject((event.target as IDBRequest).error);
            };
        });
    }

    // Get all items from a specific store
    getAll(storeName: string): Promise<T[]> {
        return new Promise((resolve, reject) => {
            if (!this.db) {
                reject(new Error("Database is not open"));
                return;
            }

            const transaction = this.db.transaction([storeName], 'readonly');
            const store = transaction.objectStore(storeName);
            const request = store.getAll();

            request.onsuccess = () => {
                resolve(request.result as T[]);
            };

            request.onerror = (event: Event) => {
                reject((event.target as IDBRequest).error);
            };
        });
    }
}


export const storageWrapper = {
    get: <T>(key: string, storage = window.localStorage): T | string | null => {
        const item = storage.getItem(key);
        if (item === null) return null;

        try {
            return JSON.parse(item);
        } catch {
            return item; // 不是 JSON，直接返回原值
        }
    },
    set: (key: string, value: unknown, storage = window.localStorage): void => {
        const isObject = typeof value === "object" && value !== null;
        storage.setItem(key, isObject ? JSON.stringify(value) : String(value));
    },
    remove: (key: string, storage = window.localStorage): void => {
        storage.removeItem(key);
    }
};