import { useContext, useEffect, useState } from "react"
import { IFuelStation } from "../../../../typings"
import { Button, Checkbox, CheckboxGroup, Input, InputNumber, Modal, Radio, RadioGroup, Switch, Table, Tabs, Toast } from "@douyinfe/semi-ui"
import { OptionProps } from "@douyinfe/semi-ui/lib/es/select"
import FlexContainer from "../../../../components/FlexContainer"
import { IconSearch, IconSetting } from "@douyinfe/semi-icons"
import { FUEL_MAP_ADVANCED_SETTING_LOCAL_STORAGE_KEY } from "../../../../constants"
import { storageWrapper } from "../../../../utils/indexDB"
import { AppContext } from "../../../../App"
import './index.less'
import { FuelMapContext } from "../.."

interface IAdvancedSettingModal {
    fuelStationBrandList: OptionProps[];
    onSubmit: () => void;
}

interface IAdvancedSetting {
    disabledBrandList: string[],
    personalDiscounts: Record<string, any>,
    favouriteStationIds?: string[]
}

const AdvancedSettingModal = ({ fuelStationBrandList, onSubmit }: IAdvancedSettingModal) => {
    const [visible, setVisible] = useState(false)
    const [keyword, setKeyword] = useState('')

    const { isMobile } = useContext(AppContext)
    const { fuelStationList } = useContext(FuelMapContext)

    const [advancedSetting, setAdvancedSetting] = useState<IAdvancedSetting>({
        disabledBrandList: [],
        personalDiscounts: {}
    })
    
    useEffect(() => {
        if (!visible) {
            const cacheData: any = storageWrapper.get(FUEL_MAP_ADVANCED_SETTING_LOCAL_STORAGE_KEY)
            setAdvancedSetting(cacheData || {
                disabledBrandList: [],
                personalDiscounts: {}
            })
        }
    }, [visible])

    const handleOk = () => {
        localStorage.setItem(FUEL_MAP_ADVANCED_SETTING_LOCAL_STORAGE_KEY, JSON.stringify(advancedSetting))
        setVisible(false)
        Toast.success("Submitted successfully")
        onSubmit()
    }

    return (
        <>
            <Button icon={<IconSetting />} onClick={() => setVisible(true)} theme="solid"></Button>
            <Modal visible={visible} title="Advanced Setting" width={isMobile ? '100%' : 600} onCancel={() => setVisible(false)} onOk={handleOk}>
                <RadioGroup
                    type="button"
                    
                >
                    <Radio value={1}>All brands</Radio>
                    <Radio value={2}>Brands available in NSW</Radio>
                </RadioGroup>
                <Table
                    dataSource={fuelStationBrandList.filter((item: any) => {


                        return !keyword || item.label?.toLowerCase?.().includes(keyword.toLowerCase())
                    }).sort((a: any, b: any) => a.label.localeCompare(b.label))}
                    pagination={false}
                    size="small"
                    sticky
                    style={{ height: 400, overflow: 'auto' }}
                    columns={[
                        {
                            title: (
                                <FlexContainer alignItems="center" gap="8px">
                                    <span>Brand</span>
                                    <Input showClear size="small" onChange={setKeyword} prefix={<IconSearch />} style={{ width: 120 }} />
                                </FlexContainer>
                            ),
                            render: (record: any) => {
                                const stateCount: any = {}
                                const stationCount = fuelStationList.filter(station => {
                                    const isMatch = record.value?.includes(station.brand)

                                    if (isMatch) {
                                        if (!stateCount[station.state]) {
                                            stateCount[station.state] = 1
                                        } else {
                                            stateCount[station.state] += 1
                                        }
                                    }

                                    return isMatch
                                })?.length

                                return `${record.label} (${stationCount})`
                            }
                        },
                        {
                            title: (
                                <FlexContainer alignItems="center" gap="6px">
                                    <Checkbox checked={!advancedSetting.disabledBrandList?.length} onChange={e => {
                                        if (e.target.checked) {
                                            setAdvancedSetting(prev => {
                                                return {
                                                   ...prev,
                                                    disabledBrandList: []
                                                }
                                            })
                                        }
                                    }} />
                                    Visible?
                                </FlexContainer>
                            ),
                            width: 90,
                            render: (record: any) => {
                                return <Checkbox checked={!advancedSetting.disabledBrandList.includes(record.label)} onChange={(e) => {
                                    setAdvancedSetting(prev => {
                                        return {
                                            ...prev,
                                            disabledBrandList: !e.target.checked ? [...prev.disabledBrandList, record.label] : prev.disabledBrandList.filter(brand => brand !== record.label)
                                        }
                                    })
                                }} />
                            }
                        },
                        {
                            title: 'Discount',
                            width: 130,
                            render: (record: any) => {
                                return (
                                    <InputNumber
                                        size="small"
                                        style={{ width: 100 }}
                                        suffix={"¢/L"}
                                        max={0}
                                        defaultValue={advancedSetting.personalDiscounts?.[record.label]?.discountCentByLitre || 0}
                                        onChange={value => {
                                            setAdvancedSetting(prev => {
                                                return {
                                                    ...prev,
                                                    personalDiscounts: {
                                                        [record.label]: { discountCentByLitre: value }
                                                    }
                                                }
                                            })
                                        }}
                                    />
                                )
                            }
                        }
                    ]}
                />
            </Modal>
        </>
    )
}

export default AdvancedSettingModal