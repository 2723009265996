//@ts-nocheck
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Nav, Avatar, Dropdown, LocaleProvider, Button, Collapsible, SideSheet, BackTop, Modal, Popover } from '@douyinfe/semi-ui';
import { BrowserRouter, Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import DealsByPlatform from './pages/DealsByPlatform';
import { IconAppCenter, IconArrowUp, IconCart, IconClose, IconCoinMoneyStroked, IconContrast, IconExit, IconFile, IconGift, IconGlobeStroke, IconHome, IconHomeStroked, IconMember, IconMenu, IconMoon, IconMore, IconOrderedList, IconPriceTag, IconShoppingBag, IconSun, IconUser, IconUserStroked } from '@douyinfe/semi-icons';
import RewardFlightsByPlatform from './pages/RewardFlightsByPlatform';
import GroceriesByPlatform from './pages/GroceriesByPlatform';
import SystemStatus from './pages/ManagementPage/SystemStatus';
import en_US from '@douyinfe/semi-ui/lib/es/locale/source/en_US';
import FlexContainer from './components/FlexContainer';
import classNames from 'classnames';
import { CookieUtil, isHotAuDeals, isMobileDevice, request, toBase64Unicode } from './utils';
import { TabBar, TabBarItem } from 'tdesign-mobile-react';
import My from './pages/My';
import LoginForm from './pages/My/components/LoginForm';
import ShoppingList from './pages/ShoppingList';
import Label, { LabelV2 } from './pages/ManagementPage/Label';
import { useAsyncEffect } from 'ahooks';
import { IDeal } from './typings';
import VConsole from 'vconsole';
import { CART_ITEM_LIST_LOCAL_STORAGE_KEY, THEME_MODE_LOCAL_STORAGE_KEY, USER_INFO_SESSION_STORAGE_KEY } from './constants';
import ContactUs from './pages/ContactUs';
import Cashback from './pages/Cashback';
import Poster from './pages/ManagementPage/Poster';
import Embedded from './pages/Embedded';
import PrivacyPolicy from './pages/PrivacyPolicy';
import GiftCardManagement from './pages/ManagementPage/GiftCardManagement';
import ChromeExtensionModal from './components/CommonModal/ChromeExtensionModal';
import LoginCallback from './pages/LoginCallback';
import md5 from 'blueimp-md5';
import { capitalize } from 'lodash-es';
import GiftCards from './pages/GiftCards';
import GiftCardDetail from './pages/GiftCardDetail';
import CashbackMerchantManagement from './pages/ManagementPage/CashbackMerchantManagement';
import CashbackDetail from './pages/CashbackDetail';
import RewardFlightsDetail from './pages/RewardFlightsDetail';
import GiftCardPromotionManagement from './pages/ManagementPage/GiftCardPromotionManagement';
import AdminLayout from './pages/ManagementPage/AdminLayout';
import TrendingDealManagement from './pages/ManagementPage/TrendingDealManagement';
import CatalogueManagement from './pages/ManagementPage/CatalogueManagement';
import SqlRunner from './pages/ManagementPage/SqlRunner';
import ServiceRunner from './pages/ManagementPage/ServiceRunner';
import Article from './pages/Article';
import FlightRouteManagement from './pages/ManagementPage/FlightRouteManagement';
import { IconAccessibility, IconBanner, IconIntro, IconLayout, IconNavigation, IconNotification, IconRating, IconTag } from '@douyinfe/semi-icons-lab';
import { driver } from 'driver.js';
import { IPopoverActionEnum, IPopoverWithAction } from './components/TourContainer';
import './App.less';
import useTour from './hooks/useTour';
import { polyfillResizeObserver } from './utils/polyfill';
import KeyValueManagement from './pages/ManagementPage/KeyValueManagement';
import DatabaseSchema from './pages/ManagementPage/DatabaseSchema';
import HotelManagement from './pages/ManagementPage/HotelManagement';
import AirportManagement from './pages/ManagementPage/AirportManagement';
import ArticleManagement from './pages/ManagementPage/ArticleManagement';
import Toolbox from './pages/ManagementPage/Toolbox';
import RewardHotels from './pages/RewardHotels';
import RewardHotelDetail from './pages/RewardHotelDetail';
import ArticleDetail from './pages/ArticleDetail';
import NotFound from './pages/NotFound';
import { HotAuDealsLogo, RewardWatchLogo } from './components/Logo';
import DatabaseSynchronization from './pages/ManagementPage/DatabaseSynchronization';
import GroceriesDetail from './pages/GroceriesDetail';
import Cronjob from './pages/ManagementPage/Cronjob';
import TrendingCashbackManagement from './pages/ManagementPage/TrendingCashbackManagement';
import StockChecker from './pages/StockChecker';
import FuelMap from './pages/FuelMap';

const navItems = [
  { itemKey: 'deals', text: 'Deals', icon: <IconPriceTag />, hideInBrowser: false, hideInApp: true, visible: isHotAuDeals() },
  { itemKey: 'catalogue', text: 'Catalogue', icon: <IconPriceTag />, visible: isHotAuDeals() },
  { itemKey: 'groceries', text: 'Groceries', icon: <IconShoppingBag />, visible: isHotAuDeals() },

  { itemKey: 'gift-cards', text: 'Gift Cards', icon: <IconGift />,  visible: isHotAuDeals() },

  { itemKey: 'cashback', text: 'Cashback', icon: <IconCoinMoneyStroked />, hideInApp: true, visible: isHotAuDeals() },

  { itemKey: 'reward-flights', text: 'Flights', icon: <IconGlobeStroke />, hideInApp: true, visible: !isHotAuDeals() },
  { itemKey: 'reward-hotels', text: 'Hotels', icon: <IconHomeStroked />, hideInApp: true, visible: !isHotAuDeals() },

  { itemKey: 'articles', text: 'Articles', icon: <IconFile />, hideInApp: true, hideInBrowser: true, visible: isHotAuDeals() },

  { itemKey: 'shopping-list', text: 'Shopping List', icon: <IconCart />, hideInBrowser: true, visible: isHotAuDeals() },
  { itemKey: 'my', text: 'My', icon: <IconUser />, hideInBrowser: true, visible: isHotAuDeals() },
].filter(item => item.visible)

export const adminNavItems: {
  path: string;
  name: string;
  element: any;
  icon: any;
  category: string;
  hidden?: boolean;
}[] = [
    {
      "path": "/system-status",
      "name": "System Status",
      "element": <SystemStatus />,
      "icon": <IconBanner />,
      "category": "General"
    },
    // {
    //   "path": "/label",
    //   "element": <Label />,
    //   "hidden": true
    // },
    // {
    //   "path": "/label-v2",
    //   "element": <LabelV2 />,
    //   "hidden": true
    // },
    {
      "path": "/poster",
      "name": "Poster Creator",
      "element": <Poster />,
      "icon": <IconBanner />,
      "category": "General"
    },
    {
      "path": "/cronjob",
      "name": "Cronjob",
      "element": <Cronjob />,
      "icon": <IconBanner />,
      "category": "General"
    },

    {
      "path": "/gift-card-management",
      "name": "Card Management",
      "element": <GiftCardManagement />,
      "icon": <IconRating />,
      "category": "Gift Cards",
    },
    {
      "path": "/gift-card-promotion-management",
      "name": "Promotion management",
      "element": <GiftCardPromotionManagement />,
      "icon": <IconRating />,
      "category": "Gift Cards"
    },
    {
      "path": "/trending-deal-management",
      "name": "Trending Deal Management",
      "element": <TrendingDealManagement />,
      "icon": <IconTag />,
      "category": "Deals"
    },
    {
      "path": "/trending-cashback-management",
      "name": "Trending Cashback Management",
      "element": <TrendingCashbackManagement />,
      "icon": <IconCoinMoneyStroked />,
      "category": "Cashback"
    },
    {
      "path": "/cashback-merchant-management",
      "name": "Merchant Management",
      "element": <CashbackMerchantManagement />,
      "icon": <IconCoinMoneyStroked />,
      "category": "Cashback",
    },
    {
      "path": "/catalogue-management",
      "name": "Catalogue Management",
      "element": <CatalogueManagement />,
      "icon": <IconTag />,
      "category": "Deals"
    },

    {
      "path": "/hotel-management",
      "name": "Hotel Management",
      "element": <HotelManagement />,
      "icon": <IconIntro />,
      "category": "Hotels"
    },

    {
      "path": "/airport-management",
      "name": "Airport Management",
      "element": <AirportManagement />,
      "icon": <IconNavigation />,
      "category": "Flights"
    },
    {
      "path": "/flight-route-management",
      "name": "Route management",
      "element": <FlightRouteManagement />,
      "icon": <IconNavigation />,
      "category": "Flights"
    },

    {
      "path": "/article-management",
      "name": "Article management",
      "element": <ArticleManagement />,
      "icon": <IconLayout />,
      "category": "Articles"
    },
    {
      "path": "/service-runner",
      "name": "Service Runner",
      "element": <ServiceRunner />,
      "icon": <IconAccessibility />,
      "category": "Utils"
    },
    {
      "path": '/sql-runner',
      "name": "SQL Runner",
      "element": <SqlRunner />,
      "icon": <IconAccessibility />,
      "category": "Utils"
    },
    {
      "path": '/database-schema',
      "name": "Database Schema",
      "element": <DatabaseSchema />,
      "icon": <IconAccessibility />,
      "category": "Utils"
    },
    {
      "path": "/database-synchronization",
      "name": "Database Synchronization",
      "element": <DatabaseSynchronization />,
      "icon": <IconAccessibility />,
      "category": "Utils"
    },
    {
      "path": "/configuration-management",
      "name": "Configuration Management",
      "element": <KeyValueManagement />,
      "icon": <IconAccessibility />,
      "category": "Utils"
    },

    {
      "path": "/toolbox",
      "name": 'Toolbox',
      "element": <Toolbox />,
      "icon": <IconAccessibility />,
      "category": "Utils"
    }
  ]

const Navigation = () => {
  const { isMobile, windowWidth, showLoginForm, showShoppingList, cartItemList, isLogin, isDarkMode, userInfo } = useContext(AppContext)

  const navigate = useNavigate()
  const [selectedKeys, setSelectedKeys] = useState([window.location.pathname.split('/').filter(Boolean)[0] || 'groceries'])
  const [mobileSidebarVisible, setMobileSidebarVisible] = useState(false)

  const currNavItem = useMemo(() => {
    return navItems.find(item => item.itemKey === selectedKeys?.[0])?.text
  }, [selectedKeys])

  const getFooter = useCallback((forcePc = false) => {
    if (isMobile && !forcePc) {
      return (
        <div>
          <Button icon={<IconMenu style={{ fontSize: 20 }} onClick={() => setMobileSidebarVisible(true)} />} />
        </div>
      )
    }

    const cachedThemeMode = localStorage.getItem(THEME_MODE_LOCAL_STORAGE_KEY)

    const handleChangeTheme = (theme: string) => {
      if (theme === 'auto') {
        localStorage.removeItem(THEME_MODE_LOCAL_STORAGE_KEY)
        window.location.reload()
        return
      }

      localStorage.setItem(THEME_MODE_LOCAL_STORAGE_KEY, theme)
      window.location.reload()
    }

    return (
      <FlexContainer gap="8px" flexDirection={isMobile ? 'column' : 'row'}>
        {selectedKeys.includes('groceries') && !isMobile && (
          <>
            <Button icon={<IconCart />} style={{ background: 'hsla(0,0%,100%,0.1)', color: 'white', borderRadius: 32 }} onClick={showShoppingList}>
              <span className="font-weight-bold">{cartItemList.length} {cartItemList.length > 1 ? 'items' : 'item'}</span>
            </Button>
          </>
        )}

        <Dropdown
          trigger='hover'
          menu={[
            {
              node: 'item',
              icon: <img width="20" height="20" src={`https://img.icons8.com/ios-glyphs/50/${isDarkMode ? 'FFFFFF' : '000000'}/chrome.png`} alt="chrome--v1" />,
              name: <span className="font-weight-bold">Chrome Extension</span>,
              active: !cachedThemeMode,
              onClick: () => window.open('https://chromewebstore.google.com/detail/hotaudeals-helper/hhpnflehmikmmkneofkchgjgjbmmbalg')
            },
            // { 
            //   node: 'item', 
            //   icon: <img width="20" height="20" src={`https://img.icons8.com/ios-glyphs/50/${isDarkMode ? 'FFFFFF' : '000000'}/apple-app-store.png`} alt="apple-app-store"/>              , 
            //   name: <span className="font-weight-bold">iOS App</span>, 
            //   active: cachedThemeMode && !isDarkMode, 
            //   onClick: () => handleChangeTheme('light') 
            // },
          ]}
          position={isMobile ? 'bottomLeft' : 'bottomRight'}
          zIndex={isMobile ? 1001 : 103}
          spacing={10}
        >
          {isMobile && (
            <Button type="tertiary" icon={<IconAppCenter />} style={isDarkMode ? { background: 'hsla(0,0%,100%,0.1)', color: 'white', borderRadius: 32 } : { borderRadius: 32 }}>
              Download App
            </Button>
          )}

          {!isMobile && <Button icon={<IconAppCenter />} style={{ background: 'hsla(0,0%,100%,0.1)', color: 'white', borderRadius: 32 }} />}
        </Dropdown>

        <Dropdown
          trigger='hover'
          showTick
          menu={[
            { node: 'item', icon: <IconContrast />, name: <span className="font-weight-bold">Use device theme</span>, active: !cachedThemeMode, onClick: () => handleChangeTheme('auto') },
            { node: 'item', icon: <IconSun />, name: <span className="font-weight-bold">Light theme</span>, active: cachedThemeMode && !isDarkMode, onClick: () => handleChangeTheme('light') },
            { node: 'item', icon: <IconMoon />, name: <span className="font-weight-bold">Dark theme</span>, active: cachedThemeMode && isDarkMode, onClick: () => handleChangeTheme('dark') },
            // { node: 'light', name: 'Light theme' },
          ]}
          position={isMobile ? 'bottomLeft' : 'bottomRight'}
          zIndex={isMobile ? 1001 : 103}
          spacing={10}
        >
          {isMobile && (
            <Button type="tertiary" icon={isDarkMode ? <IconSun /> : <IconMoon />} style={isDarkMode ? { background: 'hsla(0,0%,100%,0.1)', color: 'white', borderRadius: 32 } : { borderRadius: 32 }}>
              Theme mode: {capitalize(!!cachedThemeMode ? 'default' : (isDarkMode ? 'dark' : 'light'))}
            </Button>
          )}

          {!isMobile && <Button icon={isDarkMode ? <IconSun /> : <IconMoon />} style={{ background: 'hsla(0,0%,100%,0.1)', color: 'white', borderRadius: 32 }} />}
        </Dropdown>

        {!isLogin ? (
          <div>
            {isMobile ? (
              <Button type="tertiary" icon={<IconUserStroked />} style={isDarkMode ? { background: 'hsla(0,0%,100%,0.1)', color: 'white', borderRadius: 32 } : { borderRadius: 32 }} onClick={showLoginForm}>
                <span className="font-weight-bold">Log in</span>
              </Button>
            ) : (
              <Button icon={<IconUserStroked />} style={{ background: 'hsla(0,0%,100%,0.1)', color: 'white', borderRadius: 32 }} onClick={showLoginForm}>
                <span className="font-weight-bold">Log in</span>
              </Button>
            )}
          </div>
        ) : (
          <div>
            <Popover
              showArrow={!isMobile}
              position={isMobile ? 'topLeft' : 'bottomRight'}
              style={{ padding: 0, overflow: 'hidden' }}
              spacing={10}
              zIndex={isMobile ? 1001 : 103}
              content={
                <My />
              }
            >
              {isMobile ? (
                <Button type="tertiary" icon={<IconUserStroked />} style={{ borderRadius: 32, textAlign: 'left', height: 40, paddingRight: 16, ...isDarkMode ? { background: 'hsla(0,0%,100%,0.1)', color: 'white' } : {} }}>
                  <div>
                    <div>Sign in as</div>
                    <div style={{ fontSize: 12, marginTop: -4 }}>{userInfo?.email}</div>
                  </div>
                </Button>
              ) : (
                <Avatar size="small" src="https://img.icons8.com/ios-glyphs/120/EBEBEB/fire-element--v1.png" style={{ background: 'lightgrey', padding: 4, boxSizing: 'border-box' }} />
              )}
            </Popover>
          </div>
        )}


      </FlexContainer>
    )
  }, [isLogin, isMobile, selectedKeys, cartItemList, isDarkMode])

  return (
    <div className='navbar-wrapper'>
      <Nav
        style={{ height: 52, background: '#35373b' }}
        header={(
          <FlexContainer alignItems='center' style={{ cursor: 'pointer' }} onClick={() => {
            if (isHotAuDeals()) {
              navigate('/')
              setSelectedKeys(['groceries'])
            } else {
              navigate('/reward-flights')
              setSelectedKeys(['reward-flights'])
            }

          }}>
            {isHotAuDeals()
              ? <HotAuDealsLogo />
              : <RewardWatchLogo /> 
            }

            {isMobile && currNavItem && windowWidth > 400 && (
              <div style={{ padding: '4px 8px', marginLeft: 8, borderRadius: 20, background: '#FFFFFFEE', color: 'rgb(53, 55, 59)', fontWeight: 600, whiteSpace: 'nowrap' }}>
                {currNavItem}
              </div>
            )}
          </FlexContainer>
        )}
        mode="horizontal"
        items={isMobile ? [] : navItems.filter(item => !item.hideInBrowser)}
        selectedKeys={selectedKeys}
        onClick={(item) => {
          setSelectedKeys([item.itemKey] as string[])
          navigate(item.itemKey as string)
        }}
        footer={getFooter()}
      />

      <SideSheet
        visible={mobileSidebarVisible}
        width="80%"
        bodyStyle={{ padding: '0 8px' }}
        onCancel={() => setMobileSidebarVisible(false)}
        footer={getFooter(true)}
      >
        <div className="mobile-navbar-wrapper">
          <Nav
            bodyStyle={{ height: 320 }}
            items={navItems.filter(item => !item.hideInBrowser)}
            onClick={(item) => {
              setMobileSidebarVisible(false)
              setSelectedKeys([item.itemKey] as string[])
              navigate(item.itemKey as string)
            }}
            style={{ width: '100%', height: '100%' }}
          />
        </div>
      </SideSheet>
    </div>
  )
}

const MobileNavigation = () => {
  const { isLogin, isInApp, cartItemList } = useContext(AppContext)

  const [selectedKeys, setSelectedKeys] = useState(window.location.pathname.split('/').filter(Boolean)[0] || 'groceries')
  const navigate = useNavigate()

  useEffect(() => {
    let initialHeight = window.innerHeight;

    const pageEl = document.querySelector('.page-wrapper')
    const tabBarEl = document.querySelector('.mobile-navbar-wrapper .t-tab-bar');

    const handleResize = () => {
      const currentHeight = window.innerHeight;

      if (initialHeight - 1 > currentHeight) {
        // Keyboard is most likely visible
        tabBarEl.style.display = 'none';
        pageEl.style.height = '100vh'
      } else {
        // Keyboard is hidden
        tabBarEl.style.display = 'flex';
        pageEl.style.height = `calc(100vh - ${isInApp ? 48 : 52}px)`
      }
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [isInApp])

  return (
    <div className="mobile-navbar-wrapper" style={{ borderTop: '1px solid #eaeaea !important' }}>
      <TabBar
        value={selectedKeys}
        bordered={false}
        fixed={false}

        onChange={(currTab) => {
          setSelectedKeys(currTab)
          navigate(currTab)
        }}
      >
        {navItems.filter(item => !item.hideInApp).map((item, i) => (
          <TabBarItem key={item.itemKey || i} icon={item.icon} value={item.itemKey} badgeProps={item.itemKey === 'shopping-list' ? {
            count: cartItemList.length,
            offset: [5, -7],
            style: {
              background: 'rgb(102,102,102)',
              padding: '0',
              fontWeight: 700,
              border: '1px solid white',
              // boxSizing: 'border-box'
            }
          } : undefined}>
            <span className="font-weight-bold" style={{ fontSize: 12, whiteSpace: 'nowrap' }}>{item.text}</span>
          </TabBarItem>
        ))}
      </TabBar>
    </div>
  )
}

export const AppContext = createContext<{
  isMobile: boolean,
  windowWidth: number,
  windowHeight: number,
  navbarHeight: number,
  isInApp: boolean,
  isLogin: boolean,
  isWindowLoaded: boolean,
  isDarkMode: boolean,
  isEmbedded: boolean,

  userInfo: {
    email: string,
  },
  cartItemList: IDeal[],

  COLOR_MAP: Record<string, string>,

  showLoginForm: () => {},
  setUserInfo: (v: any) => {},
  updateCart: (action: string, item?: any) => {},
  showShoppingList: () => {},
  hideShoppingList: () => {}
}>({
  isMobile: false,
  windowWidth: 0,
  windowHeight: 0,
  navbarHeight: 52,
  isInApp: true,
  isLogin: false,
  isWindowLoaded: false,
  isDarkMode: false,
  isEmbedded: false,

  userInfo: undefined,
  cartItemList: [],

  showLoginForm: () => { },
  setUserInfo: () => { },
  updateCart: () => { },
  showShoppingList: () => { },
  hideShoppingList: () => { },

  COLOR_MAP: {}
})

function App() {

  let cachedUserInfo = undefined
  const cachedUserInfoStr = CookieUtil.get(USER_INFO_SESSION_STORAGE_KEY)

  if (cachedUserInfoStr) {
    try {
      const json = JSON.parse(cachedUserInfoStr)

      if (md5(`${json.ts}${json.data.email}${json.ts}`) === json.sign) {
        cachedUserInfo = json.data
      }
    } catch (err) {
      CookieUtil.delete(USER_INFO_SESSION_STORAGE_KEY)
    }
  }

  const location = useLocation();
  const { handleAddToVisitedTourId } = useTour()

  const isDev = window.location.host.includes('localhost')
  const isInApp = window.location.host.includes('inapp') || localStorage.getItem('inapp') === '1' || window.location.search.includes('inapp=1')
  const isEmbedded = window.location.pathname.includes('embedded')
  const isFuelMap = window.location.pathname.includes('fuel-map')

  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const [windowHeight, setWindowHeight] = useState(window.innerHeight)

  const [loginFormSidesheetVisible, setLoginFormSidesheetVisible] = useState(false)
  const [shoppingListSidesheetVisible, setShoppingListSidesheetVisible] = useState(false)

  const [isDarkMode, setIsDarkMode] = useState(false)
  const [isWindowLoaded, setIsWindowLoaded] = useState(false)

  const [appInfo, setAppInfo] = useState({
    isLogin: !!cachedUserInfo,
    userInfo: cachedUserInfo,
    COLOR_MAP: {},
    v: 1
  })

  const [cartItemList, setCartItemList] = useState<IDeal[]>([])

  useEffect(() => {
    const colorScheme = isDarkMode ? 'dark' : 'light'
    const metaTag = document.querySelector('meta[name="color-scheme"]');

    if (metaTag) {
      // Update the content of the meta tag
      metaTag.setAttribute('content', colorScheme);
    } else {
      // If the meta tag doesn't exist, create it
      const newMetaTag = document.createElement('meta');
      newMetaTag.name = "color-scheme";
      newMetaTag.content = colorScheme;
      document.head.appendChild(newMetaTag);
    }
  }, [isDarkMode])

  useAsyncEffect(async () => {
    if (isMobileDevice()) {
      document.body.classList.add('App_mobile')
    } else {
      document.body.classList.add('App_pc')
    }

    if (isInApp) {
      document.body.classList.add('App_inapp')
    }

    if (isEmbedded) {
      document.body.classList.add('App_embedded')
    }

    // handle resize
    const handleResize = () => {
      setWindowWidth(window.innerWidth)
      setWindowHeight(window.innerHeight)
    }

    window.addEventListener('resize', handleResize)

    // handle window load
    const handleLoad = () => setIsWindowLoaded(true)
    window.addEventListener('load', handleLoad)

    // handle theme mode change
    const handleDarkMode = () => {
      if (isEmbedded) return

      const body = document.body;

      const cachedThemeMode = localStorage.getItem(THEME_MODE_LOCAL_STORAGE_KEY)
      if (cachedThemeMode) {
        body.setAttribute('theme-mode', cachedThemeMode);
        body.classList.add(`App_${cachedThemeMode}`)
        setIsDarkMode(cachedThemeMode === 'dark')
        return
      }

      if (e.matches) {
        body.setAttribute('theme-mode', 'dark');
        body.classList.add('App_dark')
        setIsDarkMode(true)
      } else {
        body.setAttribute('theme-mode', 'light')
        body.classList.add('App_light')
        setIsDarkMode(false)
      }
    }

    const e = window.matchMedia('(prefers-color-scheme: dark)');
    e.addEventListener('change', handleDarkMode);

    handleResize()
    handleDarkMode()


    const colorMapRespData = await request({
      method: 'POST',
      url: '/api/v1/service/execute',
      data: {
        service: 'rds.sql',
        requestParams: [
          `SELECT * FROM simpo_octopus.key_value_pair WHERE type = "color"`
        ]
      }
    })

    setAppInfo(prev => ({
      ...prev,
      COLOR_MAP: Object.assign({}, ...colorMapRespData.map((item: any) => ({ [item.key]: item.value })))
    }))

    return () => {
      window.removeEventListener('resize', handleResize)
      window.removeEventListener('load', handleLoad)
      e.removeEventListener('change', handleDarkMode)
    }
  }, [])

  useEffect(() => {
    if (isWindowLoaded) {
      setTimeout(() => {
        const elList = Array.from(document.querySelectorAll('[data-tour-id]:not([data-tour-id=""])'))

        const obj = driver({
          popoverClass: 'responsive-background-secondary',
          allowClose: false,
          showProgress: true,
          steps: elList.map((el, idx) => {
            const tourId = el.getAttribute('data-tour-id')
            const order = parseInt(el.getAttribute('data-tour-order') || '1')
            const popoverDetail: IPopoverWithAction = JSON.parse(el.getAttribute('data-tour-popover') || {})

            return {
              element: `[data-tour-id="${tourId}"]`,
              order,
              popover: {
                ...popoverDetail,
                onNextClick: (el: any) => {
                  obj.moveNext()

                  if (popoverDetail.action === IPopoverActionEnum.CLICK_FIRST_CHILD) {
                    if (el?.firstChild) {
                      el.firstChild.click()
                    }
                  }

                  handleAddToVisitedTourId(tourId)
                }
              }
            }
          }).sort((a, b) => a.order - b.order)
        })

        obj.drive()
      }, 500)
    }
  }, [isWindowLoaded, location])

  useAsyncEffect(async () => {
    const isGroceriesPage = window.location.pathname.startsWith('/groceries')

    if (appInfo.userInfo && appInfo.userInfo.email) {
      const ts = Date.now()

      CookieUtil.set(USER_INFO_SESSION_STORAGE_KEY, JSON.stringify({
        data: appInfo.userInfo,
        ts,
        sign: md5(`${ts}${appInfo.userInfo.email}${ts}`)
      }), { days: 30 })
    } else {
      CookieUtil.delete(USER_INFO_SESSION_STORAGE_KEY)
    }

    if (!isGroceriesPage) {
      return
    }

    setCartItemList([])

    if (appInfo.isLogin) {
      const cartItemListRespData = await request({
        url: '/api/v1/service/execute',
        method: 'POST',
        data: {
          service: 'rds.sql',
          requestParams: [
            `SELECT * FROM simpo_octopus.au_user_cart_item WHERE email = "${appInfo.userInfo.email}"`
          ]
        }
      })

      setCartItemList(cartItemListRespData)

      if (!isMobileDevice() && cartItemListRespData.length > 0) {
        setShoppingListSidesheetVisible(true)
      }
    } else {
      const itemStrJsonStr = localStorage.getItem(CART_ITEM_LIST_LOCAL_STORAGE_KEY)
      const itemList = JSON.parse(itemStrJsonStr || '[]')

      setCartItemList(itemList.map(item => {
        const [platform, sku_id] = item.split('_')
        return { platform, sku_id }
      }))

      if (!isMobileDevice() && itemList.length > 0) {
        setShoppingListSidesheetVisible(true)
      }
    }
  }, [appInfo])

  return (
    <AppContext.Provider value={{
      isMobile: isInApp || isMobileDevice(),
      windowWidth,
      windowHeight,
      navbarHeight: (isEmbedded || isFuelMap) ? 0 : (isInApp ? 48 : 52),
      isInApp,
      isWindowLoaded,
      cartItemList,
      isDarkMode,
      isEmbedded,
      ...appInfo,

      showLoginForm: () => setLoginFormSidesheetVisible(true),
      setUserInfo: (v) => setAppInfo(prev => ({ ...prev, userInfo: v, isLogin: !!v })),
      updateCart: (action, item) => {
        switch (action) {
          case 'add':
            setCartItemList(prev => {
              if (prev.length === 0 && !isMobileDevice()) {
                setShoppingListSidesheetVisible(true)
              }

              return [...prev, item]
            })
            break
          case 'delete':
            setCartItemList(prev => prev.filter(el => !(el.platform === item.platform && el.sku_id === item.sku_id)))
            break
          case 'refresh':
            setAppInfo(prev => ({ ...prev, v: prev.v + 1 }))
            break
        }
      },
      showShoppingList: () => setShoppingListSidesheetVisible(true),
      hideShoppingList: () => setShoppingListSidesheetVisible(false)
    }}>
      <LocaleProvider locale={en_US}>
        <div className="App">
          {!isInApp && !isEmbedded && !isFuelMap && <Navigation />}

          <div className="page-wrapper" style={{ height: `calc(${window.innerHeight}px - ${(isEmbedded || isFuelMap) ? 0 : (isInApp ? 48 : 52)}px)`, overflow: 'hidden' }}>
              <Routes>
                <Route path="/" element={<Navigate to="/groceries" />} />

                <Route path="/" element={<AdminLayout />}>
                  <Route path="/admin" element={<></>} />

                  {adminNavItems.map(navItem => (
                    <Route key={navItem.path} path={navItem.path} element={navItem.element} />
                  ))}
                </Route>

                <Route path="/deals" element={<Navigate to="/deals/chemist-warehouse" />} />
                <Route path="/deals/:platform" element={<DealsByPlatform />} />

                <Route path="/catalogue" element={<Navigate to="/catalogue/coles" />} />
                <Route path="/catalogue/:platform" element={<GroceriesByPlatform />} />

                <Route path="/groceries" element={<Navigate to="/groceries/coles" />} />
                <Route path="/groceries/:platform" element={<GroceriesByPlatform />} />
                <Route path="/groceries/:platform/:sku_id" element={<GroceriesDetail />} />

                <Route path="/gift-cards" element={<GiftCards />} />
                <Route path="/gift-cards/:id" element={<GiftCardDetail />} />

                <Route path="/cashback" element={<Cashback />} />
                <Route path="/cashback/:merchant" element={<CashbackDetail />} />

                <Route path="/reward-flights" element={<RewardFlightsByPlatform />} />
                <Route path="/reward-flights/:departure/:destination" element={<RewardFlightsDetail />} />

                <Route path="/reward-hotels" element={<RewardHotels />} />
                <Route path="/reward-hotels/:brand/:property_id" element={<RewardHotelDetail />} />

                <Route path="/shopping-list" element={<ShoppingList />} />
                <Route path="/my" element={<My />} />

                <Route path="/contact-us" element={<ContactUs />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />

                <Route path="/embedded" element={<Embedded />} />
                <Route path="/login-callback" element={<LoginCallback />} />

                <Route path="/articles" element={<Article />} />
                <Route path="/articles/:article_id" element={<ArticleDetail />} />

                <Route path="/test" element={<StockChecker />}  />
                <Route path="/fuel-map" element={<FuelMap />} />

                <Route path="*" element={<NotFound />} />
              </Routes>
          </div>

          {isInApp && !isEmbedded && <MobileNavigation />}
        </div>

        <SideSheet
          visible={shoppingListSidesheetVisible}
          onCancel={() => setShoppingListSidesheetVisible(false)}
          style={{ zIndex: 102 }}
          width={isMobileDevice() ? '100%' : 350}
          bodyStyle={{ padding: 0 }}
          headerStyle={isDarkMode ? { background: '##35363c' } : {}}
          mask={isMobileDevice() ? true : false}
          getPopupContainer={isMobileDevice() ? () => document.body : () => document.querySelector('.infinite-scroll-component__outerdiv') || document.body}
        >
            <ShoppingList />
        </SideSheet>

        {(isInApp || isMobileDevice()) ? (
          <SideSheet
            style={{ borderRadius: '12px 12px 0 0' }}
            visible={loginFormSidesheetVisible}
            headerStyle={{ padding: 0 }}
            closable={false}
            bodyStyle={{ padding: 0 }}
            onCancel={() => setLoginFormSidesheetVisible(false)}
            placement='bottom'
            height="270px"
          >
              <LoginForm onLogin={() => setLoginFormSidesheetVisible(false)} />
          </SideSheet>
        ) : (
          <Modal
            // style={{ borderRadius: '12px 12px 0 0' }}
            visible={loginFormSidesheetVisible}
            bodyStyle={{ padding: '0 0 12px 0' }}
            style={{ padding: 0 }}
            centered
            header={<></>}
            footer={null}
            onCancel={() => setLoginFormSidesheetVisible(false)}
          >
            <div style={{ position: 'relative' }}>
              <Button
                size="small"
                theme="borderless"
                type="tertiary"
                icon={<IconClose />}
                style={{ position: 'absolute', top: 24, right: 0 }}
                onClick={() => setLoginFormSidesheetVisible(false)}
              />
                <LoginForm onLogin={() => setLoginFormSidesheetVisible(false)} />
            </div>
          </Modal>
        )}
      </LocaleProvider>
    </AppContext.Provider>
  );
}

export default App;
