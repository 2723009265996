import { Button, Input, SideSheet, Table, Tag, Toast, ToastFactory } from "@douyinfe/semi-ui"
import { useContext, useState } from "react"
import { ICashbackPromotion, ICashbackPromotionChange } from "../../../typings"
import { request } from "../../../utils"
import { useAsyncEffect } from "ahooks"
import FlexContainer from "../../../components/FlexContainer"
import { AppContext } from "../../../App"
import { IconArrowDown, IconArrowUp, IconExternalOpen, IconFilledArrowUp, IconSearch } from "@douyinfe/semi-icons"
import dayjs from "dayjs"
import LoadingButton from "../../../components/LoadingButton"

const TrendingCashbackManagement = () => {
    const { navbarHeight, windowHeight } = useContext(AppContext)

    const [merchantPopularity, setMerchantPopularity] = useState<Record<string, number>>({})
    const [merchantList, setMerchantList] = useState<{ merchant_name: string; merchant_logo: string }[]>([])
    const [cashbackList, setCashbackList] = useState<ICashbackPromotionChange[]>([])
    const [loading, setLoading] = useState(true)
    const [keyword, setKeyword] = useState('')

    const [selectedMerchant, setSelectedMerchant] = useState<ICashbackPromotionChange>()

    useAsyncEffect(async () => {
        const merchantPopularityListRespData = await request({
            url: '/api/v1/service/execute',
            method: 'post',
            data: {
                service: 'rds.sql',
                requestParams: [
                    `SELECT \`key\`, value FROM simpo_octopus.key_value_pair WHERE type = "popularity"`
                ]
            }
        })

        const merchantListRespData = await request({
            url: '/api/v1/service/execute',
            method: 'post',
            data: {
                service: 'rds.sql',
                requestParams: [
                    `SELECT merchant_name, merchant_logo FROM simpo_octopus.au_cashback_promotion_tmp`
                ]
            }
        })

        const cashbackListRespData = await request({
            url: '/api/v1/service/execute',
            method: 'post',
            data: {
                service: 'rds.sql',
                requestParams: [`
                    SELECT
                        platform,
                        merchant_name,
                        CAST(
                        prev_max_cashback_rate AS DECIMAL ( 10, 2 )) AS prev_max_cashback_rate,
                        CAST(
                        new_max_cashback_rate AS DECIMAL ( 10, 2 )) AS new_max_cashback_rate,
                        new_cashback_info
                    FROM
                        simpo_octopus.au_cashback_promotion_change 
                    WHERE
                        CAST(
                        prev_max_cashback_rate AS DECIMAL ( 10, 2 )) < CAST( new_max_cashback_rate AS DECIMAL ( 10, 2 )) AND created_at >= CURDATE() 
                    ORDER BY
                        CAST(
                        new_max_cashback_rate AS DECIMAL ( 10, 2 )) DESC    
                `]
            }
        })

        setCashbackList(cashbackListRespData)
        setMerchantList(merchantListRespData)
        setMerchantPopularity(Object.assign({}, ...merchantPopularityListRespData.map((item: any) => ({
            [item.key]: parseInt(item.value)
        }))))

        setLoading(false)
    }, [])

    const handlePopularityDown = async (key: string) => {
        await request({
            data: {
                service: 'rds.insert',
                requestParams: [
                    'simpo_octopus',
                    'key_value_pair',
                    {
                        type: 'popularity',
                        key: key,
                        value: '0',
                        remark: 'cashback'
                    },
                    {
                        onDuplicateKeyUpdate: {
                            value: 'VALUES(value)',
                            remark: 'VALUES(remark)'
                        }
                    }
                ]
            }
        })

        Toast.success("Submit successfully")
        setMerchantPopularity(prev => ({
            ...prev,
            [key]: 0
        }))
    }

    return (
        <div style={{ width: '100%' }}>
            <div style={{ flexGrow: 1 }}>
                <div className='responsive-text font-weight-black' style={{ fontSize: 20, padding: '8px 16px', letterSpacing: -0.5 }}>
                    Trending Cashback Management
                </div>

                <Table
                    dataSource={cashbackList.filter(cashback => (!keyword || cashback.merchant_name.toLowerCase().includes(keyword.toLowerCase())) && merchantPopularity[cashback.merchant_name] !== 0)}
                    pagination={false}
                    loading={loading}
                    size="small"
                    sticky
                    style={{ height: windowHeight - navbarHeight - 46, overflow: 'auto' }}
                    columns={[
                        {
                            title: "Platform",
                            dataIndex: 'platform',
                            width: 130,
                        },
                        {
                            title: (
                                <FlexContainer alignItems="center" gap="8px">
                                    <div>Merchant</div>
                                    <div>
                                        <Input size="small" prefix={<IconSearch />} style={{ width: 150 }} onChange={setKeyword} />
                                    </div>
                                </FlexContainer>
                            ),
                            render: (record: ICashbackPromotionChange) => {
                                const logo = merchantList.find(item => item.merchant_name === record.merchant_name)?.merchant_logo

                                return (
                                    <FlexContainer gap="8px" alignItems="center">
                                        {!!logo && <img src={logo} width="100px" style={{ borderRadius: 4, padding: 6, background: 'white' }} alt={record.merchant_name} />}
                                        {/* <div>{record.merchant_name}</div> */}
                                    </FlexContainer>
                                )
                            }
                        },
                        {
                            title: "Cashback rate",
                            render: (record: ICashbackPromotionChange) => {
                                const targetItem = record.new_cashback_info.find((item: any) => item.cashback_rate.includes(`${record.new_max_cashback_rate}`))

                                const isPercentage = targetItem.cashback_rate.includes('%')
                                const cashbackRateDifference = parseFloat(((record.new_max_cashback_rate as any || 0) - (record.prev_max_cashback_rate as any || 0)).toFixed(2))

                                return (
                                    <FlexContainer alignItems="center">
                                        <div>
                                            {isPercentage ? `${record.new_max_cashback_rate}%` : `$${record.new_max_cashback_rate}`}
                                        </div>

                                        <Tag color="green" style={{ marginLeft: 6 }}>
                                            <IconFilledArrowUp style={{ fontSize: 12, marginRight: 2 }} />
                                            <span>{isPercentage ? `${cashbackRateDifference}%` : `$${cashbackRateDifference}`}</span>
                                        </Tag>
                                    </FlexContainer>
                                )
                            }
                        },
                        {
                            title: 'Created at',
                            render: (record: ICashbackPromotionChange) => {
                                return dayjs(record.created_at).format('YYYY-MM-DD')
                            }
                        },
                        {
                            title: 'Action',
                            width: 140,
                            render: (record: ICashbackPromotionChange) => {
                                return (
                                    <FlexContainer gap="6px" flexWrap="wrap">
                                        <Button
                                            icon={<IconArrowUp />}
                                            type="tertiary"
                                            theme="solid"
                                            style={{ borderRadius: 32 }}
                                        />
                                        <LoadingButton
                                            icon={<IconArrowDown />}
                                            type="tertiary"
                                            theme="solid"
                                            style={{ borderRadius: 32 }}
                                            onClick={() => handlePopularityDown(record.merchant_name)}
                                        />
                                        <Button
                                            theme="solid"
                                            style={{ borderRadius: 32 }}
                                            icon={<IconExternalOpen />}
                                            onClick={() => {
                                                window.open(`/cashback/${btoa(record.merchant_name)}`)
                                            }}
                                        />
                                        <Button
                                            style={{ borderRadius: 32 }}
                                            icon={<IconSearch />}
                                            onClick={() => {
                                                setSelectedMerchant(record)
                                            }}
                                        />
                                    </FlexContainer>
                                )
                            }
                        }
                    ]}
                />
            </div>

            <SideSheet 
                visible={!!selectedMerchant} 
                title="Cashback Detail"
                mask={false}
                onCancel={() => setSelectedMerchant(undefined)}
            >
                <div>
                    <pre>
                        {JSON.stringify(selectedMerchant?.new_cashback_info || {}, null, 2)}
                    </pre>
                </div>
            </SideSheet>
        </div>
    )
}

export default TrendingCashbackManagement