import { useCallback, useContext, useMemo, useRef, useState } from "react"
import SubHeader from "../../components/SubHeader"
import { BackTop, Badge, Breadcrumb, Button, Card, Checkbox, Divider, Dropdown, Image, Input, Radio, RadioGroup, SideSheet, Switch, Tag, Toast, Typography } from "@douyinfe/semi-ui"
import { useAsyncEffect } from "ahooks"
import { changeUrlWithQueryParams, handleDecodeFilterKey, isMobileDevice, isNumeric, renderPageOnReady, request, toBase64Unicode } from "../../utils"
import { IDeal } from "../../typings"
import FlexContainer from "../../components/FlexContainer"
import InfiniteScroll from 'react-infinite-scroll-component';
import './index.less'
import { AppContext } from "../../App"
import { IconAlignTop, IconArrowUp, IconCart, IconHistory, IconRedo, IconRefresh, IconRefresh2, IconSearch } from "@douyinfe/semi-icons"
import algoliasearch from "algoliasearch"
import { useParams } from "react-router-dom"
import ProductCard from "../GroceriesByPlatform/components/ProductCard"
import SimilarProductSidesheet, { ISimilarProductSidesheetProps } from "../GroceriesByPlatform/components/SimilarProductSidesheet"
import { uniq } from "lodash-es"

export const NAME_MAP: Record<string, string> = {
    'amazon-warehouse': 'Amazon',
    'chemist-warehouse': 'Chemist Warehouse'
}

const SORT_BY_DICT = {
    'most-recent': 'Most recent',
    'price-from-low-to-high': 'Price: Low-High',
    'discount-from-high-to-low': 'Discount: High-Low',
    'popularity': 'Popularity',
    'rating-from-high-to-low': 'Rating: High-Low'
}


const PAGE_SIZE = 50

const GroceriesByPlatform = () => {
    const { isMobile, windowWidth, windowHeight, navbarHeight, isInApp, showShoppingList} = useContext(AppContext)
    const { platform } = useParams()

    const filterKey: Record<string, string> = handleDecodeFilterKey()

    const [currTab, setCurrTab] = useState(platform && ['amazon-warehouse', 'chemist-warehouse'].includes(platform) ? platform : 'chemist-warehouse')

    const [loading, setLoading] = useState(true)
    const [productList, setProductList] = useState<IDeal[]>([])
    const [exactMatchList, setExactMatchList] = useState<IDeal[]>([])

    const [categoryList, setCategoryList] = useState<any[]>([])
    const [selectedCategory, setSelectedCategory] = useState<string>(filterKey.selectedCategory || 'all')

    const [sortBy, setSortBy] = useState(filterKey.sortBy || 'popularity')
    const [searchKeyword, setSearchKeyword] = useState(filterKey.searchKeyword || '')

    const [pageNum, setPageNum] = useState(1)
    const [totalNum, setTotalNum] = useState(0)

    const [v, setV] = useState(1)

    const prevStateRef = useRef<Record<string, any>>({})

    const [similarProductSidesheetProps, setSimilarProductSidesheetProps] = useState<ISimilarProductSidesheetProps>({
        visible: false
    })

    const hasFilter = useMemo(() => {
        if (searchKeyword || selectedCategory !== 'all' || sortBy !== 'popularity') {
            return true
        }

        return false
    }, [searchKeyword, selectedCategory, sortBy])

    const handleResetFilter = useCallback(() => {
        if (searchKeyword) {
            setSearchKeyword('')
            setV(prev => prev + 1)
        }

        if (selectedCategory !== 'all') {
            setSelectedCategory('all')
        }

        if (sortBy !== 'popularity') {
            setSortBy('popularity')
        }
    }, [searchKeyword, selectedCategory, sortBy])

    useAsyncEffect(async () => {
        let categoryListRespData: any
        let tmpList: any

        switch (currTab) {
            case "amazon-warehouse":
                categoryListRespData = await request({
                    method: 'POST',
                    url: '/api/v1/service/execute',
                    data: {
                        service: `rds.sql`,
                        requestParams: [
                            `SELECT department, count(*) as total FROM simpo_octopus.au_amazon_warehouse_deal WHERE sync_batch = (SELECT MAX(sync_batch) FROM simpo_octopus.au_amazon_warehouse_deal) GROUP BY department ORDER BY department ASC`
                        ]
                    }
                })

                tmpList = uniq(categoryListRespData.map((item: any) => `${item.department || 'Other'} (${item.total})`).flat())
                setCategoryList(tmpList)
                break
            case "chemist-warehouse":
                categoryListRespData = await request({
                    method: 'POST',
                    url: '/api/v1/service/execute',
                    data: {
                        service: `rds.sql`,
                        requestParams: [
                            `SELECT category_level_one, count(*) as total FROM simpo_octopus.au_chemist_warehouse_product_tmp GROUP BY category_level_one ORDER BY category_level_one ASC`
                        ]
                    }
                })

                tmpList = uniq(categoryListRespData.map((item: any) => `${item.category_level_one || 'Other'} (${item.total})`).flat())
                setCategoryList(tmpList)
                break
            case "kmart":
                categoryListRespData = await request({
                    method: 'POST',
                    url: '/api/v1/service/execute',
                    data: {
                        service: `rds.sql`,
                        requestParams: [
                            `SELECT DISTINCT sub_category FROM simpo_octopus.au_kmart_clearance_product WHERE sub_category IS NOT NULL AND sub_category <> '' ORDER BY sub_category ASC`
                        ]
                    }
                })

                tmpList = uniq(categoryListRespData.map((item: any) => item.sub_category.split('||')).flat())

                setCategoryList(tmpList)
                break
            case "ikea-as-is-market":
                categoryListRespData = await request({
                    method: 'POST',
                    url: '/api/v1/service/execute',
                    data: {
                        service: `rds.sql`,
                        requestParams: [
                            `SELECT ikea_store_name, count(*) as total FROM simpo_octopus.au_ikea_as_is_product GROUP BY ikea_store_name ORDER BY ikea_store_name ASC`
                        ]
                    }
                })

                tmpList = uniq(categoryListRespData.map((item: any) => `${item.ikea_store_name || 'Other'} (${item.total})`).flat())
                setCategoryList(tmpList)
        }




    }, [currTab])

    useAsyncEffect(async () => {
        const json = {
            currTab,
            sortBy,
            selectedCategory,
            searchKeyword,
        }

        const changedField = ['currTab', 'sortBy', 'selectedCategory', 'searchKeyword'].filter(key => prevStateRef.current[key] !== (json as any)?.[key])

        if (changedField.length && pageNum !== 1) {
            setPageNum(1)
            setTotalNum(0)
            prevStateRef.current = json
            return
        }

        if (pageNum === 1) {
            setLoading(true)
        }

        // set url
        changeUrlWithQueryParams(`/deals/${currTab}`, {
            filterKey: toBase64Unicode(JSON.stringify({ sortBy, selectedCategory, searchKeyword }))
        })

        let sortByCommand = ''
        switch (sortBy) {
            case 'popularity':
                sortByCommand = 'ORDER BY -discount_rate DESC'
                break
            case 'most-recent':
                if (currTab === 'ikea-as-is-market') {
                    sortByCommand = 'ORDER BY product_id DESC'
                } else {
                    sortByCommand = 'ORDER BY created_at DESC'
                }

                break
            case 'price-from-low-to-high':
                if (currTab === 'amazon-warehouse') {
                    sortByCommand = 'ORDER BY lowest_used_offer_price ASC'
                } else if (currTab === 'kmart') {
                    sortByCommand = 'ORDER BY new_price ASC'
                } else {
                    sortByCommand = 'ORDER BY price ASC'
                }

                break
            case 'discount-from-high-to-low':
                sortByCommand = 'ORDER BY -discount_rate DESC'
                break
            case 'rating-from-high-to-low':
                sortByCommand = 'ORDER BY star_rating DESC, total_votes DESC'
                break
        }

        const regex = /^\d+$/;

        let isSkuIdSearch = false
        const whereCommand = [`1 = 1`]
        if (searchKeyword) {
            if (currTab !== 'amazon-warehouse' && isNumeric(searchKeyword) && (searchKeyword as string).length > 4) {
                whereCommand.push(`sku_id = "${searchKeyword}"`)
                isSkuIdSearch = true
            } else if (currTab === 'amazon-warehouse' && regex.test(searchKeyword)) {
                whereCommand.push(`asin = "${searchKeyword}"`)
                isSkuIdSearch = true
            } else if (currTab === 'kmart' && regex.test(searchKeyword)) {
                whereCommand.push(`sku_id = "P_${searchKeyword}"`)
                isSkuIdSearch = true
            } else if (currTab === 'ikea-as-is-market' && regex.test(searchKeyword)) {
                whereCommand.push(`product_id = "${searchKeyword}"`)
                isSkuIdSearch = true
            } else {
                if (currTab === 'ikea-as-is-market') {
                    whereCommand.push(`(title LIKE "%${searchKeyword}%" OR description LIKE "%${searchKeyword}%")`)
                } else {
                    whereCommand.push(`name LIKE "%${searchKeyword}%"`)
                }
            }
        }

        let sqlCommand = ''
        let countSqlCommand = ''

        switch (currTab) {
            case 'ikea-as-is-market':
                if (!isSkuIdSearch && selectedCategory && selectedCategory !== 'all') {
                    whereCommand.push(`ikea_store_name = "${selectedCategory.split('(')[0].trim()}"`)
                }

                sqlCommand = `
                    SELECT 
                        *,
                        "ikea-as-is-market" AS platform,
                        concat(title, ' - ', description) as name,
                        product_id as sku_id,
                        price as new_price,
                        retail_price as origin_price
                    FROM 
                        simpo_octopus.au_ikea_as_is_product
                    WHERE 
                        ${whereCommand.join(' AND ')} 
                    ${sortByCommand} 
                    LIMIT ${PAGE_SIZE}
                    OFFSET ${(pageNum - 1) * PAGE_SIZE}
                `

                countSqlCommand = `
                    SELECT 
                        COUNT(*) as total
                    FROM 
                        simpo_octopus.au_ikea_as_is_product
                    WHERE 
                        ${whereCommand.join(' AND ')} 
                    ${sortByCommand}
                `

                break
            case 'amazon-warehouse':
                whereCommand.push(`sync_batch = (SELECT MAX(sync_batch) FROM simpo_octopus.au_amazon_warehouse_deal)`)

                if (!isSkuIdSearch && selectedCategory && selectedCategory !== 'all') {
                    whereCommand.push(`department = "${selectedCategory.split('(')[0].trim()}"`)
                }

                sqlCommand = `
                    SELECT 
                        *,
                        "amazon-warehouse" AS platform,
                        asin as sku_id,
                        lowest_used_offer_price as new_price,
                        price_new as origin_price
                    FROM 
                        simpo_octopus.au_amazon_warehouse_deal
                    WHERE 
                        ${whereCommand.join(' AND ')} 
                    ${sortByCommand} 
                    LIMIT ${PAGE_SIZE}
                    OFFSET ${(pageNum - 1) * PAGE_SIZE}
                `

                countSqlCommand = `
                    SELECT 
                        COUNT(*) as total
                    FROM 
                        simpo_octopus.au_amazon_warehouse_deal
                    WHERE 
                        ${whereCommand.join(' AND ')} 
                    ${sortByCommand}
                `

                break
            case 'chemist-warehouse':
                if (!isSkuIdSearch && selectedCategory && selectedCategory !== 'all') {
                    whereCommand.push(`category_level_one = "${selectedCategory.split('(')[0].trim()}"`)
                }

                sqlCommand = `
                    SELECT 
                        *,
                        "chemist-warehouse" AS platform
                    FROM 
                        simpo_octopus.au_chemist_warehouse_product_tmp
                    WHERE 
                        ${whereCommand.join(' AND ')} 
                    ${sortByCommand} 
                    LIMIT ${PAGE_SIZE}
                    OFFSET ${(pageNum - 1) * PAGE_SIZE}
                `

                countSqlCommand = `
                    SELECT 
                        COUNT(*) as total
                    FROM 
                        simpo_octopus.au_chemist_warehouse_product_tmp
                    WHERE 
                        ${whereCommand.join(' AND ')} 
                    ${sortByCommand}
                `

                break
            case 'kmart':
                if (!isSkuIdSearch && selectedCategory && selectedCategory !== 'all') {
                    whereCommand.push(`sub_category LIKE "%${selectedCategory}%"`)
                }

                sqlCommand = `
                    SELECT 
                        *,
                        "kmart" AS platform
                    FROM 
                        simpo_octopus.au_kmart_clearance_product
                    WHERE 
                        ${whereCommand.join(' AND ')} 
                    ${sortByCommand} 
                    LIMIT ${PAGE_SIZE}
                    OFFSET ${(pageNum - 1) * PAGE_SIZE}
                `

                countSqlCommand = `
                    SELECT 
                        COUNT(*) as total
                    FROM 
                        simpo_octopus.au_kmart_clearance_product
                    WHERE 
                        ${whereCommand.join(' AND ')} 
                    ${sortByCommand}
                `

                break
        }
        

        const productListRespData = await request({
            method: 'POST',
            url: '/api/v1/service/execute',
            data: {
                service: 'rds.sql',
                requestParams: [sqlCommand]
            }
        })

        if (pageNum === 1) {
            const totalNumRespData = await request({
                method: 'POST',
                url: '/api/v1/service/execute',
                data: {
                    service: 'rds.sql',
                    requestParams: [countSqlCommand]
                }
            })

            setTotalNum(totalNumRespData?.[0]?.total || 0)
        }

        if (pageNum === 1) {
            setProductList(productListRespData)
        } else {
            setProductList(prev => [...prev, ...productListRespData])
        }

        prevStateRef.current = json
        setLoading(false)
    }, [currTab, sortBy, selectedCategory, searchKeyword, pageNum])

    const showCategoryFilterInline = useMemo(() => {
        return isMobile || windowWidth < 1440
    }, [isMobile, windowWidth])

    const resetFilterWrapper = (
        <>
            {selectedCategory !== 'all' && (
                <Tag closable onClose={() => setSelectedCategory('all')} shape="circle" color="blue" style={{ marginBottom: 8, height: 24, padding: '0 8px 0 12px' }}>
                    <span className="font-weight-bold" style={{ fontSize: 14 }}>Category: {selectedCategory}</span>
                </Tag>
            )}

            {!!searchKeyword && (
                <Tag closable onClose={() => {
                    setSearchKeyword('')
                    setV(prev => prev + 1)
                }} shape="circle" color="blue" style={{ marginBottom: 8, height: 24, padding: '0 8px 0 12px' }}>
                    <span className="font-weight-bold" style={{ fontSize: 14 }}>Search keyword: {searchKeyword}</span>
                </Tag>
            )}

            {(selectedCategory !== 'all' || !!searchKeyword) && (
                <Button icon={<IconRefresh />} style={{ marginTop: 8 }} theme="borderless" type="tertiary" onClick={() => {
                    handleResetFilter()
                }}>Reset all</Button>
            )}
        </>
    )

    return (
        <div>
            <InfiniteScroll
                dataLength={productList.length} //This is important field to render the next data
                next={() => setPageNum(prev => prev + 1)}
                hasMore={totalNum > PAGE_SIZE * pageNum}
                height={`calc(${windowHeight}px - ${navbarHeight}px)`}
                loader={<></>}
                className="infinite-scroll-wrapper"
                endMessage={loading || productList.length === 0 ? <></> : (
                    <FlexContainer style={{ marginBottom: 48, width: '100%' }} flexDirection="column" alignItems="center">
                        <p style={{ textAlign: 'center', color: 'grey',fontSize: 14 }} className="font-weight-bold">
                            Yay! You have seen it all
                        </p>

                        {resetFilterWrapper}
                    </FlexContainer>
 
                )}
            >
                <div style={{ position: 'relative' }}>
                    <SubHeader
                        headerType="tab"
                        activeKey={currTab}
                        tabList={[
                            { itemKey: 'chemist-warehouse', tab: 'Chemist Warehouse' },
                            { itemKey: 'amazon-warehouse', tab: "Amazon Warehouse" },
                            { itemKey: 'kmart', tab: 'Kmart Clearance' },
                            { itemKey: 'ikea-as-is-market', tab: 'IKEA As-is Market' }
                        ]}
                        onChange={(v) => {
                            if (sortBy === 'rating-from-high-to-low') {
                                setSortBy('popularity')
                            }
                            setSelectedCategory('all')
                            setSearchKeyword('')
                            setCurrTab(v)
                        }}
                    />
                    <div className="main-container" style={{ marginLeft: showCategoryFilterInline ? 0 : -208 }}>
                        {!showCategoryFilterInline && (
                            <div style={{ position: 'relative' }}>
                                <div className="responsive-background" style={{ background: 'white', position: 'sticky', borderRadius: 4, top: 58, width: 180, padding: 8, maxHeight: `calc(${windowHeight}px - 98px - 40px)`, overflow: 'auto' }}>
                                    <RadioGroup type='pureCard' value={selectedCategory} onChange={e => setSelectedCategory(e.target.value)} style={{ width: '100%' }}>
                                        <Radio value='all' style={{ width: '100%' }}>
                                            All
                                        </Radio>
                                        {categoryList.map(category => {
                                            return (
                                                <Radio key={category} value={category} style={{ width: '100%' }}>
                                                    <div dangerouslySetInnerHTML={{ __html: category }} />                                                
                                                </Radio>
                                            )
                                        })}
                                    </RadioGroup>
                                </div>
                            </div>
                        )}

                        <div className="content-wrapper">
                            <div
                                className="filter-wrapper responsive-background"
                                style={{
                                    fontSize: 14,
                                    padding: '8px 0',
                                    display: 'flex',
                                    position: 'sticky',
                                    top: 47,
                                    background: '#f2f3f5',
                                    zIndex: 100,
                                    // WebkitBackdropFilter: 'blur(20px)',
                                    // backdropFilter: "blur(20px)",
                                    alignItems: 'center',
                                    whiteSpace: 'nowrap',
                                    overflowX: 'auto',
                                    justifyContent: 'space-between'
                                }}
                            >
                                <FlexContainer gap="8px" alignItems="center" className="responsive-text">
                                    {showCategoryFilterInline && (
                                        <>
                                            <Dropdown
                                                contentClassName="font-weight-regular"
                                                showTick
                                                position="bottomLeft"
                                                clickToHide
                                                render={
                                                    <Dropdown.Menu style={{ maxHeight: '400px', overflow: 'auto' }}>
                                                        <Dropdown.Item active={selectedCategory === 'all'} onClick={() => setSelectedCategory('all')}>All</Dropdown.Item>

                                                        {categoryList.map(category => {
                                                            return (
                                                                <Dropdown.Item key={category} active={selectedCategory === category} onClick={() => setSelectedCategory(category)}>
                                                                    <div dangerouslySetInnerHTML={{ __html: category }} />
                                                                </Dropdown.Item>
                                                            )
                                                        })}
                                                    </Dropdown.Menu>
                                                }
                                            >
                                                <span className="font-weight-regular" style={{ cursor: 'pointer' }}>
                                                    <span className="font-weight-bold">Category:</span>
                                                    <span>&nbsp; {selectedCategory === 'all' ? 'All' : <div dangerouslySetInnerHTML={{ __html: selectedCategory }} />}</span>
                                                </span>
                                            </Dropdown>

                                            <Divider layout="vertical" />
                                        </>
                                    )}

                                    <Dropdown
                                        contentClassName="font-weight-regular"
                                        showTick
                                        clickToHide
                                        render={
                                            <Dropdown.Menu>
                                                <Dropdown.Item active={sortBy === 'popularity'} onClick={() => setSortBy('popularity')}>Popularity</Dropdown.Item>
                                                <Dropdown.Item active={sortBy === 'most-recent'} onClick={() => setSortBy('most-recent')}>Most recent</Dropdown.Item>
                                                <Dropdown.Item active={sortBy === 'price-from-low-to-high'} onClick={() => setSortBy('price-from-low-to-high')}>Price: Low-High</Dropdown.Item>
                                                <Dropdown.Item active={sortBy === 'discount-from-high-to-low'} onClick={() => setSortBy('discount-from-high-to-low')}>Discount: High-Low</Dropdown.Item>

                                                {currTab === 'chemist-warehouse' && (
                                                    <Dropdown.Item active={sortBy === 'rating-from-high-to-low'} onClick={() => setSortBy('rating-from-high-to-low')}>Rating: High-Low</Dropdown.Item>
                                                )}
                                            </Dropdown.Menu>
                                        }
                                    >
                                        <span className="font-weight-regular" style={{ cursor: 'pointer' }}>
                                            <span className="font-weight-bold">Sort by:</span>
                                            <span>&nbsp; {(SORT_BY_DICT as any)[sortBy]}</span>
                                            {/* <IconChevronDown style={{ fontSize: 14, marginLeft: 6 }} /> */}
                                        </span>
                                    </Dropdown>

                                    <Divider layout="vertical" />

                                    <span className="font-weight-regular" style={{ cursor: 'pointer' }}>
                                        <Input
                                            key={v}
                                            showClear
                                            borderless
                                            placeholder="Search"
                                            prefix={<IconSearch />}
                                            style={{ marginLeft: -8, width: 150 }}
                                            onClear={() => setSearchKeyword('')}
                                            onEnterPress={(e) => {
                                                setSearchKeyword((e.target as any).value)
                                            }}
                                        />
                                    </span>
                                </FlexContainer>

                                <FlexContainer alignItems="center" gap="8px">
                                    {hasFilter && <Button icon={<IconRefresh />} size="small" theme="borderless" onClick={handleResetFilter}>Reset filter</Button>}
                                    {!isMobile && !!totalNum && <div className="font-weight-bold responsive-text">Showing 1-{Math.min(PAGE_SIZE * pageNum, totalNum)} of {totalNum}</div>}
                                </FlexContainer>
                            </div>

                            {renderPageOnReady(loading, (

                                <div style={{ display: 'flex', flexWrap: 'wrap', gap: 12 }}>

                                    {productList.map(product => {
                                        return (
                                            <ProductCard
                                                key={product.sku_id}
                                                platform={currTab}
                                                product={product}
                                                onClick={() => {
                                                    if (!['amazon-warehouse', 'ikea-as-is-market'].includes(currTab)) {
                                                        setSimilarProductSidesheetProps({ visible: true, platform: currTab, product })
                                                    }
                                                }}
                                                cardProps={{ style: { width: isMobile || windowWidth < 700 ? '100%' : 'calc(50% - 6px)' } }}
                                                showSimpleOpenButton
                                            />
                                        )
                                    })}

                                    {productList.length === 0 && (
                                        <FlexContainer flexDirection="column" alignItems="center" style={{ width: '100%' }}>
                                            <p style={{ width: '100%', textAlign: 'center', color: 'grey' }} className="font-weight-bold">
                                                Oops! No records found. <br /> Reset the filter and try again.
                                            </p>

                                            {resetFilterWrapper}
                                        </FlexContainer>

                                    )}
                                </div>
                            ))}

                        </div>
                    </div>

                    <SimilarProductSidesheet {...similarProductSidesheetProps} onCancel={() => setSimilarProductSidesheetProps({ visible: false })} />
                </div>
            </InfiniteScroll>

            {isMobile && (
                <div className="font-weight-bold" style={{ fontSize: 12, textAlign: 'center', position: 'sticky', bottom: 0, background: '#f2f3f5', padding: '8px 0' }}>
                    Showing 1-{Math.min(PAGE_SIZE * pageNum, totalNum)} of {totalNum}
                </div>
            )}

            <BackTop
                target={() => document.querySelector('.infinite-scroll-wrapper')}
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: 50,
                    width: 50,
                    borderRadius: '100%',
                    backgroundColor: '#fff',
                    color: 'black',
                    bottom: isInApp ? 56 : navbarHeight + 16,
                    right: isMobileDevice() ? 8 : 36,
                    border: '1px solid rgb(224, 224, 224)',
                    boxShadow: 'rgba(33, 33, 33, 0.12) 0px 8px 16px 0px'
                }}
            >
                <IconArrowUp />
            </BackTop>
        </div>

    )
}

export default GroceriesByPlatform