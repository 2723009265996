import { Breadcrumb, Button, Card, Checkbox, CheckboxGroup, Image, InputNumber, Modal, Tag, Toast, Typography } from "@douyinfe/semi-ui"
import { IDeal } from "../../../../typings"
import FlexContainer from "../../../../components/FlexContainer";
import { isCataloguePage, PLATFORM_NAME_MAP } from "../..";
import { CardProps } from "@douyinfe/semi-ui/lib/es/card";
import { useCallback, useContext, useMemo, useRef, useState } from "react";
import { AppContext } from "../../../../App";
import { IconClose, IconExternalOpen, IconHeartStroked, IconLikeHeart, IconOrderedList, IconShareStroked } from "@douyinfe/semi-icons";
import ProductBadge from "../ProductBadge";
import { request, toBase64Unicode } from "../../../../utils";
import './index.less'
import copy from "copy-to-clipboard";
import { CART_ITEM_LIST_LOCAL_STORAGE_KEY, LOGIN_DO_NOT_REMIND_ME_LOCAL_STORAGE_KEY } from "../../../../constants";
import LoginForm from "../../../My/components/LoginForm";
import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore"
import isSameOrAfter from "dayjs/plugin/isSameOrAfter"
import relativeTime from "dayjs/plugin/relativeTime"

dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)
dayjs.extend(relativeTime)

interface IProductCardProps {
    platform: string;
    product: IDeal;
    exactMatchProduct?: IDeal;
    showOpenButton?: boolean;
    showSimpleOpenButton?: boolean;
    showAddToListButton?: boolean;
    showShareButton?: boolean;
    showCatalogueItemLabel?: boolean;
    displayInSimpleMode?: boolean;

    onClick?: () => void;
    onDelete?: () => void;

    checked?: boolean;
    onChecked?: (checked: boolean) => void;

    extraActions?: React.Component[];
    cardProps?: CardProps;
}

const ProductCard = ({ platform, product, onClick, cardProps, showOpenButton, showAddToListButton, showSimpleOpenButton, showShareButton, showCatalogueItemLabel, extraActions, displayInSimpleMode, checked, onDelete, onChecked, exactMatchProduct }: IProductCardProps) => {
    const { isMobile, isInApp, isLogin, userInfo, cartItemList, showLoginForm, updateCart, windowWidth, COLOR_MAP, isDarkMode } = useContext(AppContext)

    const doNotRemindMeChecked = useRef<boolean>(false)

    const imgWidth = useMemo(() => {
        const numPerRow = (isMobile || windowWidth < 700) ? 1 : 2

        if (numPerRow === 1) {
            return 150
        }

        return (isMobile ? 150 : Math.min(150, (windowWidth - 48 - 12) / numPerRow * 0.3))
    }, [windowWidth, isMobile])

    const isAdded = useMemo(() => {
        return cartItemList.some(item => item.platform === platform && item.sku_id === product.sku_id)
    }, [cartItemList, platform, product])

    const handleAddToListWithoutLogin = () => {
        const cartItemListJsonStr = localStorage.getItem(CART_ITEM_LIST_LOCAL_STORAGE_KEY)
        let cartItemList: string[] = JSON.parse(cartItemListJsonStr || '[]')

        const itemKey = `${platform}_${product.sku_id}`

        if (cartItemList.includes(itemKey)) {
            cartItemList = cartItemList.filter(item => item !== itemKey)
            localStorage.setItem(CART_ITEM_LIST_LOCAL_STORAGE_KEY, JSON.stringify(cartItemList))

            updateCart('delete', {
                platform,
                sku_id: product.sku_id,
            })
            Toast.success('Item removed from your shopping list')
        } else {
            cartItemList.push(itemKey)
            localStorage.setItem(CART_ITEM_LIST_LOCAL_STORAGE_KEY, JSON.stringify(cartItemList))

            updateCart('add', {
                platform,
                sku_id: product.sku_id,
            })
            Toast.success('Item added to your shopping list')
        }
    }

    const handleShare = (e: any) => {
        e.stopPropagation()

        const url = window.location.href

        if (isInApp) {
            const schema = `share:${toBase64Unicode(url)}`
            window.webkit?.messageHandlers?.jsHandler.postMessage(schema);
        } else {
            copy(url)
            Toast.success('Sharable link copied to clipboard')
        }
    }

    const handleAddToList = useCallback(async (e: any) => {
        e.stopPropagation()

        if (!isLogin) {
            const isMute = localStorage.getItem(LOGIN_DO_NOT_REMIND_ME_LOCAL_STORAGE_KEY) === dayjs().format('YYYY-MM-DD')

            if (isMute) {
                handleAddToListWithoutLogin()
            } else {
                const instance = Modal.info({
                    footer: null,
                    closable: false,
                    width: '300px',
                    centered: true,
                    icon: false,
                    content: (
                        <FlexContainer flexDirection="column" alignItems="center" gap="16px">
                            <span style={{ fontSize: 20, textAlign: 'center', lineHeight: 1.3, fontWeight: 900 }}>
                                Log in to sync your favorites across all devices!
                            </span>

                            {/* <Checkbox onChange={(e) => doNotRemindMeChecked.current = e.target.checked || false}>
                                <Typography.Paragraph type="tertiary">
                                    <span className="font-weight-bold" style={{ fontSize: 12 }}>
                                        Do not remind me today
                                    </span>
                                </Typography.Paragraph>
                            </Checkbox> */}

                            <FlexContainer flexDirection="column" gap="8px" style={{ width: '100%' }}>
                                {/* <Button size="large" style={{ width: '100%' }} onClick={() => {
                                    if (doNotRemindMeChecked.current) {
                                        localStorage.setItem(LOGIN_DO_NOT_REMIND_ME_LOCAL_STORAGE_KEY, dayjs().format('YYYY-MM-DD'))
                                    }

                                    handleAddToListWithoutLogin()
                                    instance.destroy()
                                }}>Continue without Login</Button> */}

                                {/* <Button size="large" style={{ width: '100%' }} theme="solid" onClick={() => {
                                    showLoginForm()
                                    instance.destroy()
                                }}>Register / Log in</Button> */}

                                <LoginForm showSignInWithAppleOnly />
                                <LoginForm showSignInWithGoogleOnly />
                            </FlexContainer>
                        </FlexContainer>
                    )
                })
            }

            return
        }

        if (isAdded) {
            await request({
                method: 'POST',
                url: '/api/v1/service/execute',
                data: {
                    service: 'rds.delete',
                    requestParams: [
                        "simpo_octopus", 'au_user_cart_item', {
                            where: {
                                email: userInfo.email,
                                platform,
                                sku_id: product.sku_id,
                            }
                        }
                    ]
                }
            })

            Toast.success('Item removed from your shopping list')
            updateCart('delete', {
                platform,
                sku_id: product.sku_id,
            })
        } else {
            await request({
                method: 'POST',
                url: '/api/v1/service/execute',
                data: {
                    service: 'rds.insert',
                    requestParams: [
                        "simpo_octopus", 'au_user_cart_item', {
                            email: userInfo.email,
                            platform,
                            sku_id: product.sku_id,
                            quantity: 1
                        },
                        { ignore: true }
                    ]
                }
            })

            Toast.success('Item added to your shopping list')
            updateCart('add', {
                platform,
                sku_id: product.sku_id,
            })
        }
    }, [isLogin, userInfo, isAdded])

    const activeCatalogueItem = useMemo(() => {
        return isCataloguePage() || product.extra_data?.activeCatalogueItemDetail
    }, [product])

    const compareResult = useMemo(() => {
        if (exactMatchProduct) {
            if (exactMatchProduct.price === product.price) {
                return 0
            } else if ((exactMatchProduct as any).price < (product as any).price) {
                return 1
            } else {
                return -1
            }
        } else {
            return null
        }
    }, [product, exactMatchProduct])

    const actionWrapper = (
        <FlexContainer gap="8px">
            {showAddToListButton && (
                <Button
                    theme="borderless"
                    style={{
                        borderRadius: '50%',
                        fontSize: 20,
                        border: '1px solid #eaeaea',
                        background: 'white',
                        ...isAdded ? {
                            background: 'black',
                            border: '1px solid black',
                        } : {}
                    }}
                    icon={isAdded ? <IconLikeHeart style={{ color: 'white' }} /> : <IconHeartStroked style={{ color: 'black' }} />}
                    onClick={handleAddToList}
                />
            )}
            {showShareButton && (
                <Button
                    theme="borderless"
                    style={{
                        borderRadius: '50%',
                        fontSize: 20,
                        background: 'white',
                        border: '1px solid #eaeaea',
                    }}
                    icon={<IconShareStroked style={{ color: 'black' }} />}
                    onClick={handleShare}
                />
            )}
            {(showOpenButton || showSimpleOpenButton) && (
                <Button
                    className="open-in-app-button"
                    style={{
                        background: COLOR_MAP[compareResult === 1 ? exactMatchProduct?.platform || '' : platform],
                        color: 'white',
                        borderRadius: 16,
                        // marginBottom: 4,
                        ...showSimpleOpenButton ? {
                            width: 32,
                            height: 32,
                            padding: 0,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderRadius: '50%'
                        } : {}
                    }}
                    icon={showSimpleOpenButton ? <IconExternalOpen /> : undefined}
                    onClick={(e) => {
                        e.stopPropagation()

                        const targetPlatform = compareResult === 1 ? exactMatchProduct?.platform : platform
                        const targetSkuId = compareResult === 1 ? exactMatchProduct?.sku_id : product.sku_id || product.sku_id_list

                        switch (targetPlatform) {
                            case 'coles':
                                const productDetailUrl = `https://www.coles.com.au/product/any-${targetSkuId}`

                                if (isInApp) {
                                    window.open(`https://applink.coles.com.au/?cid=cusp:open-in-app:pdp:open-cta&url=${encodeURIComponent(productDetailUrl)}`)
                                } else {
                                    window.open(productDetailUrl)
                                }
                                break
                            case 'woolworths':
                                window.open(`https://www.woolworths.com.au/shop/productdetails/${targetSkuId}`)
                                break
                            case 'aldi':
                                window.open(`https://www.aldi.com.au${targetSkuId}`)
                                break
                            case 'costco':
                                window.open(`https://www.costco.com.au/warehouse-savings_${product.catalogue_id}`)
                                break
                            case 'chemist-warehouse':
                                window.open(`https://www.chemistwarehouse.com.au/buy/${targetSkuId}`)
                                break
                            case 'kmart':
                                window.open(`http://kmart.com.au/product/${(targetSkuId as string).replace('P_', '')}`)
                                break
                            case 'amazon-warehouse':
                                window.open(`https://www.amazon.com.au/gp/product/${targetSkuId}`)
                                break
                            case 'ikea-as-is-market': 
                                window.open(`https://www.ikea.com/au/en/campaigns/a-second-chance-for-our-furniture-pub06dd9d21/#/-/${targetSkuId}`)
                                break
                        }
                    }}
                >
                    {showOpenButton && (
                        <span className="font-weight-bold">
                            Open in {
                                ['coles', 'woolworths'].includes(product.platform)
                                    ? compareResult !== 1 && platform === 'coles' ? 'Coles' : 'Woolworths'
                                    : PLATFORM_NAME_MAP[product.platform]
                            }
                        </span>
                    )}
                </Button>
            )}
            {extraActions ? <>{extraActions}</> : <></>}
        </FlexContainer>
    )

    return (
        <Card
            key={product.sku_id}
            bodyStyle={{
                padding: 0,
                // paddingLeft: displayInSimpleMode || isMobile ? 0 : 12,
                cursor: onClick ? 'pointer' : 'default',
                position: 'relative',
                ...displayInSimpleMode ? {
                    height: '100%'
                } : {
                    minHeight: (isMobile ? 130 : 150) + (!displayInSimpleMode && !!activeCatalogueItem && showCatalogueItemLabel ? 20 : 0),
                    height: '100%'
                }
            }}
            {...(cardProps || {})}
            className={`product-card-wrapper responsive-background-secondary ${cardProps?.className || ''}`}
        >
            {!displayInSimpleMode && !!activeCatalogueItem && showCatalogueItemLabel && (
                <FlexContainer
                    className="font-weight-bold catalogue-item-banner"
                    justifyContent="space-between"
                    style={{
                        width: '100%',
                        boxSizing: 'border-box',
                        height: '20px',
                        color: 'white',
                        padding: '0 16px',
                        background: `linear-gradient(90deg, ${COLOR_MAP[platform]}, ${COLOR_MAP[platform]}aa)`,
                        lineHeight: '20px',
                        fontSize: 12,
                    }}
                >
                    <div>Catalogue</div>

                    {typeof activeCatalogueItem === 'object' && <div>Valid until {dayjs(activeCatalogueItem.end_date).format('DD/MM')}</div>}
                    {!!product.start_date && dayjs().isSameOrAfter(dayjs(product.start_date)) && <div>Valid until {dayjs(product.end_date).format('DD/MM')}</div>}
                    {!!product.start_date && dayjs().isBefore(dayjs(product.start_date)) && <div>Valid from {dayjs(product.start_date).format('DD/MM')} to {dayjs(product.end_date).format('DD/MM')}</div>}
                </FlexContainer>
            )}

            <FlexContainer alignItems={displayInSimpleMode ? 'center' : ''} onClick={() => onClick?.()} style={{ height: `calc(100% - ${(!displayInSimpleMode && !!activeCatalogueItem && showCatalogueItemLabel ? 20 : 0)}px)` }}>
                {displayInSimpleMode && isLogin && (
                    <div style={{ marginRight: -4, position: 'absolute', bottom: 6, left: 8, zIndex: 4, background: 'white' }}>
                        <Checkbox checked={checked} onChange={(e) => onChecked?.(e.target.checked as boolean)} />
                    </div>
                )}

                {!isCataloguePage() && platform === 'coles' && (
                    <FlexContainer style={{ position: 'relative', background: 'white', height: '100%' }} alignItems="center">
                        <Image preview={false} src={product.pic_url} width={imgWidth - (displayInSimpleMode ? 60 : 0)} height={imgWidth - (displayInSimpleMode ? 60 : 0)} style={{ flexShrink: 0, borderRadius: 0 }} />
                        {product.discount_rate && product.discount_rate < 0 && <ProductBadge platform={platform} discountRate={product.discount_rate} displayInSimpleMode={displayInSimpleMode} />}
                    </FlexContainer>
                )}

                {!isCataloguePage() && platform === 'woolworths' && (
                    <FlexContainer style={{ position: 'relative', background: 'white', height: '100%' }} alignItems="center">
                        <Image preview={false} src={product.pic_url} width={imgWidth - 16 - (displayInSimpleMode ? 60 : 0)} height={imgWidth - 16 - (displayInSimpleMode ? 60 : 0)} style={{ flexShrink: 0, padding: 8, borderRadius: 0 }} />
                        {product.discount_rate && product.discount_rate < 0 && <ProductBadge platform={platform} discountRate={product.discount_rate} displayInSimpleMode={displayInSimpleMode} />}
                    </FlexContainer>
                )}

                {!isCataloguePage() && !['coles', 'woolworths'].includes(platform) && (
                    <FlexContainer style={{ position: 'relative', background: 'white', height: '100%' }} alignItems="center">
                        <Image preview={false} src={product.pic_url} width={imgWidth - 16 - (displayInSimpleMode ? 60 : 0)} height={imgWidth - 16 - (displayInSimpleMode ? 60 : 0)} style={{ flexShrink: 0, padding: 8, borderRadius: 0 }} />
                        {product.discount_rate && product.discount_rate < 0 && <ProductBadge platform={platform} discountRate={product.discount_rate} displayInSimpleMode={displayInSimpleMode} />}
                    </FlexContainer>
                )}

                {isCataloguePage() && (
                    <div style={{ position: 'relative', background: 'white', height: '100%' }}>
                        <Image preview={false} src={product.pic_url} width={(isMobile ? 106 : 126) - (displayInSimpleMode ? 60 : 0)} height={(isMobile ? 106 : 126) - (displayInSimpleMode ? 60 : 0)} style={{ flexShrink: 0, padding: 12, borderRadius: 0 }} />
                        {product.discount_rate && product.discount_rate < 0 && <ProductBadge platform={platform} discountRate={product.discount_rate} displayInSimpleMode={displayInSimpleMode} />}
                    </div>
                )}


                <FlexContainer flexDirection="column" justifyContent="space-between" style={displayInSimpleMode ? { padding: `4px 8px`, width: '100%', height: 78 } : { padding: '8px 16px 8px 8px', width: '100%' }}>
                    <div>
                        {/* <Breadcrumb style={{ marginBottom: -6, flexWrap: 'nowrap', whiteSpace: 'nowrap' }}>
                            <Breadcrumb.Item>
                                <Typography.Paragraph type="tertiary" style={{ fontSize: 10 }}>
                                    <span className="font-weight-regular" style={{ color: 'grey' }}>{product.category_level_one}</span>
                                </Typography.Paragraph>
                            </Breadcrumb.Item>
                        </Breadcrumb> */}

                        {/* {!!activeCatalogueItem && (
                            <div>
                                <Tag>
                                    <span className="font-weight-bold">Catalogue Item</span>
                                </Tag>
                            </div>    
                        )} */}

                        <div
                            className="font-weight-bold"
                            style={displayInSimpleMode
                                ? { width: isMobile ? `calc(100vw - 70px - 28px - 28px)` : 'calc(100% - 28px)', display: '-webkit-box', WebkitLineClamp: 2, WebkitBoxOrient: 'vertical', textOverflow: 'ellipsis', overflow: 'hidden' }
                                : {}
                            }
                            onDoubleClick={() => copy(product.sku_id as any)}
                            dangerouslySetInnerHTML={{ __html: product.name }}
                        />
                    </div>

                    {!displayInSimpleMode && (
                        <FlexContainer justifyContent="space-between" alignItems="flex-end">
                            <div>
                                <FlexContainer alignItems="center" className="font-weight-bold product-price-wrapper">
                                    <span
                                        style={{
                                            fontWeight: 700,
                                            letterSpacing: -1,
                                            fontSize: 28,
                                            color: COLOR_MAP[platform],
                                            textDecoration: compareResult === 1 ? 'line-through' : ''
                                        }
                                        }
                                    >
                                        ${product.new_price || product.price}
                                    </span>
                                    
                                    {product.origin_price && product.origin_price > product.new_price && Math.abs(((product.origin_price - product.new_price) / product.origin_price) * 100).toFixed(0) !== '0' && compareResult !== 1 && (
                                        <div style={{ marginLeft: 8, marginRight: -2, fontSize: 12, letterSpacing: -0.5, fontWeight: 600, padding: '2px 8px', color: 'black', borderRadius: 16, background: 'rgb(254, 220, 2)', whiteSpace: 'nowrap' }}>
                                            Save ${((product?.origin_price || 0) - (product.new_price || 0)).toFixed(2)}
                                        </div>
                                    )}

                                    {!!product.lowest_price && product.lowest_price >= product.new_price && (
                                        <div style={{ marginLeft: 8, marginRight: -2, fontSize: 12, letterSpacing: -0.5, fontWeight: 600, padding: '2px 8px', color: 'white', borderRadius: 16, background: COLOR_MAP[platform || ''], whiteSpace: 'nowrap' }}>
                                            Historical low
                                        </div>
                                    )}

                                    {compareResult !== null && (
                                        <>
                                            {compareResult === 1 && (
                                                <>
                                                    <span style={{ marginLeft: 8, fontWeight: 700, letterSpacing: -1, fontSize: 28, color: COLOR_MAP[exactMatchProduct?.platform || ''] }}>${exactMatchProduct?.price}</span>
                                                    <FlexContainer gap="2px" alignItems="center" style={{ marginLeft: 8, fontSize: 12, letterSpacing: -0.5, fontWeight: 600, padding: '2px 8px 2px 6px', color: 'white', borderRadius: 16, background: COLOR_MAP[exactMatchProduct?.platform || ''], whiteSpace: 'nowrap' }}>
                                                        <img width="16" height="16" src="https://img.icons8.com/glyph-neue/64/FFFFFF/double-tick.png" alt="double-tick" />
                                                        Cheapest
                                                    </FlexContainer>
                                                </>
                                            )}
                                            {compareResult === 0 && (
                                                <FlexContainer gap="2px" alignItems="center" style={{ marginLeft: 8, fontSize: 12, letterSpacing: -0.5, fontWeight: 600, padding: '2px 8px 2px 5px', color: 'white', borderRadius: 16, background: COLOR_MAP[product?.platform || ''], whiteSpace: 'nowrap' }}>
                                                    <img width="16" height="16" src="https://img.icons8.com/ios-filled/50/FFFFFF/us-dollar-circled--v2.png" />
                                                    Matched
                                                </FlexContainer>
                                            )}
                                            {compareResult === -1 && (
                                                <FlexContainer gap="2px" alignItems="center" style={{ marginLeft: 8, fontSize: 12, letterSpacing: -0.5, fontWeight: 600, padding: '2px 8px 2px 6px', color: 'white', borderRadius: 16, background: COLOR_MAP[product?.platform || ''], whiteSpace: 'nowrap' }}>
                                                    <img width="16" height="16" src="https://img.icons8.com/glyph-neue/64/FFFFFF/double-tick.png" alt="double-tick" />
                                                    Cheapest
                                                </FlexContainer>
                                            )}
                                        </>
                                    )}
                                </FlexContainer>
                                <Typography.Paragraph type="tertiary" style={{ fontSize: 10, marginTop: 2 }}>
                                    <span className="font-weight-regular" dangerouslySetInnerHTML={{ __html: product.unit_price || '' }}></span>
                                </Typography.Paragraph>
                            </div>
                        </FlexContainer>
                    )}

                    {displayInSimpleMode && (
                        <FlexContainer justifyContent="space-between" alignItems="flex-end" style={{ marginRight: 4 }}>
                            {actionWrapper}
                            {/* {compareResult !== null && (
                                <>
                                    {compareResult === 1 && (
                                        <>
                                            <span style={{ marginLeft: 8, fontWeight: 700, letterSpacing: -1, fontSize: 28, color: COLOR_MAP[exactMatchProduct?.platform || ''] }}>${exactMatchProduct?.price}</span>
                                            <FlexContainer gap="2px" alignItems="center" style={{ marginLeft: 8, fontSize: 12, letterSpacing: -0.5, fontWeight: 600, padding: '2px 8px 2px 6px', color: 'white', borderRadius: 16, background: COLOR_MAP[exactMatchProduct?.platform || ''], whiteSpace: 'nowrap' }}>
                                                <img width="16" height="16" src="https://img.icons8.com/glyph-neue/64/FFFFFF/double-tick.png" alt="double-tick" />
                                                Cheapest
                                            </FlexContainer>
                                        </>
                                    )}
                                    {compareResult === 0 && (
                                        <FlexContainer gap="2px" alignItems="center" style={{ marginLeft: 8, fontSize: 12, letterSpacing: -0.5, fontWeight: 600, padding: '2px 8px 2px 5px', color: 'white', borderRadius: 16, background: COLOR_MAP[product?.platform || ''], whiteSpace: 'nowrap' }}>
                                            <img width="16" height="16" src="https://img.icons8.com/ios-filled/50/FFFFFF/us-dollar-circled--v2.png" />
                                            Matched
                                        </FlexContainer>
                                    )}
                                    {compareResult === -1 && (
                                        <FlexContainer gap="2px" alignItems="center" style={{ marginLeft: 8, fontSize: 12, letterSpacing: -0.5, fontWeight: 600, padding: '2px 8px 2px 6px', color: 'white', borderRadius: 16, background: COLOR_MAP[product?.platform || ''], whiteSpace: 'nowrap' }}>
                                            <img width="16" height="16" src="https://img.icons8.com/glyph-neue/64/FFFFFF/double-tick.png" alt="double-tick" />
                                            Cheapest
                                        </FlexContainer>
                                    )}
                                </>
                            )} */}

                            <FlexContainer flexDirection="column" alignItems="flex-end">
                                <div style={{ fontWeight: 600, letterSpacing: -1, fontSize: 18 }}>
                                    {compareResult !== 1 && (
                                        <FlexContainer alignItems="center" gap="4px" style={{ color: COLOR_MAP[platform] }}>
                                            {/* <img width="16" height="16" src={`https://img.icons8.com/glyph-neue/64/${COLOR_MAP[platform].replace('#', '')}/double-tick.png`} alt="double-tick" /> */}
                                            ${product.new_price || product.price}
                                        </FlexContainer>
                                    )}
                                    {compareResult === 1 && (
                                        <FlexContainer alignItems="center" gap="4px" style={{ color: COLOR_MAP[exactMatchProduct?.platform || ''] }}>
                                            {/* <img width="16" height="16" src={`https://img.icons8.com/glyph-neue/64/${COLOR_MAP[exactMatchProduct?.platform || ''].replace('#', '')}/double-tick.png`} alt="double-tick" /> */}
                                            ${exactMatchProduct?.price}
                                        </FlexContainer>
                                    )}
                                </div>
                                {![1, -1].includes(compareResult || 0) && !!product.origin_price && product.origin_price > product.new_price && (
                                    <div className="font-weight-bold responsive-text" style={{ textDecoration: 'line-through', fontSize: 10, marginTop: -4, marginBottom: -8, color: 'rgb(28, 31, 35)', opacity: 0.6 }}>Was ${product.origin_price}</div>
                                )}
                                {compareResult === 1 && <div className="font-weight-bold" style={{ textDecoration: 'line-through', fontSize: 10, marginTop: -4, marginBottom: -8, color: COLOR_MAP[platform], opacity: 0.6 }}>Why Pay ${product.price}</div>}
                                {compareResult === -1 && <div className="font-weight-bold" style={{ textDecoration: 'line-through', fontSize: 10, marginTop: -4, marginBottom: -8, color: COLOR_MAP[exactMatchProduct?.platform || ''], opacity: 0.6 }}>Why Pay ${exactMatchProduct?.price}?</div>}
                            </FlexContainer>
                        </FlexContainer>
                    )}

                    {!displayInSimpleMode && (
                        <div style={{ position: 'absolute', bottom: 8, right: 16 }}>
                            {actionWrapper}
                        </div>
                    )}
                </FlexContainer>


            </FlexContainer>


            {displayInSimpleMode && (
                <Button size="small" type="tertiary" icon={<IconClose style={{ fontSize: 12 }} />} style={{ position: 'absolute', top: 8, right: 8, borderRadius: '50%', width: 20, height: 20 }} onClick={onDelete}></Button>
            )}
        </Card>
    )
}

export default ProductCard