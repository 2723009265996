import { createContext, useContext, useEffect, useMemo, useRef, useState } from "react"
import { IFuelPrice, IFuelStation } from "../../typings"
import { useAsyncEffect } from "ahooks"
import { generateColorGradient, request } from "../../utils"
import LazyMap from "../../components/LazyMap"
import { MapRef, Marker } from "react-map-gl"
import { Button, Checkbox, Descriptions, Divider, Input, List, Popconfirm, Popover, Select, SideSheet, Slider, Table, Tag, Tooltip } from "@douyinfe/semi-ui"
import { IconChevronDown, IconClose, IconEyeClosed, IconMapPin, IconSearch, IconSend, IconSetting, IconStar, IconTriangleDown } from "@douyinfe/semi-icons"
import { AppContext } from "../../App"
import { LngLatBounds, Map } from "mapbox-gl"
import { MAPBOX_TOKEN } from "../RewardFlightsByPlatform/components/AirportMap"
import FlexContainer from "../../components/FlexContainer"
import { IconNavigation } from "@douyinfe/semi-icons-lab"
import { groupBy, isEmpty, mean, uniq } from "lodash-es"
import useKeyValuePairs from "../../hooks/useKeyValuePairs"
import { OptionProps } from "@douyinfe/semi-ui/lib/es/select"
import './index.less'
import AdvancedSettingModal from "./components/AdvancedSettingModal"
import dayjs from "dayjs"
import classNames from "classnames"
import ViewMoreContainer from "../../components/ViewMoreContainer"
import { FUEL_MAP_ADVANCED_SETTING_LOCAL_STORAGE_KEY } from "../../constants"
import { storageWrapper } from "../../utils/indexDB"
import Draggable from "react-draggable"
import FuelStationDetailPane from "./components/FuelStationDetailPane"
import SuburbAutoComplete from "../../components/SuburbAutoComplete"
import CurrentLocationIcon from "./components/CurrentLocationIcon"

interface IViewpointStats {
    num: number,
    avg: number,
    med: number,
    min: number,
    max: number
}

const VIEWPOINT_STATS_DEFAULT_VALUE = {
    num: 0,
    avg: 0,
    med: 0,
    min: 0,
    max: 0
}

export const DEFAULT_FUEL_STATION_LOGO = 'https://static.vecteezy.com/system/resources/previews/034/812/074/non_2x/gas-station-icon-isolated-fuel-petrol-pump-symbol-sign-free-vector.jpg'

export const FuelMapContext = createContext<{
    fuelPriceDict: Record<string, IFuelPrice[]>;
    viewpointStats: IViewpointStats;
    selectedFuelType: string;
    fuelStationBrandOptionList: OptionProps[];
    fuelStationList: IFuelStation[];
    mapRef?: MapRef;
    setTmpStationIdRef?: (id: string) => void;
    showOpenOnly: boolean;
}>({
    fuelPriceDict: {},
    viewpointStats: VIEWPOINT_STATS_DEFAULT_VALUE,
    selectedFuelType: 'E10',
    fuelStationBrandOptionList: [],
    fuelStationList: [],
    setTmpStationIdRef: undefined,
    showOpenOnly: true
})


function calcMedian(values: number[]): number {
    if (values.length === 0) {
        return 0
    }

    values = [...values].sort((a, b) => a - b);
    const half = Math.floor(values.length / 2);

    return (values.length % 2
        ? values[half]
        : (values[half - 1] + values[half]) / 2
    );

}

export const checkIsOpenNow: (station: any) => any = (station: IFuelStation) => {
    if (!station.opening_hours) {
        return { isOpenNow: true }
    }


    const defaultWeekday = dayjs().day()

    const weekday = defaultWeekday === 0 ? 7 : defaultWeekday;
    const todayOpeningHour = station.opening_hours.find(day => day.DayOrder === weekday)
    const tmrOpeningHour = station.opening_hours.find(day => day.DayOrder === weekday + 1)

    let isOpenNow

    if (todayOpeningHour?.IsOpen24Hours) {
        isOpenNow = true
    } else {
        const currTime = dayjs()
        const startTime = dayjs(`${dayjs().format('YYYY-MM-DD')} ${todayOpeningHour?.StartTime}`, 'YYYY-MM-DD hh:mm A')

        let endTime
        if (startTime.isAfter(currTime)) {
            endTime = dayjs(`${dayjs().subtract(1, 'd').format('YYYY-MM-DD')} ${todayOpeningHour?.EndTime?.replace('12:00 AM', '11:59 PM')}`, 'YYYY-MM-DD hh:mm A')
        } else {
            endTime = dayjs(`${dayjs().format('YYYY-MM-DD')} ${todayOpeningHour?.EndTime?.replace('12:00 AM', '11:59 PM')}`, 'YYYY-MM-DD hh:mm A')
        }

        isOpenNow = endTime.isAfter(currTime)
    }

    return { isOpenNow, todayOpeningHour, tmrOpeningHour }
}

const FuelMap = () => {
    const [fuelStationList, setFuelStationList] = useState<IFuelStation[]>([])
    const [loading, setLoading] = useState(true)
    const [visiblePopoverStationId, setVisiblePopoverStationId] = useState('')
    const [viewpointBound, setViewpointBound] = useState<LngLatBounds>()
    const [fuelPriceDict, setFuelPriceDict] = useState<Record<string, IFuelPrice[]>>({})
    const [fuelTypeOptionList, setFuelTypeOptionList] = useState<OptionProps[]>([])
    const [fuelStationBrandOptionList, setFuelStationBrandOptionList] = useState<OptionProps[]>([])

    const [selectedFuelType, setSelectedFuelType] = useState('P95-PUP-P98-98R')
    const [selectedFuelStationBrandList, setSelectedFuelStationBrandList] = useState<string[]>([])
    const [showOpenOnly, setShowOpenOnly] = useState(true)

    const [stationInputKeyword, setStationInputKeyword] = useState('')
    const [viewpointStats, setViewpointStats] = useState<IViewpointStats>(VIEWPOINT_STATS_DEFAULT_VALUE)
    const [advancedSetting, setAdvancedSetting] = useState<{ disabledBrandList: string[], personalDiscounts: Record<string, any> }>({
        disabledBrandList: [],
        personalDiscounts: {}
    })
    const [sidesheetHeight, setSidesheetHeight] = useState(48)
    const [mapIsMoving, setMapIsMapMoving] = useState(false)
    const [mapPrevCenterZoom, setMapPrevCenterZoom] = useState<{ center: number[], zoom: number }>()


    const [] = useState({})

    const { isDarkMode, navbarHeight, isMobile, windowHeight } = useContext(AppContext)

    const mapRef = useRef<MapRef>()
    const tmpStationIdRef = useRef('')
    const fuelPriceGradientColor = useRef<any>()
    const heightRef = useRef(48)
    const mapIsMovingTimeoutRef = useRef<any>()
    const mapIsLockedRef = useRef(false)

    // useEffect(() => {
    //     console.log(fuelStationList.find(item => item.station_id === visiblePopoverStationId))
    // }, [fuelStationList, visiblePopoverStationId])

    const fuelStationInViewpoint = useMemo(() => {
        if (!viewpointBound) {
            return []
        }

        // Expand bounds by ±1 degree
        const expandedBounds = {
            minLat: viewpointBound._sw.lat - 0.00,
            maxLat: viewpointBound._ne.lat + 0.00,
            minLng: viewpointBound._sw.lng - 0.00,
            maxLng: viewpointBound._ne.lng + 0.00
        };


        return fuelStationList.filter(({ latitude, longitude, station_id }) => {
            const withinViewpoint =
                parseFloat(latitude) >= expandedBounds.minLat && parseFloat(latitude) <= expandedBounds.maxLat &&
                parseFloat(longitude) >= expandedBounds.minLng && parseFloat(longitude) <= expandedBounds.maxLng

            return withinViewpoint
        });
    }, [viewpointBound, fuelStationList])

    const nonEvFuelStationInViewpoint = useMemo(() => {
        console.log(123)
        return fuelStationInViewpoint.filter((station) => {
            const { station_id, brand, name } = station
            const notEv = !fuelPriceDict[station_id] || !fuelPriceDict[station_id].length || !fuelPriceDict[station_id].every(price => price.fuel_type === 'EV')

            const isValidFuelType = !selectedFuelType || fuelPriceDict[station_id]?.some(item => selectedFuelType.split('-').includes(item.fuel_type))
            const isValidFuelStationBrand = !selectedFuelStationBrandList || selectedFuelStationBrandList.length === 0 || selectedFuelStationBrandList.includes(brand)

            const isMatchKeyword = !stationInputKeyword || name.toLowerCase().includes(stationInputKeyword.toLowerCase())
            const isOpenNow = !showOpenOnly || checkIsOpenNow(station)?.isOpenNow

            const isHide = advancedSetting.disabledBrandList?.includes(station.brand)

            return notEv && isValidFuelType && isValidFuelStationBrand && isMatchKeyword && isOpenNow && !isHide
        })
    }, [fuelStationInViewpoint, fuelPriceDict, selectedFuelType, selectedFuelStationBrandList, stationInputKeyword, advancedSetting])

    const handleAdvancedSettingChange = () => {
        const cacheData: any = storageWrapper.get(FUEL_MAP_ADVANCED_SETTING_LOCAL_STORAGE_KEY)
        setAdvancedSetting(cacheData || {
            disabledBrandList: [],
            personalDiscounts: {}
        })

        if (cacheData && cacheData?.personalDiscounts) {
            setFuelPriceDict(prev => {
                return Object.assign({}, ...Object.entries(prev).map(([station_id, priceList]) => {
                    const stationInfo = fuelStationList.find(station => station.station_id === station_id)
                    const discountInfo = cacheData.personalDiscounts?.[stationInfo?.brand as string]

                    if (discountInfo && discountInfo.discountCentByLitre && priceList[0]?.discountInfo?.discountCentByLitre !== discountInfo.discountCentByLitre) {

                        return {
                            [station_id]: priceList.map(priceInfo => {
                                const originPrice = priceInfo.origin_price || priceInfo.price

                                return {
                                    ...priceInfo,
                                    origin_price: originPrice,
                                    price: parseFloat((originPrice + (discountInfo.discountCentByLitre || 0)).toFixed(1)),
                                    discountInfo
                                }
                            })
                        }
                    } else {
                        return {
                            [station_id]: priceList
                        }
                    }
                }))
            })
        }
    }

    const handleSuburbChange = async (postcode: string) => {
        if (!postcode) {
            return
        }

        const respData = await request({
            method: 'post',
            url: '/api/v1/service/execute',
            data: {
                service: 'au.common.getGeoPointByPostcode',
                requestParams: [
                    postcode
                ]
            }
        })

        if (respData) {
            mapRef.current?.getMap()?.jumpTo({
                center: [respData?.longitude, respData?.latitude],
                zoom: 12,
            })
        }
    }

    useEffect(() => {
        const fuelPriceList = nonEvFuelStationInViewpoint.map(station => {
            const tmpList = fuelPriceDict[station.station_id]?.filter(price => selectedFuelType.split('-').includes(price.fuel_type))?.map(item => item.price)

            return tmpList || []
        }).flat()

        setViewpointStats({
            num: nonEvFuelStationInViewpoint.length,
            avg: parseFloat(mean(fuelPriceList).toFixed(1)),
            med: parseFloat(calcMedian(fuelPriceList).toFixed(1)),
            min: Math.min(...fuelPriceList, 300),
            max: Math.max(...fuelPriceList, 0)
        })

        fuelPriceGradientColor.current = generateColorGradient(Math.min(...fuelPriceList), Math.max(...fuelPriceList) + 1, '#198754', '#fc8800')
    }, [nonEvFuelStationInViewpoint, selectedFuelType, fuelPriceDict])

    useAsyncEffect(async () => {
        const naStationId = fuelStationInViewpoint.filter(station => !fuelPriceDict[station.station_id]).map(station => station.station_id)

        if (naStationId.length) {
            const respData = await request({
                method: 'post',
                url: '/api/v1/service/execute',
                data: {
                    service: 'rds.sql',
                    requestParams: [
                        `SELECT * FROM simpo_octopus.au_fuel_price WHERE station_id IN (${naStationId.map(id => `"${id}"`).join(", ")}) AND price != 999.90`
                    ]
                }
            })

            setFuelPriceDict(prev => Object.assign({}, ...naStationId.map(id => ({ [id]: [] })), prev, groupBy(respData, 'station_id')))
        }
    }, [fuelStationInViewpoint, fuelPriceDict])

    // useEffect(() => {
    //     handleAdvancedSettingChange()
    // }, [fuelPriceDict])

    useAsyncEffect(async () => {
        const respData = await request({
            method: 'post',
            url: '/api/v1/service/execute',
            data: {
                service: 'rds.sql',
                requestParams: [
                    `SELECT station_id, brand, name, address, latitude, longitude, state, google_place_id, opening_hours FROM simpo_octopus.au_fuel_station`
                ]
            }
        })

        const respData2: any[] = await request({
            method: 'post',
            url: '/api/v1/service/execute',
            data: {
                service: 'rds.sql',
                requestParams: [
                    `SELECT * FROM simpo_octopus.config WHERE \`key\` IN ("fuel_station_brand_list", "fuel_type_list")`
                ]
            }
        })

        setFuelTypeOptionList(respData2?.find(item => item.key === 'fuel_type_list')?.value?.map((brand: any) => ({
            label: brand.code,
            value: brand.alternativeNames?.join('-')
        })))

        setFuelStationBrandOptionList(respData2?.find(item => item.key === 'fuel_station_brand_list')?.value?.map((brand: any) => ({
            label: brand.name,
            value: brand.alternativeNames,
            extra: brand.logoUrl
        })))

        setFuelStationList(respData)
        setLoading(false)
    }, [])

    const priceTable = (
        <Table
            style={{ height: '100%', overflow: 'auto' }}
            className="fuel-station-table-wrapper"
            loading={loading}
            sticky
            dataSource={nonEvFuelStationInViewpoint}
            size="small"
            pagination={false}
            columns={[
                {
                    title: (
                        <FlexContainer>
                            <Input placeholder={`${nonEvFuelStationInViewpoint.length} stations`} prefix={<IconSearch />} size="small" borderless onChange={setStationInputKeyword} />
                        </FlexContainer>
                    ),
                    render: (station: IFuelStation) => {
                        const logoUrl = fuelStationBrandOptionList.find(item => item.value && (item.value as any).includes(station.brand))?.extra || DEFAULT_FUEL_STATION_LOGO
                        const { isOpenNow, todayOpeningHour } = checkIsOpenNow(station)

                        return (
                            <FlexContainer
                                alignItems="center"
                                justifyContent="space-between"
                                onClick={() => {

                                    if (isMobile) {
                                        mapIsLockedRef.current = true
                                        const mapInstance = mapRef.current?.getMap()

                                        mapInstance?.flyTo({
                                            center: [station?.longitude, station?.latitude].map(item => parseFloat(item)) as any,
                                            zoom: 14,
                                            offset: [0, -160]
                                        })
                                        tmpStationIdRef.current = station.station_id
                                    } else {
                                        setVisiblePopoverStationId(station.station_id)
                                    }

                                }}
                            >
                                <FlexContainer gap="8px">
                                    <div style={{ flexShrink: 0, borderRadius: '50%', width: 40, height: 40, overflow: 'hidden', background: 'white' }}>
                                        <img src={logoUrl} width="40px" height="40px" style={{ objectFit: 'contain' }} />
                                    </div>
                                    <div>
                                        <div
                                            className="font-weight-bold"
                                            style={{ width: 155, whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', }}
                                        >
                                            {station.name}
                                        </div>
                                        {/* <FlexContainer gap="4px">
                                                        {fuelPriceList.filter(price => !selectedFuelType || price.fuel_type === selectedFuelType).map(price => {
                                                            return (
                                                                <Tag>{price.fuel_type}: {price.price}</Tag>
                                                            )
                                                        })}
                                                    </FlexContainer> */}

                                        <div style={{ color: isOpenNow ? 'rgb(59,179,70)' : 'rgb(249,57,32)' }}>
                                            {!!todayOpeningHour?.IsOpen24Hours
                                                ? 'Opens 24 hours'
                                                : isOpenNow
                                                    ? `Open until ${todayOpeningHour?.EndTime}`
                                                    : "Closed"}
                                        </div>
                                    </div>


                                </FlexContainer>


                            </FlexContainer>
                        )
                    }
                },
                {
                    title: 'Price',
                    width: 96,
                    defaultSortOrder: 'ascend',
                    dataIndex: 'station_id',
                    sorter: (a: any, b: any) => {
                        const fuelPriceListA = fuelPriceDict[a.station_id]
                        const priceInfoA = fuelPriceListA.find(price => selectedFuelType.split('-').includes(price.fuel_type)) as IFuelPrice

                        const fuelPriceListB = fuelPriceDict[b.station_id]
                        const priceInfoB = fuelPriceListB.find(price => selectedFuelType.split('-').includes(price.fuel_type)) as IFuelPrice

                        return (priceInfoA?.price || 0) - (priceInfoB?.price || 0)
                    },
                    render: (station_id: string, record: IFuelStation) => {
                        const fuelPriceList = fuelPriceDict[record.station_id]
                        const priceInfo = fuelPriceList.find(price => selectedFuelType.split('-').includes(price.fuel_type)) as IFuelPrice

                        if (!priceInfo) {
                            return <></>
                        }

                        return !!selectedFuelType ? (
                            <div className="font-weight-black" style={{ fontSize: 24, letterSpacing: -1, color: fuelPriceGradientColor.current?.(priceInfo.price), whiteSpace: 'nowrap' }}>
                                {priceInfo.price}
                                {!!priceInfo.discountInfo && '*'}
                                {!!priceInfo.discountInfo && (
                                    <div style={{ textDecoration: 'line-through', fontWeight: 600, letterSpacing: 0, fontSize: 12, color: 'grey', lineHeight: '14px', marginBottom: -4 }}>
                                        {priceInfo.origin_price}
                                    </div>
                                )}
                            </div>
                        ) : <></>

                    }
                }
            ]}
        />
    )

    useEffect(() => {
        if (isMobile && !visiblePopoverStationId) {
            mapIsLockedRef.current = false
        }
    }, [visiblePopoverStationId])

    return (
        <FuelMapContext.Provider value={{
            fuelPriceDict,
            viewpointStats,
            selectedFuelType,
            fuelStationBrandOptionList,
            fuelStationList,
            mapRef: mapRef.current,
            setTmpStationIdRef: (id: string) => { tmpStationIdRef.current = id },
            showOpenOnly,
        }}>
            <div className="fuel-map-wrapper">
                <FlexContainer style={{ width: '100vw', height: `calc(100vh - ${navbarHeight}px)` }}>

                    {!isMobile && (
                        <div style={{ width: '390px' }}>
                            {priceTable}
                        </div>
                    )}

                    <div style={{ width: '100%', height: '100%', position: 'relative' }}>
                        <FlexContainer gap="8px" style={{ padding: '8px 12px', overflow: 'auto' }} alignItems="center">
                            <Checkbox defaultChecked={showOpenOnly} onChange={e => setShowOpenOnly(e.target.checked as boolean)} style={{ whiteSpace: 'nowrap' }}>
                                Open now?
                            </Checkbox>

                            <Select
                                prefix="Fuel type"
                                value={selectedFuelType}
                                optionList={fuelTypeOptionList}
                                onChange={(v: any) => setSelectedFuelType(v)}
                                style={{ width: 160 }}
                            />

                            <Select
                                prefix="Brand"
                                showClear
                                multiple
                                filter
                                maxTagCount={1}
                                optionList={fuelStationBrandOptionList}
                                onChange={(v) => setSelectedFuelStationBrandList((v as any[]).flat() as string[])}
                                style={{ width: 200 }}
                            />

                            {/* <SuburbAutoComplete
                                onSelect={v => handleSuburbChange(v as string)}
                            /> */}

                            <AdvancedSettingModal fuelStationBrandList={fuelStationBrandOptionList} onSubmit={handleAdvancedSettingChange} />
                        </FlexContainer>

                        <LazyMap
                            onRef={mapRef}
                            style={{ width: '100%', height: '100%' }}
                            mapStyle={isDarkMode ? "mapbox://styles/mapbox/dark-v11" : "mapbox://styles/mapbox/light-v11"}
                            mapboxAccessToken={MAPBOX_TOKEN}
                            onLoad={() => {
                                setTimeout(() => {
                                    window.dispatchEvent(new Event('resize'))
                                }, 200)

                                const newBounds = mapRef.current?.getBounds()
                                if (newBounds) setViewpointBound(newBounds)
                            }}
                            onMoveEnd={() => {
                                if (tmpStationIdRef.current) {  
                                    setVisiblePopoverStationId(tmpStationIdRef.current)
                                    tmpStationIdRef.current = ''
                                }

                                const newBounds = mapRef.current?.getBounds()
                                if (newBounds && !mapIsLockedRef.current) setViewpointBound(newBounds)

                                mapIsMovingTimeoutRef.current = setTimeout(() => {
                                    setMapIsMapMoving(false)
                                    mapIsMovingTimeoutRef.current = undefined
                                }, 500)
                            }}
                            onMoveStart={() => {
                                setMapPrevCenterZoom({
                                    center: mapRef.current?.getCenter(),
                                    zoom: mapRef.current?.getZoom()
                                } as any)

                                if (mapIsMovingTimeoutRef.current) {
                                    clearTimeout(mapIsMovingTimeoutRef.current)
                                    mapIsMovingTimeoutRef.current = undefined
                                }

                                setMapIsMapMoving(true)
                                setVisiblePopoverStationId('')
                            }}
                            initialViewState={{
                                "longitude": 151.20699000000002,
                                "latitude": -33.87078,
                                "zoom": 13,
                                "bearing": 0,
                                "pitch": 0

                            }}
                        >
                            {nonEvFuelStationInViewpoint.map(station => {
                                const logoUrl = fuelStationBrandOptionList.find(item => item.value && (item.value as any).includes(station.brand))?.extra || DEFAULT_FUEL_STATION_LOGO
                                const fuelPriceList = fuelPriceDict?.[station.station_id] || []

                                const selectedFuelTypePriceList = selectedFuelType ? fuelPriceList.filter(price => selectedFuelType.split('-').includes(price.fuel_type)) : undefined
                                const lowestPrice = Math.min(...selectedFuelTypePriceList?.map(item => item.price) as number[])

                                const { isOpenNow } = checkIsOpenNow(station)

                                const el = (
                                    <div
                                        className={classNames({
                                            "fuel-station-marker-wrapper": true,
                                            "fuel-station-marker-wrapper__active": visiblePopoverStationId === station.station_id
                                        })}
                                        onClick={() => {
                                            setVisiblePopoverStationId(station?.station_id)
                                        }}
                                        style={{
                                            cursor: 'pointer',
                                            position: 'relative',
                                            zIndex: isOpenNow ? 2 : 1
                                        }}
                                    >
                                        <Tag
                                            avatarSrc={logoUrl || 'https://static.vecteezy.com/system/resources/previews/034/812/074/non_2x/gas-station-icon-isolated-fuel-petrol-pump-symbol-sign-free-vector.jpg'}
                                            avatarShape="circle"
                                            shape="circle"
                                            size="large"
                                            style={{ background: fuelPriceGradientColor.current?.(lowestPrice) }}
                                        >
                                            <span className="font-weight-bold" style={{ color: 'white' }}>{lowestPrice}</span>
                                        </Tag>

                                        {!isOpenNow && <div style={{ position: 'absolute', borderRadius: 13, width: '100%', height: '100%', background: 'black', opacity: '0.8', top: 0, left: 0 }} />}
                                    </div>
                                )

                                return (
                                    <Marker
                                        longitude={parseFloat(station.longitude)}
                                        latitude={parseFloat(station.latitude)}
                                        onClick={() => {
                                            if (isMobile) {
                                                mapRef.current?.getMap().flyTo({
                                                    center: [station?.longitude, station?.latitude].map(parseFloat) as any,
                                                    offset: [0, -160],
                                                    zoom: 14,
                                                })

                                                mapIsLockedRef.current = true
                                            }
                                        }}
                                    >
                                        {isMobile ? (
                                            <>
                                                {el}
                                            </>
                                        ) : (
                                            <Popover
                                                onClickOutSide={() => setVisiblePopoverStationId('')}
                                                trigger="custom"
                                                visible={visiblePopoverStationId === station.station_id}
                                                showArrow
                                                content={(
                                                    <FuelStationDetailPane
                                                        station={station}
                                                        onAdvancedSettingChange={handleAdvancedSettingChange}
                                                        onCancel={() => setVisiblePopoverStationId('')}
                                                    />
                                                )}
                                            >
                                                {el}
                                            </Popover>
                                        )
                                        }

                                    </Marker>
                                )
                            })}
                        </LazyMap>

                        {isMobile && (
                            <>
                                <FlexContainer id="dragger" justifyContent="center" style={{ position: 'fixed', bottom: heightRef.current - 2, width: '100%', visibility: visiblePopoverStationId || mapIsMoving ? 'hidden' : 'visible' }}>
                                    <Draggable
                                        axis="y"
                                        bounds={{
                                            top: -(windowHeight - heightRef.current - navbarHeight - 48 - 31 - 32),
                                            bottom: heightRef.current - 48
                                        }}
                                        onDrag={(e, data) => {
                                            setSidesheetHeight(heightRef.current - data.lastY)
                                            // onHeightChange(heightRef.current - data.lastY)
                                        }}
                                    >
                                        <div style={{ padding: 8 }}>
                                            <div
                                                style={{
                                                    width: 80,
                                                    height: 6,
                                                    borderRadius: 32,
                                                    background: isDarkMode ? '#f2f3f599' : `#00000099`,
                                                    zIndex: 100,
                                                    WebkitBackdropFilter: 'blur(20px)',
                                                    backdropFilter: "blur(20px)",
                                                }}
                                            >
                                                <></>
                                            </div>
                                        </div>
                                    </Draggable>
                                </FlexContainer>

                                <SideSheet
                                    className="fuel-map-wrapper"
                                    placement="bottom"
                                    visible={!visiblePopoverStationId && !mapIsMoving}
                                    height={sidesheetHeight}
                                    mask={false}
                                    style={{ borderRadius: '12px 12px 0 0', position: 'relative' }}
                                    bodyStyle={{ padding: 0 }}
                                >
                                    {priceTable}
                                </SideSheet>

                                <SideSheet
                                    className="fuel-map-wrapper"
                                    visible={!!visiblePopoverStationId}
                                    placement="bottom"
                                    mask={false}
                                    onCancel={() => setVisiblePopoverStationId('')}
                                    closable={false}
                                    headerStyle={{ padding: 0 }}
                                    bodyStyle={{ padding: '0 16px 16px' }}
                                    style={{ borderRadius: '12px 12px 0 0' }}
                                    height="376px"
                                >
                                    {fuelStationList.find(item => item.station_id === visiblePopoverStationId) && (
                                        <FuelStationDetailPane
                                            station={fuelStationList.find(item => item.station_id === visiblePopoverStationId) as IFuelStation}
                                            onAdvancedSettingChange={handleAdvancedSettingChange}
                                            onCancel={() => {
                                                if (mapPrevCenterZoom) {
                                                    mapRef.current?.flyTo(mapPrevCenterZoom as any)
                                                }
                                                setVisiblePopoverStationId('')
                                            }}
                                        />
                                    )}
                                </SideSheet>
                            </>

                        )}


                        {!isMobile && (
                            <div
                                className="responsive-background-secondary fuel-map-statistics-wrapper"
                                style={{
                                    position: 'absolute',
                                    bottom: 36,
                                    left: 8,
                                    padding: 16,
                                    borderRadius: 12,
                                    background: 'white'
                                }}
                            >
                                <div>
                                    Statistics
                                </div>

                                <div>
                                    <Descriptions
                                        data={[
                                            {
                                                key: '#Fuel Station',
                                                value: viewpointStats.num
                                            },
                                            {
                                                key: 'MED Price',
                                                value: viewpointStats.med.toFixed(1)
                                            },
                                            {
                                                key: 'MIN Price',
                                                value: viewpointStats.min
                                            },
                                            {
                                                key: 'MAX Price',
                                                value: viewpointStats.max
                                            }
                                        ]}
                                    />
                                </div>
                            </div>
                        )}

                        <CurrentLocationIcon />
                    </div>
                </FlexContainer>
            </div >
        </FuelMapContext.Provider >
    )
}

export default FuelMap