import { Button, Card, Progress, RadioGroup, Select, Table, Tag, Toast, Typography } from "@douyinfe/semi-ui"
import { useAsyncEffect } from "ahooks"
import { request } from "../../../utils"
import { useContext, useEffect, useState } from "react"
import { ISyncTask } from "../../../typings"
import { AppContext } from "../../../App"
import './index.less'
import { IconCrossCircleStroked, IconDelete, IconLoading, IconSpin, IconTick, IconTickCircle } from "@douyinfe/semi-icons"

import dayjs from "dayjs"
import relativeTime from 'dayjs/plugin/relativeTime'
import duration from 'dayjs/plugin/duration'
import FlexContainer from "../../../components/FlexContainer"
import LoadingButton from "../../../components/LoadingButton"

dayjs.extend(relativeTime);
dayjs.extend(duration)

const SystemStatus = ({ mode, source }: { mode?: string, source?: string }) => {
    const { windowHeight, navbarHeight } = useContext(AppContext)

    const [syncTaskList, setSyncTaskList] = useState<ISyncTask[]>([])
    const [db, setDb] = useState<string>('https://www.hotaudeals.com')
    const [loading, setLoading] = useState(true)

    const [v, setV] = useState(1)

    useEffect(() => {
        const handleFetch = async () => {
            setLoading(true)
            const respData = await request({
                baseURL: db,
                method: 'POST',
                url: '/api/v1/service/execute',
                data: {
                    service: 'rds.sql',
                    requestParams: [
                        source 
                            ? `SELECT * FROM simpo_octopus.sync_task WHERE source = "${source}" ORDER BY sync_task_id DESC LIMIT 10`
                            : `SELECT * FROM simpo_octopus.sync_task WHERE source <> "au-ozbargain" ORDER BY sync_task_id DESC LIMIT 50`
                    ]
                }
            })

            setSyncTaskList(respData)
            setLoading(false)
        }

        handleFetch()
        const interval = setInterval(handleFetch, 5000)

        return () => {
            clearInterval(interval)
        }
    }, [db, v, source])

    const handleDelete = async (id: number) => {
        await request({
            data: {
                service: 'rds.delete',
                requestParams: [
                    'simpo_octopus', 'sync_task', { where: { sync_task_id: id } }
                ]
            }
        })

        Toast.success('Delete successfully')
        setV(prev => prev + 1)
    }

    const columns = [
        { title: 'ID', dataIndex: 'sync_task_id', width: 100 },
        {
            title: 'Platform',
            width: 350,
            render: (record: ISyncTask) => {
                const showFromNow = dayjs(record.created_at).format('YYYY-MM-DD') === dayjs().format('YYYY-MM-DD') && record.progress !== record.total

                return (
                    <div>
                        <div>{record.source}</div>
                        <div style={{ fontSize: 10, color: 'grey' }}>
                            <div>Created at {dayjs(record.created_at).format('DD/MM HH:mm:ss')} {showFromNow ? `(${dayjs(record.created_at).fromNow()})` : ""}</div>
                            <div style={{ marginTop: -8 }}>Updated at {dayjs(record.updated_at).format('DD/MM HH:mm:ss')} {showFromNow ? `(${dayjs(record.updated_at).fromNow()})` : ""}</div>
                            {record.total === record.progress && <div style={{ marginTop: -8 }}>Duration: {dayjs(record.updated_at).from(dayjs(record.created_at), true)}</div>}
                        </div>
                    </div>
                )
            }
        },
        {
            title: ''
        },
        {
            title: 'Status',
            width: 140,
            render: (record: ISyncTask) => {
                if (record.progress === record.total) {
                    return <Tag color="green" prefixIcon={<IconTickCircle />} style={{ height: 24 }}>Completed</Tag>
                }

                if (dayjs(record.updated_at).isBefore(dayjs().subtract(60, 'minute'))) {
                    return <Tag color="red" prefixIcon={<IconCrossCircleStroked />} style={{ height: 24 }}>Timeout</Tag>
                }

                return <Tag color="blue" prefixIcon={<IconSpin spin />} style={{ height: 24 }}>Syncing</Tag>
            }
        },
        {
            title: 'Progress',
            width: 300,
            render: (record: ISyncTask) => {
                const percent = parseFloat((record.progress / record.total * 100).toFixed(1))

                if (isNaN(percent)) {
                    return '-'
                }

                return (
                    <Progress percent={percent} showInfo format={() => `${record.progress} / ${record.total}`} style={{ height: 8 }} />
                )
            }
        },
        { title: '', dataIndex: 'group_by', width: 120 },
        {
            title: 'Action',
            width: 80,
            render: (record: ISyncTask) => {
                return (
                    <LoadingButton icon={<IconDelete />} type="danger" theme="solid" style={{ borderRadius: 32 }} onClick={() => handleDelete(record.sync_task_id)} />
                )
            }
        }
    ]

    const tableWrapper = (
        <Table
            size="small"
            empty={<span className="font-weight-regular">No data</span>}
            sticky
            loading={loading}
            dataSource={syncTaskList}
            columns={columns}
            pagination={false}
            style={{ height: windowHeight - navbarHeight - 46, overflow: 'auto' }}
        />
    )

    if (mode === 'table') {
        return tableWrapper
    }

    return (
        <div style={{ width: '100%' }}>

            <FlexContainer alignItems="center">
                <div className="font-weight-black responsive-text" style={{ padding: '8px 16px', fontSize: 20, letterSpacing: -0.5 }}>
                    System Status
                </div>

                <Select
                    prefix={<span className="font-weight-bold" style={{ fontSize: 14, paddingLeft: 10, paddingRight: 4, color: 'lightgrey' }}>Database: </span>}
                    value={db}
                    onChange={v => setDb(v as any)}
                    optionList={[
                        { label: 'Sydney', value: 'https://www.hotaudeals.com' },
                        { label: 'Shanghai', value: 'http://124.71.152.240:7001' }
                    ]}
                />
            </FlexContainer>

            {tableWrapper}
        </div>
    )
}

export default SystemStatus