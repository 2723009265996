import { IconMapPin } from "@douyinfe/semi-icons"
import FlexContainer from "../../../../components/FlexContainer"
import { useContext } from "react"
import { FuelMapContext } from "../.."
import { AppContext } from "../../../../App"

const CurrentLocationIcon = () => {
    const { mapRef } = useContext(FuelMapContext)
    const { isMobile } = useContext(AppContext)

    const handleGetCurrentLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(async (position) => {
                const latitude = position.coords.latitude;
                const longitude = position.coords.longitude;
                console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);

                mapRef?.flyTo({
                    center: [longitude, latitude],
                })
            }, function (error) {
                console.error('Error getting location:', error);
            });
        } else {
            console.log('Geolocation is not supported by this browser.');
        }
    }

    return (
        <>
            <FlexContainer
                style={{ height: 48, width: 48, borderRadius: '50%', background: 'white', position: 'fixed', right: 16, bottom: 16, cursor: 'pointer' }}
                justifyContent="center"
                alignItems="center"
                onClick={handleGetCurrentLocation}
            >
                <IconMapPin style={{ fontSize: 20 }} />
            </FlexContainer>

            <div>
                <div 
                    style={{
                        width: 14,
                        height: 14,
                        borderRadius: '50%',
                        background: 'rgb(0,100,250)',
                        position: 'absolute',
                        left: 'calc(50% - 9px)',
                        top: `calc(50% - 9px - ${isMobile ? '24px' : '0px'})`,
                        border: '4px solid white'
                    }}
                >

                </div>
            </div>
        </>


    )
}

export default CurrentLocationIcon