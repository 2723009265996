export const LOGIN_DO_NOT_REMIND_ME_LOCAL_STORAGE_KEY = '__login_do_not_remind_me__'
export const CART_ITEM_LIST_LOCAL_STORAGE_KEY = '__cart_item_list__'

export const THIRD_PARTY_LOGIN_PAGE_URL_LOCAL_STORAGE_KEY = '__THIRD_PARTY_LOGIN_PAGE_URL_LOCAL_STORAGE_KEY__'
export const THIRD_PARTY_LOGIN_MERGE_SHOPPING_LIST_TRIGGER_LOCAL_STORAGE_KEY = '__THIRD_PARTY_LOGIN_MERGE_SHOPPING_LIST_TRIGGER__'

export const USER_INFO_SESSION_STORAGE_KEY = '__user_info_session_storage_key__'

export const THEME_MODE_LOCAL_STORAGE_KEY = '__theme_mode__'

export const VISITED_TOUR_ID = '__visited_tour_id__'
export const FUEL_MAP_ADVANCED_SETTING_LOCAL_STORAGE_KEY = '__fuel_map_advanced_setting__'